import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {FORM_FILTERS_CLEAR} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import EmptyArea from 'components/EmptyArea';
import FilterChip from 'components/AgentScreenComponents/_topBar/FilterChip';
import {getSelectedOptionInformation} from 'components/AgentScreenComponents/_caseBlock/helper';
import {FilterOptionType} from 'components/AdminScreenComponents/types';

import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';
import {getFormVersionsList} from 'pages/agent/tableView/helper';

import {getOptionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {
    DEFAULT_NONE,
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_AGENT,
    PARAMETER_FORM,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
} from 'config/index';
import {TAG_COLOR_6} from 'config/theme';

interface CurrentFiltersPanelType {
    filtersNumber: number;
}

const CurrentFiltersPanel: FC<CurrentFiltersPanelType> = ({filtersNumber}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const clearFormFieldFilters = useCallback(() => dispatch({type: FORM_FILTERS_CLEAR.REQUEST}), [dispatch]);

    const {
        admin: {
            agentList,
            formsList,
            formFieldFilters: {formId, filters, tags, translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const currentTags = searchParams.get(PARAMETER_TAG);
    const currentOptions = searchParams.get(PARAMETER_OPTION);
    const currentAgents = searchParams.get(PARAMETER_AGENT);
    const currentForms = searchParams.get(PARAMETER_FORM);

    const currentAgentsArray = currentAgents ? currentAgents.split(',') : [];
    const currentFormsArray = currentForms ? currentForms.split(',') : [];
    const currentTagsArray = currentTags ? currentTags.split(',') : [];
    const currentOptionsArray = currentOptions ? currentOptions.split(',') : [];

    const onDeleteClick = (parameter: string): void => {
        searchParams.delete(parameter);
        setSearchParams(searchParams);
    };

    const onAgentDeleteClick = (agentId: string) => {
        const index = currentAgentsArray.indexOf(agentId);
        if (index > -1) {
            currentAgentsArray.splice(index, 1);
            searchParams.set(PARAMETER_AGENT, currentAgentsArray.join(','));
            setSearchParams(searchParams);
        }
    };

    const onFormDeleteClick = (formVersionId: string) => {
        const index = currentFormsArray.indexOf(formVersionId);
        if (index > -1) {
            currentFormsArray.splice(index, 1);
            searchParams.set(PARAMETER_FORM, currentFormsArray.join(','));
            filters.map((filter: FormFieldFilterType) => {
                searchParams.delete(`field${filter.id}`);
            });
            setSearchParams(searchParams);
            clearFormFieldFilters();
        }
    };

    const onTagDeleteClick = (tagId: string) => {
        const index = currentTagsArray.indexOf(tagId);
        if (index > -1) {
            currentTagsArray.splice(index, 1);
            searchParams.set(PARAMETER_TAG, currentTagsArray.join(','));
            setSearchParams(searchParams);
        }
    };

    const onOptionDeleteClick = (optionId: string) => {
        const index = currentOptionsArray.indexOf(optionId);
        if (index > -1) {
            currentOptionsArray.splice(index, 1);
            searchParams.set(PARAMETER_OPTION, currentOptionsArray.join(','));
            setSearchParams(searchParams);
        }
    };

    const formVersions: FilterOptionType[] = formsList ? getFormVersionsList(formsList) : [];

    if (filtersNumber === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '100%',
                pt: 1,
                pb: 3,
            }}
        >
            <EmptyArea />
            <Box sx={{ml: 2, mr: 2}}>
                {currentAgentsArray.map(item => {
                    const agent = agentList
                        ? agentList.find(agentListItem => Number(agentListItem.id) === Number(item))
                        : null;
                    if (!agent) {
                        return null;
                    }
                    const {id, firstName, lastName, email} = agent;
                    return (
                        <FilterChip
                            key={`agent-${id}`}
                            title={firstName && lastName ? `${firstName} ${lastName}` : email}
                            backgroundColor={'#9B7B6D'}
                            color={getTagColor('#9B7B6D')}
                            onDeleteClick={() => onAgentDeleteClick(String(id))}
                        />
                    );
                })}
                {currentAgentsArray.includes(DEFAULT_NONE) && (
                    <FilterChip
                        key={`agent-${DEFAULT_NONE}`}
                        title={t('orguser.requesterCase.notAssigned')}
                        backgroundColor={'#9B7B6D'}
                        color={getTagColor('#9B7B6D')}
                        onDeleteClick={() => onAgentDeleteClick(DEFAULT_NONE)}
                    />
                )}
                {currentFormsArray.map(item => {
                    const formVersion = formVersions
                        ? formVersions.find(formVersionItem => Number(formVersionItem.name) === Number(item))
                        : null;
                    if (!formVersion) {
                        return null;
                    }
                    const {label, name} = formVersion;
                    return (
                        <FilterChip
                            key={`form-${name}`}
                            title={label}
                            backgroundColor={'#205072'}
                            color={getTagColor('#205072')}
                            onDeleteClick={() => onFormDeleteClick(String(name))}
                        />
                    );
                })}
                {currentTagsArray.map(item => {
                    const tag = tags.find(tagItem => Number(tagItem.id) === Number(item));
                    if (!tag) {
                        return null;
                    }
                    return (
                        <FilterChip
                            key={`tag-${tag.id}`}
                            title={tag.title}
                            backgroundColor={tag.color}
                            color={getTagColor(tag.color)}
                            onDeleteClick={() => onTagDeleteClick(String(tag.id))}
                        />
                    );
                })}
                {currentOptionsArray.map(item => {
                    const selectedOption = getSelectedOptionInformation(filters, Number(item));
                    if (!selectedOption || !formId) {
                        return null;
                    }
                    const keyword = getOptionKeyword(formId, selectedOption.id, 'title');
                    return (
                        <FilterChip
                            key={`option-${selectedOption.uuid}`}
                            title={getFormTranslatedLabel(translations, keyword, selectedOption.title)}
                            backgroundColor={TAG_COLOR_6}
                            color={getTagColor(TAG_COLOR_6)}
                            onDeleteClick={() => onOptionDeleteClick(String(selectedOption.id))}
                        />
                    );
                })}
                {lastCreatedFromUrl && (
                    <FilterChip
                        title={`${t('orguser.requesterCase.created')} ${t(
                            `orguser.requesterCase.topbar.${lastCreatedFromUrl}`,
                        )}`}
                        onDeleteClick={() => onDeleteClick(PARAMETER_LAST_CREATED)}
                    />
                )}
                {lastUpdatedFromUrl && (
                    <FilterChip
                        title={`${t('orguser.requesterCase.updated')} ${t(
                            `orguser.requesterCase.topbar.${lastUpdatedFromUrl}`,
                        )}`}
                        onDeleteClick={() => onDeleteClick(PARAMETER_LAST_UPDATED)}
                    />
                )}
            </Box>
            <EmptyArea />
        </Box>
    );
};

export default CurrentFiltersPanel;
