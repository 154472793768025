import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';
import {UPDATE_REQUESTER_CASE} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    getSectionContent,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FormPopup from 'pages/client/form/fieldPopup/FormPopup';
import FormFieldSwitcher from 'pages/client/form/partials/FormFieldSwitcher';
import FormSectionWrapper from 'pages/client/form/wrappers/FormSectionWrapper';
import {encryptClientInformation} from 'pages/client/form/helper';
import CommentsToSection from 'pages/client/form/comments/CommentsToSection';

import {getSectionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {STATUS_OPENING} from 'config/index';

interface FormSectionComponentType {
    formId: number;
    pageId: number;
    section: FormSectionTypes;
    handleChangeInformation: (pageId: number, sectionId: number, fieldId: number, value: string) => void;
    clientInformation: unknown;
    previewMode?: boolean;
}

interface UpdateRequesterInfoParameterType {
    id: string | undefined;
    result: unknown;
    sectionId?: number;
    toggleSectionChecked?: () => void;
    showAlert?: (type: string, message?: string) => void;
}

const FormSection: FC<FormSectionComponentType> = ({
    section,
    formId,
    pageId,
    clientInformation,
    handleChangeInformation,
    previewMode,
}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {toggleSectionChecked} = useContext(ClientFormContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);

    const updateRequesterInformation = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            isCaseUpdateLoading,
            currentCase: {checkedSections, isEncryptInfo, noEditForm, status, subFormRequests},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const noEdit = noEditForm && status !== STATUS_OPENING;

    const {title, description, fields, popups, id} = section;

    const onSaveClicked = async (isCallAlert = true) => {
        if (clientInformation && Object.keys(clientInformation as object).length > 0 && !previewMode) {
            let params: UpdateRequesterInfoParameterType = {
                id: requestCase,
                result:
                    isEncryptInfo && unwrappedCaseKey
                        ? await encryptClientInformation(clientInformation, unwrappedCaseKey)
                        : clientInformation,
                sectionId: id,
                toggleSectionChecked,
            };
            params = isCallAlert ? {...params, showAlert} : params;
            updateRequesterInformation(params);
        }
    };

    const sectionContent: SectionContentType[] = getSectionContent(fields, popups);

    const titleKeyword = getSectionKeyword(formId, id, 'title');
    const titleDescription = getSectionKeyword(formId, id, 'description');

    const isSectionChecked = checkedSections.some(item => Number(item) === Number(id));

    return (
        <FormSectionWrapper
            id={id}
            title={previewMode ? title : getFormTranslatedLabel(translations, titleKeyword, title)}
            isChecked={isSectionChecked}
        >
            <Box
                sx={{
                    mb: 3,
                    '& p': {
                        fontSize: 14,
                        fontWeight: 300,
                        mt: 0,
                    },
                }}
            >
                <Markdown>
                    {previewMode ? description : getFormTranslatedLabel(translations, titleDescription, description)}
                </Markdown>
            </Box>
            {sectionContent &&
                sectionContent.map((item: SectionContentType, index: number) => {
                    if (item.type === CONTENT_FIELD) {
                        return (
                            <FormFieldSwitcher
                                key={`form-field-${index}`}
                                formId={formId}
                                pageId={pageId}
                                disabled={isSectionChecked || noEdit || isCaseUpdateLoading}
                                sectionId={id}
                                field={fields[item.index]}
                                clientInformation={clientInformation}
                                handleChangeInformation={handleChangeInformation}
                                previewMode={previewMode}
                                onSaveClicked={onSaveClicked}
                            />
                        );
                    } else if (item.type === CONTENT_POPUP) {
                        const popup = popups[item.index];
                        return (
                            <FormPopup
                                key={`form-popup-${index}`}
                                formId={formId}
                                pageId={pageId}
                                sectionId={id}
                                disabled={isSectionChecked || noEdit}
                                popup={popup}
                                clientInformation={clientInformation}
                                onSaveClicked={onSaveClicked}
                                previewMode={previewMode}
                            />
                        );
                    }
                    return null;
                })}
            <CommentsToSection sectionId={id} />
        </FormSectionWrapper>
    );
};

export default FormSection;
