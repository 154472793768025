import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';

import {GridColDef} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {RootReducer} from 'appRedux/reducers';

import {PermissionContext} from 'contexts/permission/context';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';

import {getSlotStatusLabel} from 'pages/client/form/fieldTypes/helper';

import {routes, ITEMS_PER_PAGE_OPTIONS_DEFAULT, DATE_TIME_LABEL_FORMAT} from 'config/index';

const SlotRelatedCasesList: FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {isCaseViewEnabled} = useContext(PermissionContext);

    const {
        resourceFields: {currentSlotItemRelatedCases},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!currentSlotItemRelatedCases) return null;

    const {results, count} = currentSlotItemRelatedCases;

    const redirectToCase = (caseId: number | null, formId: number) => {
        if (caseId && isCaseViewEnabled) {
            navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'caseLabel',
            headerName: t('orguser.resourceFields.requesterCase'),
        },
        {
            field: 'formTitle',
            headerName: t('orguser.resourceFields.formTitle'),
        },
        {
            field: 'statusTitle',
            headerName: t('orguser.resourceFields.caseStatus'),
        },
        {
            field: 'status',
            headerName: t('orguser.resourceFields.slotStatus'),
            renderCell: ({row}) => {
                return <Typography sx={{fontSize: 14}}>{t(getSlotStatusLabel(row.status))}</Typography>;
            },
        },
        {
            field: 'reservedAt',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusReservation'),
            renderCell: ({row}) => {
                return (
                    <Typography sx={{fontSize: 14}}>
                        {row.reservedAt ? moment(row.reservedAt).format(DATE_TIME_LABEL_FORMAT) : t('common.none')}
                    </Typography>
                );
            },
        },
        {
            field: 'bookedAt',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusBooking'),
            renderCell: ({row}) => {
                return (
                    <Typography sx={{fontSize: 14}}>
                        {row.bookedAt ? moment(row.bookedAt).format(DATE_TIME_LABEL_FORMAT) : t('common.none')}
                    </Typography>
                );
            },
        },
        {
            field: 'clearedAt',
            headerName: t('orguser.resourceFields.statusBindingTypes.statusClearing'),
            renderCell: ({row}) => {
                return (
                    <Typography sx={{fontSize: 14}}>
                        {row.clearedAt ? moment(row.clearedAt).format(DATE_TIME_LABEL_FORMAT) : t('common.none')}
                    </Typography>
                );
            },
        },
        {
            field: 'action',
            headerName: t('common.action'),
            align: 'center',
            width: 70,
            renderCell: ({row}) => {
                const {caseId, formId} = row;
                return (
                    <IconButton disabled={!isCaseViewEnabled} onClick={() => redirectToCase(caseId, formId)}>
                        <EditSvgIcon />
                    </IconButton>
                );
            },
        },
    ];

    return (
        <DataGridWrapper
            itemsList={results}
            itemsTotalNumber={count}
            itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS_DEFAULT}
        >
            {results && (
                <CustomDataGrid
                    rows={results}
                    columns={columns}
                    getRowId={row => row.uuid}
                    enablePaging
                    paginationMode="server"
                    disableColumnFilter
                    checkboxSelection={false}
                    sx={{cursor: 'pointer'}}
                />
            )}
        </DataGridWrapper>
    );
};

export default SlotRelatedCasesList;
