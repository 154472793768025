import {
    WorkflowItemTypes,
    WorkflowStatusItemTypes,
    WorkflowStatusEventItemTypes,
    BoardItemTypes,
    CurrentFormType,
} from 'appRedux/actions/workflow/types';
import {
    CREATE_WORKFLOW,
    DELETE_WORKFLOW,
    GET_WORKFLOWS_LIST,
    UPDATE_WORKFLOW,
    CLONE_WORKFLOW,
    CREATE_WORKFLOW_STATUS,
    UPDATE_WORKFLOW_STATUS,
    UPDATE_WORKFLOW_STATUS_INTERNAL_NAME,
    GET_WORKFLOW_GRAPH,
    REFRESH_WORKFLOW_GRAPH,
    GET_WORKFLOWS_STATUS_LIST,
    GET_CURRENT_WORKFLOW_STATUS,
    REFRESH_CURRENT_WORKFLOW_STATUS,
    DELETE_WORKFLOW_STATUS,
    CHANGE_WORKFLOW_STATUS_PRIORITY,
    UPDATE_WORKFLOW_STATUS_PERMISSIONS,
    GET_WORKFLOWS_STATUS_EVENTS_LIST,
    CREATE_WORKFLOW_STATUS_EVENTS,
    DELETE_WORKFLOW_STATUS_EVENTS,
    TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS,
    GET_BOARDS_LIST,
    CREATE_BOARD,
    CREATE_BOARD_LIST,
    CREATE_BOARD_OVERVIEW,
    UPDATE_BOARD,
    UPDATE_BOARD_LIST_STATUS,
    UPDATE_BOARD_OVERVIEW,
    DELETE_BOARD,
    TOGGLE_BOARD_STATUS,
    CREATE_BOARD_HAS_STATUS,
    TOGGLE_BOARD_HAS_STATUS,
    CREATE_WORKFLOW_TEMPLATE,
    UPDATE_WORKFLOW_TEMPLATE,
    CHANGE_BOARD_PRIORITY,
    CREATE_WORKFLOW_STATUS_TRANSLATION,
    UPDATE_WORKFLOW_STATUS_TRANSLATION,
    UPLOAD_WORKFLOW_ATTACHMENT,
    DELETE_WORKFLOW_ATTACHMENT,
    DOWNLOAD_WORKFLOW_ATTACHMENT,
    CREATE_WORKFLOW_STATUS_RULE,
    UPDATE_WORKFLOW_STATUS_RULE,
    TOGGLE_WORKFLOW_STATUS_RULE,
    DELETE_WORKFLOW_STATUS_RULE,
    ATTACH_WORKFLOW_RULE_APPROVER,
    DELETE_WORKFLOW_RULE_APPROVER,
    ATTACH_WORKFLOW_RULE_APPROVERS_GROUP,
    DELETE_WORKFLOW_RULE_APPROVERS_GROUP,
    ATTACH_WORKFLOW_EVENT_AGENT,
    DELETE_WORKFLOW_EVENT_AGENT,
    ATTACH_WORKFLOW_EVENT_GROUP,
    DELETE_WORKFLOW_EVENT_GROUP,
    CREATE_WORKFLOW_STATUS_TASK,
    UPDATE_WORKFLOW_STATUS_TASK,
    ACTIVATE_WORKFLOW_STATUS_TASK,
    DELETE_WORKFLOW_STATUS_TASK,
    CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE,
    UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE,
    ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT,
    REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT,
    SET_CURRENT_BOARD,
    CREATE_BOARD_SWIMLANE,
    UPDATE_BOARD_SWIMLANE,
    GET_FAVORITE_BOARDS_LIST,
    CHANGE_FAVORITE_BOARD_PRIORITY,
    TOGGLE_BOARD_FAVORITE,
} from 'appRedux/actions/workflow';

export const initialState: WorkflowState = {
    isLoading: false,
    isGraphLoading: false,
    isLoadingWorkflowCloning: false,
    organizationWorkflows: [],
    workflowStatusesList: [],
    currentWorkflowStatus: null,
    workflowStatusEvents: [],
    favoriteBoards: [],
    boards: [],
    loadedWorkflowGraphs: [],
    currentBoard: undefined,
    errors: undefined,
};

export interface WorkflowState {
    isLoading: boolean;
    isGraphLoading: boolean;
    isLoadingWorkflowCloning: boolean;
    organizationWorkflows: WorkflowItemTypes[];
    workflowStatusesList: WorkflowStatusItemTypes[];
    currentWorkflowStatus: WorkflowStatusItemTypes | null;
    workflowStatusEvents: WorkflowStatusEventItemTypes[];
    favoriteBoards: BoardItemTypes[];
    boards: BoardItemTypes[];
    loadedWorkflowGraphs: string[];
    currentBoard?: CurrentFormType;
    errors: string[] | undefined;
}

const instance = (
    state = initialState,
    action: IActionType<
        | WorkflowItemTypes[]
        | WorkflowStatusItemTypes[]
        | WorkflowStatusItemTypes
        | WorkflowStatusEventItemTypes[]
        | BoardItemTypes[]
        | CurrentFormType
        | IErrors
        | string
    >,
): WorkflowState => {
    switch (action.type) {
        case GET_BOARDS_LIST.REQUEST:
        case GET_FAVORITE_BOARDS_LIST.REQUEST:
        case CREATE_BOARD.REQUEST:
        case CREATE_BOARD_LIST.REQUEST:
        case CREATE_BOARD_OVERVIEW.REQUEST:
        case CREATE_BOARD_SWIMLANE.REQUEST:
        case UPDATE_BOARD.REQUEST:
        case UPDATE_BOARD_LIST_STATUS.REQUEST:
        case UPDATE_BOARD_SWIMLANE.REQUEST:
        case UPDATE_BOARD_OVERVIEW.REQUEST:
        case DELETE_BOARD.REQUEST:
        case CHANGE_BOARD_PRIORITY.REQUEST:
        case CHANGE_FAVORITE_BOARD_PRIORITY.REQUEST:
        case TOGGLE_BOARD_STATUS.REQUEST:
        case TOGGLE_BOARD_FAVORITE.REQUEST:
        case CREATE_BOARD_HAS_STATUS.REQUEST:
        case TOGGLE_BOARD_HAS_STATUS.REQUEST:
        case GET_WORKFLOWS_LIST.REQUEST:
        case CREATE_WORKFLOW.REQUEST:
        case UPDATE_WORKFLOW.REQUEST:
        case DELETE_WORKFLOW.REQUEST:
        case GET_WORKFLOWS_STATUS_LIST.REQUEST:
        case CREATE_WORKFLOW_STATUS.REQUEST:
        case UPDATE_WORKFLOW_STATUS.REQUEST:
        case UPDATE_WORKFLOW_STATUS_INTERNAL_NAME.REQUEST:
        case DELETE_WORKFLOW_STATUS.REQUEST:
        case CHANGE_WORKFLOW_STATUS_PRIORITY.REQUEST:
        case UPDATE_WORKFLOW_STATUS_PERMISSIONS.REQUEST:
        case GET_WORKFLOWS_STATUS_EVENTS_LIST.REQUEST:
        case GET_CURRENT_WORKFLOW_STATUS.REQUEST:
        case CREATE_WORKFLOW_STATUS_EVENTS.REQUEST:
        case DELETE_WORKFLOW_STATUS_EVENTS.REQUEST:
        case CREATE_WORKFLOW_TEMPLATE.REQUEST:
        case UPDATE_WORKFLOW_TEMPLATE.REQUEST:
        case TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.REQUEST:
        case CREATE_WORKFLOW_STATUS_TRANSLATION.REQUEST:
        case UPDATE_WORKFLOW_STATUS_TRANSLATION.REQUEST:
        case UPLOAD_WORKFLOW_ATTACHMENT.REQUEST:
        case DELETE_WORKFLOW_ATTACHMENT.REQUEST:
        case DOWNLOAD_WORKFLOW_ATTACHMENT.REQUEST:
        case CREATE_WORKFLOW_STATUS_RULE.REQUEST:
        case UPDATE_WORKFLOW_STATUS_RULE.REQUEST:
        case TOGGLE_WORKFLOW_STATUS_RULE.REQUEST:
        case DELETE_WORKFLOW_STATUS_RULE.REQUEST:
        case ATTACH_WORKFLOW_RULE_APPROVER.REQUEST:
        case DELETE_WORKFLOW_RULE_APPROVER.REQUEST:
        case ATTACH_WORKFLOW_RULE_APPROVERS_GROUP.REQUEST:
        case DELETE_WORKFLOW_RULE_APPROVERS_GROUP.REQUEST:
        case ATTACH_WORKFLOW_EVENT_AGENT.REQUEST:
        case DELETE_WORKFLOW_EVENT_AGENT.REQUEST:
        case ATTACH_WORKFLOW_EVENT_GROUP.REQUEST:
        case DELETE_WORKFLOW_EVENT_GROUP.REQUEST:
        case CREATE_WORKFLOW_STATUS_TASK.REQUEST:
        case UPDATE_WORKFLOW_STATUS_TASK.REQUEST:
        case ACTIVATE_WORKFLOW_STATUS_TASK.REQUEST:
        case DELETE_WORKFLOW_STATUS_TASK.REQUEST:
        case CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST:
        case UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.REQUEST:
        case ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST:
        case REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case REFRESH_WORKFLOW_GRAPH.REQUEST: {
            return {
                ...state,
                isGraphLoading: false,
                loadedWorkflowGraphs: [],
            };
        }

        case GET_WORKFLOW_GRAPH.REQUEST:
        case GET_WORKFLOW_GRAPH.REQUEST_GROUP: {
            return {
                ...state,
                isGraphLoading: true,
            };
        }

        case CLONE_WORKFLOW.REQUEST: {
            return {
                ...state,
                isLoadingWorkflowCloning: true,
            };
        }

        case DOWNLOAD_WORKFLOW_ATTACHMENT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case REFRESH_CURRENT_WORKFLOW_STATUS.REQUEST: {
            return {
                ...state,
                currentWorkflowStatus: null,
            };
        }

        case GET_WORKFLOWS_LIST.SUCCESS:
        case CREATE_WORKFLOW.SUCCESS:
        case UPDATE_WORKFLOW.SUCCESS:
        case DELETE_WORKFLOW.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationWorkflows: action.payload as WorkflowItemTypes[],
            };
        }

        case CLONE_WORKFLOW.SUCCESS: {
            return {
                ...state,
                isLoadingWorkflowCloning: false,
                organizationWorkflows: action.payload as WorkflowItemTypes[],
            };
        }

        case GET_WORKFLOW_GRAPH.SUCCESS: {
            return {
                ...state,
                isGraphLoading: false,
            };
        }
        case GET_WORKFLOW_GRAPH.SUCCESS_GROUP: {
            return {
                ...state,
                isGraphLoading: false,
                loadedWorkflowGraphs: [...state.loadedWorkflowGraphs, String(action.payload)],
            };
        }

        case GET_WORKFLOWS_STATUS_LIST.SUCCESS:
        case CREATE_WORKFLOW_STATUS.SUCCESS:
        case UPDATE_WORKFLOW_STATUS.SUCCESS:
        case UPDATE_WORKFLOW_STATUS_INTERNAL_NAME.SUCCESS:
        case DELETE_WORKFLOW_STATUS.SUCCESS:
        case CHANGE_WORKFLOW_STATUS_PRIORITY.SUCCESS:
        case UPDATE_WORKFLOW_STATUS_PERMISSIONS.SUCCESS:
        case CREATE_WORKFLOW_STATUS_TRANSLATION.SUCCESS:
        case UPDATE_WORKFLOW_STATUS_TRANSLATION.SUCCESS:
        case CREATE_WORKFLOW_STATUS_RULE.SUCCESS:
        case UPDATE_WORKFLOW_STATUS_RULE.SUCCESS:
        case TOGGLE_WORKFLOW_STATUS_RULE.SUCCESS:
        case DELETE_WORKFLOW_STATUS_RULE.SUCCESS:
        case ATTACH_WORKFLOW_RULE_APPROVER.SUCCESS:
        case DELETE_WORKFLOW_RULE_APPROVER.SUCCESS:
        case ATTACH_WORKFLOW_RULE_APPROVERS_GROUP.SUCCESS:
        case DELETE_WORKFLOW_RULE_APPROVERS_GROUP.SUCCESS:
        case CREATE_WORKFLOW_STATUS_TASK.SUCCESS:
        case UPDATE_WORKFLOW_STATUS_TASK.SUCCESS:
        case ACTIVATE_WORKFLOW_STATUS_TASK.SUCCESS:
        case DELETE_WORKFLOW_STATUS_TASK.SUCCESS:
        case CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.SUCCESS:
        case UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.SUCCESS:
        case ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.SUCCESS:
        case REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workflowStatusesList: action.payload as WorkflowStatusItemTypes[],
            };
        }

        case GET_CURRENT_WORKFLOW_STATUS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentWorkflowStatus: action.payload as WorkflowStatusItemTypes,
            };
        }

        case CREATE_WORKFLOW_TEMPLATE.SUCCESS:
        case UPDATE_WORKFLOW_TEMPLATE.SUCCESS:
        case GET_WORKFLOWS_STATUS_EVENTS_LIST.SUCCESS:
        case CREATE_WORKFLOW_STATUS_EVENTS.SUCCESS:
        case DELETE_WORKFLOW_STATUS_EVENTS.SUCCESS:
        case TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.SUCCESS:
        case UPLOAD_WORKFLOW_ATTACHMENT.SUCCESS:
        case DELETE_WORKFLOW_ATTACHMENT.SUCCESS:
        case ATTACH_WORKFLOW_EVENT_AGENT.SUCCESS:
        case DELETE_WORKFLOW_EVENT_AGENT.SUCCESS:
        case ATTACH_WORKFLOW_EVENT_GROUP.SUCCESS:
        case DELETE_WORKFLOW_EVENT_GROUP.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                workflowStatusEvents: action.payload as WorkflowStatusEventItemTypes[],
            };
        }

        case GET_BOARDS_LIST.SUCCESS:
        case CREATE_BOARD.SUCCESS:
        case CREATE_BOARD_LIST.SUCCESS:
        case CREATE_BOARD_OVERVIEW.SUCCESS:
        case CREATE_BOARD_SWIMLANE.SUCCESS:
        case UPDATE_BOARD_OVERVIEW.SUCCESS:
        case UPDATE_BOARD.SUCCESS:
        case UPDATE_BOARD_LIST_STATUS.SUCCESS:
        case UPDATE_BOARD_SWIMLANE.SUCCESS:
        case DELETE_BOARD.SUCCESS:
        case CHANGE_BOARD_PRIORITY.SUCCESS:
        case CREATE_BOARD_HAS_STATUS.SUCCESS:
        case TOGGLE_BOARD_HAS_STATUS.SUCCESS:
        case TOGGLE_BOARD_STATUS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                boards: action.payload as BoardItemTypes[],
            };
        }

        case GET_FAVORITE_BOARDS_LIST.SUCCESS:
        case CHANGE_FAVORITE_BOARD_PRIORITY.SUCCESS:
        case TOGGLE_BOARD_FAVORITE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                favoriteBoards: action.payload as BoardItemTypes[],
            };
        }

        case SET_CURRENT_BOARD: {
            return {
                ...state,
                currentBoard: action.payload as CurrentFormType,
            };
        }

        case GET_BOARDS_LIST.ERROR:
        case GET_FAVORITE_BOARDS_LIST.ERROR:
        case CREATE_BOARD.ERROR:
        case CREATE_BOARD_LIST.ERROR:
        case CREATE_BOARD_OVERVIEW.ERROR:
        case CREATE_BOARD_SWIMLANE.ERROR:
        case UPDATE_BOARD.ERROR:
        case UPDATE_BOARD_LIST_STATUS.ERROR:
        case UPDATE_BOARD_SWIMLANE.ERROR:
        case UPDATE_BOARD_OVERVIEW.ERROR:
        case DELETE_BOARD.ERROR:
        case CHANGE_BOARD_PRIORITY.ERROR:
        case TOGGLE_BOARD_STATUS.ERROR:
        case TOGGLE_BOARD_FAVORITE.ERROR:
        case CREATE_BOARD_HAS_STATUS.ERROR:
        case TOGGLE_BOARD_HAS_STATUS.ERROR:
        case GET_WORKFLOWS_LIST.ERROR:
        case CREATE_WORKFLOW.ERROR:
        case UPDATE_WORKFLOW.ERROR:
        case DELETE_WORKFLOW.ERROR:
        case GET_WORKFLOWS_STATUS_LIST.ERROR:
        case CREATE_WORKFLOW_STATUS.ERROR:
        case UPDATE_WORKFLOW_STATUS.ERROR:
        case UPDATE_WORKFLOW_STATUS_INTERNAL_NAME.ERROR:
        case DELETE_WORKFLOW_STATUS.ERROR:
        case CHANGE_WORKFLOW_STATUS_PRIORITY.ERROR:
        case UPDATE_WORKFLOW_STATUS_PERMISSIONS.ERROR:
        case GET_WORKFLOWS_STATUS_EVENTS_LIST.ERROR:
        case GET_CURRENT_WORKFLOW_STATUS.ERROR:
        case CREATE_WORKFLOW_STATUS_EVENTS.ERROR:
        case DELETE_WORKFLOW_STATUS_EVENTS.ERROR:
        case CREATE_WORKFLOW_TEMPLATE.ERROR:
        case UPDATE_WORKFLOW_TEMPLATE.ERROR:
        case TOGGLE_ACTIVITY_WORKFLOW_STATUS_EVENTS.ERROR:
        case CREATE_WORKFLOW_STATUS_TRANSLATION.ERROR:
        case UPDATE_WORKFLOW_STATUS_TRANSLATION.ERROR:
        case UPLOAD_WORKFLOW_ATTACHMENT.ERROR:
        case DELETE_WORKFLOW_ATTACHMENT.ERROR:
        case DOWNLOAD_WORKFLOW_ATTACHMENT.ERROR:
        case CREATE_WORKFLOW_STATUS_RULE.ERROR:
        case UPDATE_WORKFLOW_STATUS_RULE.ERROR:
        case TOGGLE_WORKFLOW_STATUS_RULE.ERROR:
        case DELETE_WORKFLOW_STATUS_RULE.ERROR:
        case ATTACH_WORKFLOW_RULE_APPROVER.ERROR:
        case DELETE_WORKFLOW_RULE_APPROVER.ERROR:
        case ATTACH_WORKFLOW_RULE_APPROVERS_GROUP.ERROR:
        case DELETE_WORKFLOW_RULE_APPROVERS_GROUP.ERROR:
        case ATTACH_WORKFLOW_EVENT_AGENT.ERROR:
        case DELETE_WORKFLOW_EVENT_AGENT.ERROR:
        case ATTACH_WORKFLOW_EVENT_GROUP.ERROR:
        case DELETE_WORKFLOW_EVENT_GROUP.ERROR:
        case CREATE_WORKFLOW_STATUS_TASK.ERROR:
        case UPDATE_WORKFLOW_STATUS_TASK.ERROR:
        case ACTIVATE_WORKFLOW_STATUS_TASK.ERROR:
        case DELETE_WORKFLOW_STATUS_TASK.ERROR:
        case CREATE_WORKFLOW_TASK_EMAIL_TEMPLATE.ERROR:
        case UPDATE_WORKFLOW_TASK_EMAIL_TEMPLATE.ERROR:
        case ADD_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.ERROR:
        case REMOVE_WORKFLOW_TASK_EMAIL_TEMPLATE_AGENT.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        case GET_WORKFLOW_GRAPH.ERROR: {
            return {
                ...state,
                isGraphLoading: false,
                errors: action.payload as any,
            };
        }

        case CLONE_WORKFLOW.ERROR: {
            return {
                ...state,
                isLoadingWorkflowCloning: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default instance;
