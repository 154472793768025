import React, {FC, useContext} from 'react';
import {View, Text} from '@react-pdf/renderer';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RequesterSignatureResponseType} from 'appRedux/actions/profile/types';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {ImageComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface SignatureFieldPdfContentType {
    formId: number;
    pageId: number;
    sectionId: number;
    field: FormFieldTypes;
    result: RequesterCaseItemType | null;
}

const SignatureFieldPdfContent: FC<SignatureFieldPdfContentType> = ({formId, sectionId, field}) => {
    const {
        translations,
        currentCase: {signatures},
    } = useContext(PdfDocumentContext);

    const {label, id} = field;

    const keyword = getFieldKeyword(formId, id, 'label');

    const fieldSignatures = signatures.filter(
        (signature: RequesterSignatureResponseType) => signature.field === field.id && signature.section === sectionId,
    );

    return (
        <View style={pdfStyles.field}>
            <View style={pdfStyles.fieldTitle}>
                <Text>{`${getFormTranslatedLabel(translations, keyword, label)}:`}</Text>
            </View>

            <View style={pdfStyles.fieldValue}>
                {fieldSignatures.length ? (
                    fieldSignatures.map(signature => {
                        return (
                            <View style={pdfStyles.fieldSignature} key={signature.uuid}>
                                <ImageComponent src={signature.fileLink || ''} />
                            </View>
                        );
                    })
                ) : (
                    <Text>{EMPTY_DATA}</Text>
                )}
            </View>
        </View>
    );
};

export default SignatureFieldPdfContent;
