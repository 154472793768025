import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';
import {MdxEditorCustomButtonType} from 'components/MdxEditorField/helper';

import {theme} from 'config/theme';

interface CustomButtonItemsSelectorType {
    button: MdxEditorCustomButtonType;
    onOptionSelect: (value: string) => void;
}

const CustomButtonItemsSelector: FC<CustomButtonItemsSelectorType> = ({button, onOptionSelect}) => {
    const [t] = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {
        onOptionSelect(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const {title, items} = button;

    return (
        <>
            <AgentSelectButton title={t(title)} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 200,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                {items &&
                    items.map((option: MdxEditorCustomButtonType, index: number) => {
                        const {title, markdown} = option;
                        return (
                            <MenuItem key={`option-${index}`} onClick={e => handleChange(e, markdown)}>
                                <Typography>{t(title)}</Typography>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};

export default CustomButtonItemsSelector;
