import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from '@mui/material/IconButton';

import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';

import PermissionSvgIcon from 'assets/icons/buttons/PermissionSvgIcon';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import UpdateWorkflowStatusPermissionForm from 'components/Forms/Workflow/workflowStatus/UpdateWorkflowStatusPermissionForm';
import {STATUS_OPENING} from 'config/index';
import {theme} from 'config/theme';

interface UpdateWorkflowStatusPermissionsButtonType {
    item: WorkflowStatusItemTypes;
    currentLanguage: string;
}

const UpdateWorkflowStatusPermissionsButton: FC<UpdateWorkflowStatusPermissionsButtonType> = ({
    item,
    currentLanguage,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    console.log(item.statusType, STATUS_OPENING);

    return (
        <>
            <IconButton
                title={t('common.buttons.update')}
                onClick={toggleIsOpened}
                disabled={item.statusType === STATUS_OPENING}
                sx={item.statusType === STATUS_OPENING ? {opacity: 0.35} : {}}
            >
                <PermissionSvgIcon />
            </IconButton>
            <ModalWrapper
                isShowModal={isOpened}
                title={`${item.title}: ${t('orguser.workflows.updateStatusPermissions')}`}
                toggleModal={toggleIsOpened}
            >
                <UpdateWorkflowStatusPermissionForm
                    item={item}
                    closeModal={() => setIsOpened(false)}
                    currentLanguage={currentLanguage}
                />
            </ModalWrapper>
        </>
    );
};

export default UpdateWorkflowStatusPermissionsButton;
