import React, {FC, useCallback} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {FORM_FIELD_UPDATE} from 'appRedux/actions/forms';
import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import FieldForm from 'components/Forms/FormBuilder/FieldForm/Form';
import {formFieldInitialValues} from 'components/Forms/FormBuilder/FieldForm/validation';
import {TAB_FIELD_DATA, TAB_GDPR_SETTINGS, TAB_INTERNAL} from 'components/Forms/FormBuilder/FieldForm/partials/helper';
import FieldGdprSettingsForm from 'components/Forms/FormBuilder/FieldForm/FieldGdprSettingsForm';
import {RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';

import {getPopupFieldById, getFieldById} from 'pages/admin/updateForm/partials/FormStructure/helper';

interface FormFieldPopupContentType {
    currentTab: number;
    fieldId: number;
    isPopup?: boolean;
}

const FormFieldPopupContent: FC<FormFieldPopupContentType> = ({fieldId, currentTab, isPopup}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        admin: {formInfo},
        resourceFields: {resourceFieldBudgetItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const pages: FormPageTypes[] = get(formInfo, 'pages', []);

    const submitUpdateForm = useCallback(
        data => dispatch({type: FORM_FIELD_UPDATE.REQUEST, payload: data}),
        [dispatch],
    );

    const fieldValues = isPopup ? getPopupFieldById(pages, fieldId) : getFieldById(pages, fieldId);
    const initialValues = fieldValues ? {...formFieldInitialValues, ...fieldValues} : formFieldInitialValues;

    if (currentTab === TAB_FIELD_DATA) {
        const selectedBudgetItem = resourceFieldBudgetItems.find(item => item.id === initialValues.resourceFieldBudget);
        return (
            <FieldForm
                entityId={fieldId}
                initialValues={{
                    ...initialValues,
                    budgetAmount:
                        selectedBudgetItem && selectedBudgetItem.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY
                            ? (0.01 * Number(initialValues.budgetAmount)).toFixed(2)
                            : initialValues.budgetAmount,
                    resourceFieldSlot: initialValues.resourceFieldSlot ? initialValues.resourceFieldSlot : 0,
                }}
                onSubmitAction={submitUpdateForm}
                newItemPriority={get(initialValues, 'priority', 0)}
                isPopup={isPopup}
            />
        );
    }
    if (fieldValues && currentTab === TAB_GDPR_SETTINGS) {
        return <FieldGdprSettingsForm fieldId={fieldId} initialValues={fieldValues} />;
    }
    if (currentTab === TAB_INTERNAL) {
        return <Typography>{t('common.none')}</Typography>;
    }
    return null;
};

export default FormFieldPopupContent;
