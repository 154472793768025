import {ElementType} from 'react';

import IconType_01 from 'assets/icons/savedFilters/IconType_01';
import IconType_02 from 'assets/icons/savedFilters/IconType_02';
import IconType_03 from 'assets/icons/savedFilters/IconType_03';
import IconType_04 from 'assets/icons/savedFilters/IconType_04';
import IconType_05 from 'assets/icons/savedFilters/IconType_05';
import IconType_06 from 'assets/icons/savedFilters/IconType_06';
import IconType_07 from 'assets/icons/savedFilters/IconType_07';
import IconType_08 from 'assets/icons/savedFilters/IconType_08';
import IconType_09 from 'assets/icons/savedFilters/IconType_09';
import IconType_10 from 'assets/icons/savedFilters/IconType_10';
import IconType_11 from 'assets/icons/savedFilters/IconType_11';
import IconType_12 from 'assets/icons/savedFilters/IconType_12';
import IconType_13 from 'assets/icons/savedFilters/IconType_13';
import IconType_14 from 'assets/icons/savedFilters/IconType_14';
import IconType_15 from 'assets/icons/savedFilters/IconType_15';

import {PARAMETER_TAG, PARAMETER_AGENT, PARAMETER_FORM, PARAMETER_OPTION} from 'config/index';

export const FILTER_ICON_DEFAULT_TYPE = 1;

export const MAXIMAL_SAVED_FILTERS_NUMBER = 10;

export const FILTER_SEPARATOR = '%2C';

export interface FilterObjectType {
    [key: string]: string | string[];
}

export interface IconImageType {
    type: number;
    icon: ElementType;
}

export interface SwimlaneType {
    type: number;
    label: string;
}

export const iconTypes: IconImageType[] = [
    {type: 1, icon: IconType_01},
    {type: 2, icon: IconType_02},
    {type: 3, icon: IconType_03},
    {type: 4, icon: IconType_04},
    {type: 5, icon: IconType_05},
    {type: 6, icon: IconType_06},
    {type: 7, icon: IconType_07},
    {type: 8, icon: IconType_08},
    {type: 9, icon: IconType_09},
    {type: 10, icon: IconType_10},
    {type: 11, icon: IconType_11},
    {type: 12, icon: IconType_12},
    {type: 13, icon: IconType_13},
    {type: 14, icon: IconType_14},
    {type: 15, icon: IconType_15},
];

export const getFilterIconByType = (type: number): ElementType => {
    const result = iconTypes.find((item: IconImageType) => item.type === type);
    if (result) {
        return result.icon;
    }
    return IconType_01;
};

export const getFilterObject = (text: string): FilterObjectType => {
    const textArray = text.replace('?', '').split('&');
    const filterObject: FilterObjectType = {};
    textArray.forEach(item => {
        const itemArray = item.split('=');
        const key = itemArray[0];
        const value = itemArray[1];
        filterObject[key] = [PARAMETER_TAG, PARAMETER_OPTION, PARAMETER_AGENT, PARAMETER_FORM].includes(key)
            ? value.split(FILTER_SEPARATOR)
            : value;
    });
    return filterObject;
};
