import get from 'lodash/get';

import {FormItemBasic, FormVersionTypes, FormFieldFilterType, FieldOptionType} from 'appRedux/actions/forms/types';

export const getCurrentForm = (formsList: FormItemBasic[], formVersionFromUrl: number): FormItemBasic | null => {
    for (let i = 0, n = formsList.length; i < n; i++) {
        const form = formsList[i];
        const versions: FormVersionTypes[] = get(formsList, [i, 'versions'], []);
        const version = versions.find(versionItem => versionItem.id === formVersionFromUrl);
        if (version) {
            return form;
        }
    }
    return null;
};

export const getCurrentFormTitle = (form: FormItemBasic, formVersionFromUrl: number) => {
    const versions: FormVersionTypes[] = get(form, 'versions', []);
    const version = versions.find(versionItem => versionItem.id === formVersionFromUrl);
    return version ? `${form.title} (${version.title})` : form.title;
};

export const getSelectedOptionInformation = (
    filters: FormFieldFilterType[],
    optionId: number,
): FieldOptionType | null => {
    for (let i = 0, n = filters.length; i < n; i++) {
        const options = filters[i].options;
        for (let j = 0, l = options.length; j < l; j++) {
            if (Number(options[j].id) === Number(optionId)) {
                return options[j];
            }
        }
    }
    return null;
};
