import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {SelectChangeEvent} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';
import {AGENT_LIST} from 'appRedux/actions/admin';
import {FORM_FILTERS_GET, FORMS_LIST} from 'appRedux/actions/forms';
import {AGENT_FILTERS_LIST} from 'appRedux/actions/agentFilters';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import Filter from 'components/AgentScreenComponents/_topBar/Filter';
import FilterMultiple from 'components/AgentScreenComponents/_topBar/FilterMultiple';
import SavedFiltersSelector from 'components/AgentScreenComponents/_topBar/SavedFiltersSelector';
import CurrentFiltersPanel from 'components/AgentScreenComponents/_caseBlock/CurrentFiltersPanel';
import SaveFilterButton from 'components/BlockView/SaveFilterButton';

import {
    getFormsForFilter,
    getFormFieldOptionsList,
    getAgentsForFilter,
    getTagsForFilter,
} from 'pages/agent/tableView/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {getDatePeriodFilters, VIEW_MODE_TABLE} from 'helpers/filter';

import {
    DEFAULT_FILTER_OPTION,
    DEFAULT_PAGE,
    PARAMETER_PAGE,
    PARAMETER_VIEW_MODE,
    PARAMETER_AGENT,
    PARAMETER_FORM,
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
} from 'config/index';
import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

const ActiveCasesFilterPanel: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {uuid} = useParams();

    const {filtersNumber, setSelectedIds} = useContext(AdminContext);
    const {isFormViewEnabled} = useContext(PermissionContext);
    const {isBoardCasesPage, isSwimlaneCasesPage} = useContext(RouteContext);

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);
    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);
    const getSavedFilters = useCallback(() => dispatch({type: AGENT_FILTERS_LIST.REQUEST}), [dispatch]);
    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            agentList,
            formsList,
            formFieldFilters: {formId, filters, tags, translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const viewModeFromUrl = searchParams.get(PARAMETER_VIEW_MODE);

    useEffect(() => {
        if (isFormViewEnabled && (isBoardCasesPage || isSwimlaneCasesPage) && uuid) {
            getFormsList({
                board: uuid,
            });
        } else if (isFormViewEnabled) {
            getFormsList({});
        }
        getAgents();
        getSavedFilters();
    }, []);

    const clearFormFilters = () => {
        filters.forEach((filter: FormFieldFilterType) => {
            searchParams.delete(`field${filter.id}`);
            setSearchParams(searchParams);
        });
    };

    const currentAgents = searchParams.get(PARAMETER_AGENT);
    const currentAgentsArray = currentAgents ? currentAgents.split(',') : [];

    const currentForms = searchParams.get(PARAMETER_FORM);
    const currentFormsArray = currentForms ? currentForms.split(',') : [];

    const handleSelectAgent = (event: SelectChangeEvent<string>) => {
        const option = event.target.value;
        if (option === DEFAULT_FILTER_OPTION) {
            if (currentAgentsArray.includes(DEFAULT_FILTER_OPTION)) {
                currentAgentsArray.splice(0, currentAgentsArray.length);
            } else if (agentList) {
                currentAgentsArray.splice(0, currentAgentsArray.length);
                const agents = getAgentsForFilter(agentList ?? []);
                agents.forEach(item => {
                    currentAgentsArray.push(String(item.name));
                });
            }
        } else {
            if (currentAgentsArray.includes(option)) {
                for (let i = 0, n = currentAgentsArray.length; i < n; i++) {
                    if (currentAgentsArray[i] === option || currentAgentsArray[i] === DEFAULT_FILTER_OPTION) {
                        currentAgentsArray.splice(i, 1);
                    }
                }
            } else {
                currentAgentsArray.push(option);
            }
        }

        searchParams.set(PARAMETER_AGENT, currentAgentsArray.join(','));
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);
    };

    const handleSelectForm = (event: SelectChangeEvent<string>) => {
        const option = event.target.value;
        if (currentFormsArray.includes(option)) {
            for (let i = 0, n = currentFormsArray.length; i < n; i++) {
                if (currentFormsArray[i] === option) {
                    currentFormsArray.splice(i, 1);
                }
            }
        } else {
            currentFormsArray.push(option);
        }
        searchParams.set(PARAMETER_FORM, currentFormsArray.join(','));
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);

        setSelectedIds([]);
        if (currentFormsArray.length === 1) {
            const selectedVersionId = currentFormsArray[0];
            getFormFieldFilters({
                id: selectedVersionId,
            });
        } else {
            clearFormFilters();
        }
    };

    const handleSelectLastCreated = (event: SelectChangeEvent<string>) => {
        searchParams.set(PARAMETER_LAST_CREATED, event.target.value);
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);
    };

    const handleSelectLastUpdated = (event: SelectChangeEvent<string>) => {
        searchParams.set(PARAMETER_LAST_UPDATED, event.target.value);
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);
    };

    const currentTags = searchParams.get(PARAMETER_TAG);
    const currentTagsArray = currentTags ? currentTags.split(',') : [];

    const currentOptions = searchParams.get(PARAMETER_OPTION);
    const currentOptionsArray = currentOptions ? currentOptions.split(',') : [];

    const handleSelectTags = (event: SelectChangeEvent<string>) => {
        const option = event.target.value;
        if (currentTagsArray.includes(option)) {
            for (let i = 0, n = currentTagsArray.length; i < n; i++) {
                if (currentTagsArray[i] === option) {
                    currentTagsArray.splice(i, 1);
                }
            }
        } else {
            currentTagsArray.push(option);
        }
        searchParams.set(PARAMETER_TAG, currentTagsArray.join(','));
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);
    };

    const handleSelectOptions = (event: SelectChangeEvent<string>) => {
        const option = event.target.value;
        if (currentOptionsArray.includes(option)) {
            for (let i = 0, n = currentOptionsArray.length; i < n; i++) {
                if (currentOptionsArray[i] === option) {
                    currentOptionsArray.splice(i, 1);
                }
            }
        } else {
            currentOptionsArray.push(option);
        }
        searchParams.set(PARAMETER_OPTION, currentOptionsArray.join(','));
        if (viewModeFromUrl === VIEW_MODE_TABLE) {
            searchParams.set(PARAMETER_PAGE, String(DEFAULT_PAGE));
        }
        setSearchParams(searchParams);
    };

    const showFormFiltersAndTags = currentFormsArray.length === 1;

    return (
        <Box
            sx={{
                position: 'sticky',
                top: 96,
                zIndex: 100,
                backgroundColor: CLIENT_BACKGROUND_COLOR,
                pl: 6,
                pr: 5,
                pt: 2,
                pb: 2,
            }}
        >
            <Divider />
            <Grid container sx={{pt: 3, pb: 1}}>
                <Grid item sm={1}></Grid>
                <Grid item sm={10}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <SaveFilterButton uuid={String(uuid)} />
                        <SavedFiltersSelector />
                        <FilterMultiple
                            filterName={'agent'}
                            filterLabel={t('orguser.requesterCase.topbar.agent')}
                            optionList={getAgentsForFilter(agentList ?? [])}
                            handleFilterChange={handleSelectAgent}
                            selectedIds={currentAgentsArray}
                        />
                        <FilterMultiple
                            filterName={'form'}
                            filterLabel={t('orguser.requesterCase.topbar.form')}
                            optionList={getFormsForFilter(formsList ?? [])}
                            handleFilterChange={handleSelectForm}
                            selectedIds={currentFormsArray}
                        />
                        {showFormFiltersAndTags &&
                            formId &&
                            filters &&
                            filters.map((filter: FormFieldFilterType, index: number) => {
                                const {id, label, options} = filter;
                                const fieldLabelKeyword = getFieldKeyword(formId, Number(id), 'label');
                                return (
                                    <FilterMultiple
                                        key={`option-${filter.id}-${index}`}
                                        filterName={label}
                                        filterLabel={getFormTranslatedLabel(translations, fieldLabelKeyword, label)}
                                        optionList={getFormFieldOptionsList(formId, options, translations)}
                                        handleFilterChange={handleSelectOptions}
                                        selectedIds={currentOptionsArray}
                                    />
                                );
                            })}
                        {showFormFiltersAndTags && (
                            <FilterMultiple
                                filterName={'tags'}
                                filterLabel={t('orguser.requesterCase.topbar.tags')}
                                optionList={getTagsForFilter(tags)}
                                handleFilterChange={handleSelectTags}
                                selectedIds={currentTagsArray}
                            />
                        )}
                        <Filter
                            filterName={'lastCreated'}
                            filterLabel={t('orguser.requesterCase.topbar.lastCreated')}
                            optionList={getDatePeriodFilters()}
                            handleFilterChange={handleSelectLastCreated}
                            parameter={PARAMETER_LAST_CREATED}
                            useFilterLabel
                        />
                        <Filter
                            filterName={'lastUpdated'}
                            filterLabel={t('orguser.requesterCase.topbar.lastUpdated')}
                            optionList={getDatePeriodFilters()}
                            handleFilterChange={handleSelectLastUpdated}
                            parameter={PARAMETER_LAST_UPDATED}
                            useFilterLabel
                        />
                    </Box>
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>
            <CurrentFiltersPanel filtersNumber={filtersNumber} />
            <Divider sx={{mt: filtersNumber > 0 ? 'inherit' : 2}} />
        </Box>
    );
};

export default ActiveCasesFilterPanel;
