import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {CHANGE_FAVORITE_BOARD_PRIORITY} from 'appRedux/actions/workflow';
import {BoardItemTypes} from 'appRedux/actions/workflow/types';

import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';

import MenuItemsBlock from 'components/AgentScreenComponents/MenuItemsBlock';
import {
    getSuperAdminBlockItems,
    getSuperAdminSecureBlockItems,
    AgentMenuItemTypes,
    getFirstAgentBlockItems,
    getBoardsItemsBlock,
    isMenuItemActive,
    isMenuSubItemActive,
} from 'components/AgentScreenComponents/helper';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import {CASE_ACCESS_REQUEST_CREATED} from 'pages/admin/accessRequests/helper';

import {getLanguageById} from 'helpers/translationsHelper';
import {userRoles, ITEMS_PER_PAGE_BOARD} from 'config/index';

interface AgentMenuItemsType {
    toggleAdminMenu: (value: string) => void;
    isMenuCollapsed: boolean;
}

const AgentMenuItems: FC<AgentMenuItemsType> = ({toggleAdminMenu, isMenuCollapsed}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {pathname, search} = useLocation();
    const {uuid} = useParams();

    const {isAgentPage} = useContext(RouteContext);
    const permissions = useContext(PermissionContext);
    const {showAlert} = useContext(AlertContext);
    const {myOrganization} = useContext(AdminContext);

    const {
        admin: {savedFilters, languageList},
        analytics: {dashboards},
        crypto: {caseAccessRequests},
        profile: {profile},
        requestChat: {contacts},
        workflow: {favoriteBoards, boards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const changeFavoriteBoardsPriority = useCallback(
        data => dispatch({type: CHANGE_FAVORITE_BOARD_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const onDragEnd = (dragItem: string, dropTo: number, clearAll: () => void) => {
        changeFavoriteBoardsPriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
        });

        clearAll();
    };

    const roles: string[] = get(profile, 'roles', []);
    if (profile && roles.includes(userRoles.ROLE_SUPER_ADMIN)) {
        const language = getLanguageById(languageList, profile.languageId);
        return (
            <Box sx={{mb: 3, width: '100%'}}>
                <MenuItemsBlock
                    isMenuCollapsed={isMenuCollapsed}
                    items={getSuperAdminBlockItems(language.name)}
                    toggleAdminMenu={toggleAdminMenu}
                />
                <MenuItemsBlock
                    isMenuCollapsed={isMenuCollapsed}
                    items={getSuperAdminSecureBlockItems}
                    toggleAdminMenu={toggleAdminMenu}
                />
            </Box>
        );
    }

    const createdCaseAccessRequests = caseAccessRequests.filter(item => item.status === CASE_ACCESS_REQUEST_CREATED);

    const isAdvancedMode: boolean = get(profile, 'isAdvancedMode', false);

    const firstAgentBlockItems: AgentMenuItemTypes[] = getFirstAgentBlockItems(
        get(contacts, [0, 'caseId'], null),
        contacts,
        savedFilters,
        dashboards,
        permissions,
        isAgentPage,
        createdCaseAccessRequests.length,
        isAdvancedMode,
        myOrganization,
    );

    const {boardsItems, favoriteBoardsItems} = useMemo(() => {
        const favoriteBoardsData = favoriteBoards
            .map(item => ({...(boards.find(board => board.uuid === item.uuid) || {}), ...item}))
            .filter(item => item) as BoardItemTypes[];

        const boardsData = boards.filter(item => !favoriteBoards.find(board => board.uuid === item.uuid));

        const boardsItems: AgentMenuItemTypes[] = boardsData
            ? getBoardsItemsBlock(boardsData, ITEMS_PER_PAGE_BOARD, permissions, savedFilters)
            : [];

        const favoriteBoardsItems: AgentMenuItemTypes[] = favoriteBoardsData
            ? getBoardsItemsBlock(favoriteBoardsData, ITEMS_PER_PAGE_BOARD, permissions, savedFilters)
            : [];

        return {boardsItems, favoriteBoardsItems};
    }, [favoriteBoards, boards, ITEMS_PER_PAGE_BOARD, permissions, savedFilters]);

    const [showAllBoardsButton, setShowAllBoardsButton] = useState(false);
    const [showAllBoards, setShowAllBoards] = useState(false);

    useEffect(() => {
        const activeIndex = boardsItems.findIndex(
            menuItem =>
                isMenuItemActive(menuItem, pathname, search) || isMenuSubItemActive(menuItem, pathname, search, uuid),
        );
        const maxItems = Math.max(8 - favoriteBoards.length, 4);

        boardsItems.length > maxItems && activeIndex < maxItems
            ? setShowAllBoardsButton(true)
            : setShowAllBoardsButton(false);
        activeIndex >= maxItems && setShowAllBoards(true);
    }, [boardsItems, favoriteBoards, pathname, search]);

    console.log('showAllBoards', showAllBoards, 'showAllBoardsButton', showAllBoardsButton);

    if (roles.includes(userRoles.ROLE_AGENT) || roles.includes(userRoles.ROLE_ADMIN)) {
        return (
            <Box sx={{mb: 3, width: '100%'}}>
                <MenuItemsBlock
                    isMenuCollapsed={isMenuCollapsed}
                    items={firstAgentBlockItems}
                    toggleAdminMenu={toggleAdminMenu}
                />
                {favoriteBoardsItems.length > 0 && (
                    <MenuItemsBlock
                        isMenuCollapsed={isMenuCollapsed}
                        items={favoriteBoardsItems}
                        toggleAdminMenu={toggleAdminMenu}
                        onDragEnd={onDragEnd}
                    />
                )}
                {boardsItems.length > 0 && (
                    <MenuItemsBlock
                        isMenuCollapsed={isMenuCollapsed}
                        items={
                            showAllBoards
                                ? boardsItems
                                : boardsItems.slice(0, Math.max(8 - favoriteBoardsItems.length, 4))
                        }
                        toggleAdminMenu={toggleAdminMenu}
                    />
                )}
                {showAllBoardsButton && !showAllBoards && !isMenuCollapsed && boardsItems && boardsItems.length > 0 && (
                    <Box sx={{mx: 'auto', display: 'flex', justifyContent: 'center', mt: 1}}>
                        <AgentInverseButton
                            title={t('common.buttons.showAll')}
                            sx={{height: '28px'}}
                            onClick={() => setShowAllBoards(true)}
                        />
                    </Box>
                )}
            </Box>
        );
    }
    return null;
};

export default AgentMenuItems;
