import React, {FC, useContext, DragEvent, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useBottomScrollListener} from 'react-bottom-scroll-listener';
import {useSearchParams} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {RootReducer} from 'appRedux/reducers';
import {REQUESTER_CASES_LIST} from 'appRedux/actions/admin';
import {FormFieldFilterType} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import RequesterCaseBlock from 'components/RequesterCaseBlocks/RequesterCaseBlock';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import DragPlaceholderBlock from 'components/BlockView/DragPlaceholderBlock';
import {BoardViewCasesTypes, getDragColumnColor} from 'components/BlockView/helper';
import RequesterCaseStack from 'components/RequesterCaseBlocks/RequesterCaseStack';

import {getFormFieldOptions, getRequesterCases} from 'pages/agent/tableView/helper';

import {
    PARAMETER_TAG,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_SORT_BY,
    PARAMETER_SORT_TYPE,
    STATUS_OPENING,
} from 'config/index';

interface CasesColumnType {
    item: BoardViewCasesTypes;
    isActiveCases?: boolean;
    height?: number;
}

const CasesColumn: FC<CasesColumnType> = ({item, isActiveCases, height}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {status, statusType, permittedUuids, cases} = item;

    const {isCaseEditEnabled} = useContext(PermissionContext);
    const {draggedCasePermittedUuids, setDragType, isDragStart, draggedCaseInitialStatus} = useContext(AdminContext);

    const {
        profile: {profile},
        admin: {requesterCasesColumns, formFieldFilters},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getRequesterCasesCustomBoardColumn = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_COLUMN, payload: data}),
        [dispatch],
    );

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);

    const onDragEnter = (e: DragEvent<HTMLDivElement>, type: string) => {
        if (!isCaseEditEnabled) {
            return;
        }
        setDragType(type);
    };

    const currentUserEmail = get(profile, 'email', null);

    const totalItems = get(requesterCasesColumns, [status, 'count'], 0);
    const showLoadMore = cases && cases.length < totalItems;

    const handleContainerOnBottom = () => {
        if (!showLoadMore) return;
        getRequesterCasesCustomBoardColumn({
            uuid: status,
            last: cases.length,
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    };

    const containerRef = useBottomScrollListener(handleContainerOnBottom, {
        offset: 100,
        debounce: 0,
    });

    const filters: FormFieldFilterType[] = get(formFieldFilters, 'filters', []);
    const filteredCases = cases
        ? getRequesterCases(
              cases,
              searchParams.get(PARAMETER_SORT_BY),
              searchParams.get(PARAMETER_SORT_TYPE),
              getFormFieldOptions(filters, searchParams),
          )
        : [];

    const groupedCases: (RequesterCaseResponseTypes | RequesterCaseResponseTypes[])[] = [];

    filteredCases.forEach(item => {
        if (item.stack?.uuid) {
            const index = groupedCases.findIndex(
                stackItem => Array.isArray(stackItem) && stackItem[0]?.stack?.uuid === item.stack?.uuid,
            );
            if (index >= 0) {
                groupedCases[index] = [...(groupedCases[index] as RequesterCaseResponseTypes[]), item];
            } else {
                groupedCases.push([item]);
            }
        } else {
            groupedCases.push(item);
        }
    });

    const isDragPossible = permittedUuids && draggedCasePermittedUuids.includes(status);
    const isColumnHighlighted = isDragStart && draggedCaseInitialStatus !== status;

    return (
        <Grid
            item
            key={`column-${status}`}
            sm={isActiveCases ? 4 : 2}
            sx={{
                overflow: 'hidden',
                height: height ? height + 'px' : 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
            }}
            onDragEnter={e => onDragEnter(e, status)}
            draggable={isCaseEditEnabled && statusType !== STATUS_OPENING}
        >
            <Box
                ref={containerRef}
                sx={{
                    overflowY: 'scroll',
                    height: height + 'px',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    backgroundColor: isColumnHighlighted ? getDragColumnColor(isDragPossible) : 'initial',
                    border: isColumnHighlighted ? '1px dashed rgba(34, 34, 34, 0.2)' : 'initial',
                    borderRadius: isColumnHighlighted ? 2 : 'initial',
                    p: 1,
                    ml: 0.5,
                    mr: 0.5,
                }}
            >
                {groupedCases.map((caseItem: RequesterCaseResponseTypes | RequesterCaseResponseTypes[]) => {
                    if (Array.isArray(caseItem)) {
                        return (
                            <RequesterCaseStack
                                key={`${status}-${caseItem[0].caseId}`}
                                items={caseItem}
                                permittedUuids={permittedUuids}
                                currentUserEmail={currentUserEmail}
                            />
                        );
                    }
                    return (
                        <RequesterCaseBlock
                            key={`${status}-${caseItem.caseId}`}
                            item={caseItem}
                            permittedUuids={permittedUuids}
                            currentUserEmail={currentUserEmail}
                        />
                    );
                })}
                {isCaseEditEnabled && draggedCaseInitialStatus !== status && <DragPlaceholderBlock />}
            </Box>
        </Grid>
    );
};

export default CasesColumn;
