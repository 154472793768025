import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {
    UPDATE_CHECKED_SECTIONS,
    REQUEST_CASE_QUESTION,
    UPDATE_CHECKED_SECTIONS_FOR_STACK,
} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import EmptyArea from 'components/EmptyArea';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import LargeCustomSwitcher from 'components/AgentScreenComponents/_buttons/LargeCustomSwitcher';

import {getSectionContent, SectionContentType} from 'pages/admin/updateForm/partials/FormStructure/helper';
import SectionWrapper from 'pages/agent/requesterPage/wrappers/SectionWrapper';
import {getCompletedItemsNumber} from 'pages/agent/requesterPage/partials/helper';
import NewCommentPanel from 'pages/agent/requesterPage/common/NewCommentPanel';
import CommentsList from 'pages/agent/requesterPage/common/CommentsList';
import {getInitialClientTranslations} from 'pages/agent/requesterPage/partials/helper';
import SectionContentItem from 'pages/agent/requesterPage/sections/SectionContentItem';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {getSectionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {PARAMETER_SECTION_ID} from 'config/index';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface SectionItemContentType {
    formId: number;
    section: FormSectionTypes;
    content: RequesterCaseItemType;
    toggleOutdatedInfo: () => void;
    toggleIsChatOpened: () => void;
}

const SectionContent: FC<SectionItemContentType> = ({
    formId,
    toggleIsChatOpened,
    toggleOutdatedInfo,
    section,
    content,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();
    const [searchParams] = useSearchParams();

    const {fields, popups, id, title, description} = section;

    const sectionContentRef = useRef<HTMLDivElement>();

    const {showAlert} = useContext(AlertContext);
    const {setQuestionSectionId, setQuestionSectionName} = useContext(AdminContext);
    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const [showTranslation, setShowTranslation] = useState<boolean>(false);
    const [editTranslation, setEditTranslation] = useState<boolean>(false);
    const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);
    const [clientTranslations, setClientTranslations] = useState<ClientTranslationUpdatedType>();

    const currentSectionId = searchParams.get(PARAMETER_SECTION_ID);

    const toggleIsCommentsExpanded = () => {
        setIsCommentsExpanded(previous => !previous);
    };

    const checkSection = useCallback(
        data => dispatch({type: UPDATE_CHECKED_SECTIONS.REQUEST, payload: data}),
        [dispatch],
    );

    const checkSectionForStack = useCallback(
        data => dispatch({type: UPDATE_CHECKED_SECTIONS_FOR_STACK.REQUEST, payload: data}),
        [dispatch],
    );

    const activateChat = useCallback(
        data => dispatch({type: REQUEST_CASE_QUESTION.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {
            formInfo: {translations},
        },
        profile: {profile},
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {agentEmail, checkedSections, readAt, userName, userEmail, comments, stack} = currentCase;

    const agentId = get(profile, 'id', null);

    const totalItems = fields.length + popups.length;
    const completedItems = content ? getCompletedItemsNumber(fields, popups, content) : 0;

    const sectionContent: SectionContentType[] = getSectionContent(fields, popups);
    const currentUserEmail = get(profile, 'email', null);
    const isSectionChecked = checkedSections.some(item => Number(item) === Number(id));

    const sectionTitle = getFormTranslatedLabel(translations, getSectionKeyword(formId, id, 'title'), title);
    const sectionDescription = getFormTranslatedLabel(
        translations,
        getSectionKeyword(formId, id, 'description'),
        description,
    );

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const onQuestionClicked = () => {
        activateChat({
            id: caseId,
            readAt,
            showAlert,
            callback: () => {
                setQuestionSectionId(id);
                setQuestionSectionName(sectionTitle);
                toggleIsChatOpened();
            },
            toggleOutdatedInfo,
        });
    };

    const onCheckedClick = () => {
        checkSection({
            id: caseId,
            agentId,
            sectionId: id,
            readAt,
            showAlert,
            toggleOutdatedInfo,
        });
    };

    const onCheckForStackClick = () => {
        checkSectionForStack({
            id: caseId,
            agentId,
            sectionId: id,
            readAt,
            showAlert,
            toggleOutdatedInfo,
        });
    };

    useEffect(() => {
        if (currentCase && Array.isArray(currentCase.translations) && currentCase.translations.length > 0) {
            setClientTranslations(getInitialClientTranslations(id, currentCase.translations));
        }
    }, [currentCase, id]);

    useEffect(() => {
        setShowTranslation(false);
        setEditTranslation(false);
    }, [id]);

    useEffect(() => {
        if (currentSectionId && Number(currentSectionId) === Number(id)) {
            if (sectionContentRef && sectionContentRef.current) {
                sectionContentRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }, [currentSectionId]);

    return (
        <SectionWrapper
            id={id}
            title={sectionTitle}
            description={sectionDescription}
            isChecked={isSectionChecked}
            completedItems={completedItems}
            totalItems={totalItems}
            showTranslation={showTranslation}
            setShowTranslation={setShowTranslation}
            editTranslation={editTranslation}
            setEditTranslation={setEditTranslation}
            clientTranslations={clientTranslations}
        >
            <Box ref={sectionContentRef}>
                {sectionContent.map((item: SectionContentType, index: number) => {
                    return (
                        <SectionContentItem
                            key={`section-content-item-${index}`}
                            formId={formId}
                            showTranslation={showTranslation}
                            editTranslation={editTranslation}
                            clientTranslations={clientTranslations}
                            setClientTranslations={setClientTranslations}
                            item={item}
                            section={section}
                            content={content}
                        />
                    );
                })}
                {currentUserEmail === agentEmail && isEditEnabled && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1,
                        }}
                    >
                        {/*<AgentInverseButton title={ignoreTagsTranslation(t, 'hideTranslation')} />*/}
                        <AgentInverseButton
                            onClick={onQuestionClicked}
                            title={`${t('orguser.requesterCase.messageTo')} ${userName ?? userEmail}`}
                        />
                        <EmptyArea />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mb: 2,
                            }}
                        >
                            {!isSectionChecked && stack && (
                                <AgentSaveButton
                                    title={t('common.buttons.checkForStack')}
                                    onClick={onCheckForStackClick}
                                    sx={{my: 0, mr: 2}}
                                />
                            )}
                            <Typography sx={{fontWeight: 600, fontSize: 12, mr: 2}}>
                                {t('orguser.requesterCase.checked')}
                            </Typography>
                            <LargeCustomSwitcher checked={isSectionChecked} onClick={onCheckedClick} />
                        </Box>
                    </Box>
                )}
                <Divider sx={{mt: 1, mb: 1}} />
                <CommentsList
                    comments={comments}
                    sectionId={id}
                    isCommentsExpanded={isCommentsExpanded}
                    toggleIsCommentsExpanded={toggleIsCommentsExpanded}
                />
                {isEditEnabled && <NewCommentPanel sectionId={id} setIsCommentsExpanded={setIsCommentsExpanded} />}
            </Box>
        </SectionWrapper>
    );
};

export default SectionContent;
