import React, {FC, useContext, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {getSlotStatusLabel, getSlotStatusDateTime} from 'pages/client/form/fieldTypes/helper';
import SlotsSelector from 'pages/client/form/fieldTypes/resourceFields/SlotsSelector';

import {ERROR_TEXT_COLOR} from 'config/theme';

interface ResourceFieldSlotType {
    formId: number;
    sectionId: number;
    field: FormFieldTypes;
}

const ResourceFieldSlot: FC<ResourceFieldSlotType> = ({formId, sectionId, field}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField} = useContext(ClientFormContext);

    const {id, hasAvailableSlots, resourceField, resourceFieldSlot} = field;

    const {
        requestCase: {currentCaseSlots},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const currentSlot = currentCaseSlots ? currentCaseSlots.find(slot => slot.slotId === resourceFieldSlot) : null;

    return (
        <FormFieldWrapper wrapperRef={ref} formId={formId} field={field}>
            <Box sx={{position: 'relative'}}>
                {!currentSlot && !hasAvailableSlots && (
                    <Alert sx={{mt: 1, mb: 1}} severity={'error'}>
                        <Typography sx={{fontSize: 14}}>{t('requester.casePage.availableSlotsAbsent')}</Typography>
                    </Alert>
                )}
                {resourceField && !resourceFieldSlot && hasAvailableSlots && (
                    <SlotsSelector field={field} sectionId={sectionId} />
                )}
                {currentSlot && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                            getSlotStatusLabel(currentSlot.status),
                        )}:`}</Typography>
                        <Typography>{getSlotStatusDateTime(currentSlot)}</Typography>
                    </Box>
                )}
                {id === errorField && (
                    <Typography sx={{color: ERROR_TEXT_COLOR, fontSize: 12}}>
                        {t('requester.casePage.availableSlotsAbsentSubmitImpossible')}
                    </Typography>
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default ResourceFieldSlot;
