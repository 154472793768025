import React, {FC, MouseEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import {RootReducer} from 'appRedux/reducers';

import AgentSelectButton from 'components/AgentScreenComponents/_buttons/AgentSelectButton';

import RequestTranslationMenuItem from 'pages/agent/requesterPage/translation/RequestTranslationMenuItem';
import AutoTranslationMenuItem from 'pages/agent/requesterPage/translation/AutoTranslationMenuItem';

import {theme} from 'config/theme';

interface TranslationSelectorType {
    sectionId: number;
    showTranslation: boolean;
    setShowTranslation: (value: boolean) => void;
    setEditTranslation: (value: boolean) => void;
}

const TranslationSelector: FC<TranslationSelectorType> = ({
    sectionId,
    showTranslation,
    setShowTranslation,
    setEditTranslation,
}) => {
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        admin: {organizationList},
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <AgentSelectButton
                title={t('orguser.requesterCase.translation.translations')}
                onClick={handleClick}
                open={open}
            />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem
                    onClick={() => {
                        setShowTranslation(!showTranslation);
                        handleClose();
                    }}
                >
                    <Typography>
                        {t(
                            showTranslation
                                ? 'orguser.requesterCase.translation.hideTranslation'
                                : 'orguser.requesterCase.translation.showTranslations',
                        )}
                    </Typography>
                </MenuItem>
                <RequestTranslationMenuItem sectionId={sectionId} handleClose={handleClose} />
                {!isEncryptInfo && myOrganization && myOrganization.isGoogleTranslationAllowed && (
                    <AutoTranslationMenuItem
                        sectionId={sectionId}
                        setShowTranslation={setShowTranslation}
                        setEditTranslation={setEditTranslation}
                        handleClose={handleClose}
                    />
                )}
            </Menu>
        </>
    );
};

export default TranslationSelector;
