import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {RootReducer} from 'appRedux/reducers';
import {GET_REQUESTER_CASE, REQUEST_CASE_ACTIVITIES} from 'appRedux/actions/requestCase';
import {FORM_BY_CASE_INFORMATION} from 'appRedux/actions/forms';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';
import CaseKeyContextWrapper from 'contexts/caseKey';

import ActivityPageContent from 'pages/agent/activityPage/ActivityPageContent';

const ActivityPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {setPageTitle, setBackLink, setBackLinkLabel} = useContext(RouteContext);

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {isEncryptInfo} = currentCase;

    const getFormByCaseInformation = useCallback(
        data => dispatch({type: FORM_BY_CASE_INFORMATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseActivity = useCallback(
        data => dispatch({type: REQUEST_CASE_ACTIVITIES.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        setBackLink('');
        setBackLinkLabel(t('common.links.goBack'));
    }, []);

    useEffect(() => {
        if (caseId) {
            getFormByCaseInformation({
                id: caseId,
            });
        }
    }, [caseId]);

    useEffect(() => {
        setPageTitle(t('orguser.activities.activity'));
        getRequesterCaseInfo({id: caseId});
        getRequesterCaseActivity({id: caseId, showAlert});
        return () => {
            setPageTitle('');
        };
    }, []);

    if (isEncryptInfo) {
        return (
            <CaseKeyContextWrapper caseId={Number(caseId)}>
                <ActivityPageContent />
            </CaseKeyContextWrapper>
        );
    }
    return <ActivityPageContent />;
};

export default ActivityPage;
