import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {GET_GROUPS_LIST} from 'appRedux/actions/groups';
import {GET_WORKFLOWS_LIST, REFRESH_WORKFLOW_GRAPH} from 'appRedux/actions/workflow';
import {GET_ORGANIZATION_LANGUAGES_LIST} from 'appRedux/actions/organizationLanguage';

import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {PermissionContext} from 'contexts/permission/context';
import {AlertContext} from 'contexts/alert/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';
import UpdateWorkflowForm from 'components/Forms/Workflow/workflow/UpdateWorkflowForm';
import {WORKFLOWS_LIST} from 'components/AgentScreenComponents/helper';

import WorkflowStatusesList from 'pages/admin/updateWorkflow/WorkflowStatusesList';
import ChangeLanguageButton from 'pages/admin/updateWorkflow/partials/ChangeLanguageButton';

import {getLanguageById} from 'helpers/translationsHelper';

import {PARAMETER_TAB, routes} from 'config/index';

const UpdateWorkflow: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {uuid} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);
    const {setBackLink, setBackLinkLabel, setTopBarTitle, setTopBarSubtitle, isAgentPage} = useContext(RouteContext);

    const {
        admin: {languageList},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const getGroupList = useCallback(data => dispatch({type: GET_GROUPS_LIST.REQUEST, payload: data}), [dispatch]);

    const getOrganizationLanguages = useCallback(
        data => dispatch({type: GET_ORGANIZATION_LANGUAGES_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const refreshGraph = useCallback(() => dispatch({type: REFRESH_WORKFLOW_GRAPH.REQUEST}), [dispatch]);

    const currentWorkflow = organizationWorkflows.find(item => item.uuid === uuid);

    useEffect(() => {
        if (myOrganization) {
            getWorkflowsList({
                organizationId: myOrganization.id,
                showAlert,
            });
            getOrganizationLanguages({
                id: myOrganization.id,
                showAlert,
            });
            getGroupList({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    useEffect(() => {
        const path = isAgentPage ? routes.AGENT : routes.ADMIN;
        setBackLink(`${path}?${PARAMETER_TAB}=${WORKFLOWS_LIST}`);
        setBackLinkLabel(t('common.links.backToWorkflows'));
        setTopBarTitle('');
        setTopBarSubtitle('');
    }, []);

    useEffect(() => {
        return () => {
            refreshGraph();
        };
    }, []);

    const currentOrganizationDefaultLanguage = myOrganization
        ? getLanguageById(languageList, myOrganization.language)
        : null;

    if (!isOrganizationEditEnabled || !currentWorkflow || !currentOrganizationDefaultLanguage) {
        return null;
    }
    return (
        <ClientSectionWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                }}
            >
                <UpdateWorkflowForm currentWorkflow={currentWorkflow} />
                <ChangeLanguageButton organizationLanguage={currentOrganizationDefaultLanguage} />
            </Box>
            <WorkflowStatusesList
                currentWorkflow={currentWorkflow}
                currentOrganizationDefaultLanguage={currentOrganizationDefaultLanguage}
            />
        </ClientSectionWrapper>
    );
};

export default UpdateWorkflow;
