import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';

import {GET_NOTIFICATIONS_LIST, REMOVE_ALL_NOTIFICATIONS} from 'appRedux/actions/notifications';
import {NotificationType} from 'appRedux/actions/notifications/types';
import {RootReducer} from 'appRedux/reducers';

import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import NotificationMenuItem from 'components/Notifications/NotificationMenuItem';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {AVATAR_SIZE} from 'config/index';
import {MAIN_CLIENT_COLOR, theme} from 'config/theme';

const Notifications: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (notifications && notifications.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getNotifications = useCallback(() => dispatch({type: GET_NOTIFICATIONS_LIST.REQUEST}), [dispatch]);

    const clearAllNotifications = useCallback(
        data => dispatch({type: REMOVE_ALL_NOTIFICATIONS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        notifications: {notifications},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const onClearAllClick = () => {
        clearAllNotifications({
            showAlert,
            callback: handleClose,
        });
    };

    useEffect(() => {
        getNotifications();
    }, []);

    const notReviewedNotifications = notifications.filter(item => !item.isReviewed);

    return (
        <>
            <IconButton sx={{width: AVATAR_SIZE, height: AVATAR_SIZE, mr: 3}} onClick={handleClick}>
                <Badge
                    badgeContent={notReviewedNotifications.length}
                    sx={{
                        color: theme.palette.background.default,
                        '& .MuiBadge-badge': {
                            top: 2,
                            right: 2,
                            backgroundColor: MAIN_CLIENT_COLOR,
                        },
                    }}
                    invisible={notReviewedNotifications.length === 0}
                >
                    {open ? (
                        <NotificationsNoneIcon
                            sx={{color: isClientMode ? theme.palette.background.default : theme.palette.info.main}}
                        />
                    ) : (
                        <NotificationsIcon
                            sx={{color: isClientMode ? theme.palette.background.default : theme.palette.info.main}}
                        />
                    )}
                </Badge>
            </IconButton>
            <Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            position: 'relative',
                            mt: 1,
                            width: 450,
                            borderRadius: 2,
                            top: 10,
                            boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                            backgroundColor: theme.palette.background.default,
                            maxHeight: '85vh',
                            overflowX: 'auto',
                            '& ul': {
                                padding: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    <MenuList sx={{p: 2}}>
                        {notifications &&
                            notifications.map((notification: NotificationType) => {
                                return (
                                    <NotificationMenuItem
                                        key={`notification-${notification.uuid}`}
                                        item={notification}
                                        closePanel={handleClose}
                                    />
                                );
                            })}
                    </MenuList>
                    {notifications && notifications.length > 0 && (
                        <Box
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: `0px 0px 16px 0px rgba(0, 0, 0, 0.1)`,
                                width: '100%',
                                p: 2,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit={false}
                                onClick={onClearAllClick}
                                title={t('common.buttons.clearAll')}
                            />
                        </Box>
                    )}
                </Menu>
            </Box>
        </>
    );
};

export default Notifications;
