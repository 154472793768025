import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {ClientTranslationUpdatedType} from 'appRedux/actions/clientTranslation/types';
import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';

import PopupInformationItem from 'pages/agent/requesterPage/sections/PopupInformationItem';

import {theme} from 'config/theme';

interface PopupInformationType {
    formId: number;
    sectionId: number;
    popup: FormPopupTypes;
    items: RequesterCasePopupType[];
    showTranslation: boolean;
    editTranslation: boolean;
    clientTranslations?: ClientTranslationUpdatedType;
    setClientTranslations?: (value: ClientTranslationUpdatedType) => void;
}

const PopupInformation: FC<PopupInformationType> = ({
    formId,
    sectionId,
    popup,
    items,
    setClientTranslations,
    clientTranslations,
    showTranslation,
    editTranslation,
}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button onClick={toggleIsOpened} sx={{m: 0, p: 0}}>
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >{`${items.length} ${t('orguser.requesterCase.entries')}`}</Typography>
                    {isOpened ? (
                        <KeyboardArrowUp sx={{color: theme.palette.info.main}} />
                    ) : (
                        <KeyboardArrowDown sx={{color: theme.palette.info.main}} />
                    )}
                </Button>
            </Box>
            {isOpened && (
                <Box sx={{mt: 1}}>
                    {items.map((item: RequesterCasePopupType, index: number) => {
                        return (
                            <PopupInformationItem
                                key={`popup-${index}`}
                                formId={formId}
                                sectionId={sectionId}
                                popup={popup}
                                item={item}
                                showTranslation={showTranslation}
                                editTranslation={editTranslation}
                                clientTranslations={clientTranslations}
                                setClientTranslations={setClientTranslations}
                                popupItem={index + 1}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default PopupInformation;
