import React, {FC, ChangeEvent, useRef, useEffect, useContext, useState, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import Markdown from 'react-markdown';
import {useParams} from 'react-router-dom';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_OPTION, UPDATE_REQUESTER_CASE_POPUP_OPTION} from 'appRedux/actions/requestCase';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';
import {FieldOptionType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {isRequesterCaseOptionPresented} from 'pages/client/form/fieldTypes/helper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {ignoreHtmlInString, getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

const FormRadio: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    popupContent,
    disabled,
    previewMode,
    popupItemId,
    onSaveClicked,
}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [previewModeSelectedOption, setPreviewModeSelectedOption] = useState<number | null>(null);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const updateRequesterCaseOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const updateRequesterCasePopupOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const options = field.fieldOptions || [];
    const keywordsOptions = options.map(opt => getOptionKeyword(formId, opt.id, opt.text ? 'text' : 'title'));
    const translatedOptions = keywordsOptions.map((keyword, i) =>
        getFormTranslatedLabel(translations, keyword, options[i].text || options[i].title),
    );
    const initialValue = getInitialFieldValue(pageId, sectionId, field.id, clientInformation, isPopup, popupContent);

    const requesterCaseOptions: RequesterCaseOptionTypes[] = useMemo(() => {
        if (isPopup && popupItemId) {
            const currentItem = currentCase.popupItems.find(item => item.id === popupItemId);
            return currentItem?.options || [];
        } else {
            return get(currentCase, 'options', []);
        }
    }, [currentCase, isPopup, popupItemId]);

    const onRadioSelect = (e: ChangeEvent<{value: string}>) => {
        setErrorField && setErrorField(null);
        if (previewMode) {
            setPreviewModeSelectedOption(Number(e.target.value));
            setIsUpdated(previous => !previous);
        } else if (handleChangeInformation) {
            updateRequesterCaseOption({
                id: Number(requestCase),
                optionId: Number(e.target.value),
                showAlert,
                callback: () => {
                    handleChangeInformation(pageId, sectionId, field.id, e.target.value);
                    setIsInitialRender(false);
                    setIsUpdated(previous => !previous);
                },
            });
        }
    };

    const handlePopupFieldChange = (e: ChangeEvent<{value: string}>) => {
        setErrorField && setErrorField(null);
        if (previewMode) {
            setPreviewModeSelectedOption(Number(e.target.value));
            setIsUpdated(previous => !previous);
        } else if (onPopupFieldChange) {
            updateRequesterCasePopupOption({
                id: Number(requestCase),
                optionId: Number(e.target.value),
                requesterCaseHasPopupId: popupItemId,
                showAlert,
                callback: () => {
                    onPopupFieldChange(field.id, e.target.value);
                    setIsInitialRender(false);
                    setIsUpdated(previous => !previous);
                },
            });
        }
    };

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
        >
            <RadioGroup value={initialValue}>
                {options.map((option: FieldOptionType, index: number) => {
                    const translatedValue = get(translatedOptions, index, null);
                    const value =
                        !previewMode && translatedValue
                            ? ignoreHtmlInString(translatedValue)
                            : option.text || option.title;
                    return (
                        <FormControlLabel
                            key={`radio-${index}`}
                            value={option.id}
                            disabled={disabled}
                            control={
                                <Radio
                                    data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-${index}`}
                                    checked={
                                        previewMode
                                            ? previewModeSelectedOption === option.id
                                            : isRequesterCaseOptionPresented(option.id, requesterCaseOptions)
                                    }
                                    onChange={isPopup ? handlePopupFieldChange : onRadioSelect}
                                />
                            }
                            label={
                                option.text ? (
                                    <Markdown>{value}</Markdown>
                                ) : (
                                    <Typography variant="body1">{value}</Typography>
                                )
                            }
                        />
                    );
                })}
            </RadioGroup>
        </FormSelectorWrapper>
    );
};

export default FormRadio;
