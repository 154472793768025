import {
    RequesterCaseUpdatingTypes,
    RequesterCaseOptionUpdatingTypes,
    RequesterAllCaseTypes,
    RequesterCaseTypes,
    RequesterCaseGettingTypes,
    UploadRequesterCaseTypes,
    UploadRequesterCaseResponseTypes,
    RequesterPreparedStatusType,
    DeleteRequesterDocumentTypes,
    RequestCaseAssignAgentType,
    RequestCaseChangeStatusType,
    RequesterCaseCreateRequestTypes,
    RequesterCaseCreateResponseTypes,
    RequestCaseChatActivateRequestTypes,
    RequesterCaseAddTagRequestTypes,
    RequesterCaseRemoveTagRequestTypes,
    RequesterCaseTagTypes,
    RequestCaseQuestionRequestTypes,
    RequesterCaseActivitiesRequestTypes,
    RequesterCaseActivitiesExportRequestTypes,
    RequesterCaseActivitiesTypes,
    RequesterCaseAttachmentsForArchiveTypes,
    CaseWatcherCreatingTypes,
    CaseWatcherRemovingTypes,
    CaseWatcherTypes,
    RequesterCaseApproveTypes,
    StatusChangeApproveUpdateRequestType,
    UnsetStatusChangeApproveRequestType,
    DeleteAllStatusChangeApproveRequestType,
    RequesterCaseEmailRequestTypes,
    EditRequesterDocumentTypes,
    UploadRequesterSignatureTypes,
    UploadRequesterSignatureResponseTypes,
    InviteAdditionalFormCreateRequestTypes,
    InviteAdditionalFormDeleteRequestTypes,
    InviteAdditionalFormTypes,
    CaseHasTaskIgnoringTypes,
    SubFormRequestTypes,
    CreateSubFormRequestFormTypes,
    SubFormRequestSubmittingRequestTypes,
    SubFormRequestDeleteRequestTypes,
    RequesterCaseToggleRemovingPossibilityRequestTypes,
    CaseHasTasksTypes,
    RequesterAllCaseItemTypes,
    RequesterCaseUpdateResultsResponse,
    RequesterCaseFilesTypes,
    ChatFileType,
    RequesterCaseDrawerTypes,
    RequesterCaseSlotsListRequestTypes,
    RequesterCaseHasSlotCreationRequestTypes,
    RequesterCaseHasSlotDeletingRequestTypes,
    RequesterCaseHasSlotTypes,
    RequesterCaseBudgetsListRequestTypes,
    RequesterCaseBudgetCreateRequestTypes,
    RequesterCaseBudgetUpdateRequestTypes,
    RequesterCaseBudgetDeleteRequestTypes,
    RequesterCaseHasBudgetTypes,
    RequesterCasePopupType,
    RequesterCasePopupCreatingTypes,
    RequesterCasePopupUpdatingTypes,
    RequesterCasePopupDeletingTypes,
    RequesterCasePopupOptionUpdatingTypes,
} from 'appRedux/actions/requestCase/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_ALL_REQUESTER_CASES = requestTypeCreator('GET_ALL_REQUESTER_CASES', {
    REQUEST_BACKGROUND: 'REQUEST_BACKGROUND',
});
export const GET_REQUESTER_CASE = requestTypeCreator('GET_REQUESTER_CASE', {
    REQUEST_BACKGROUND: 'REQUEST_BACKGROUND',
});
export const GET_REQUESTER_CASE_DRAWER = requestTypeCreator('GET_REQUESTER_CASE_DRAWER');
export const GET_REQUESTER_CASE_ALL_FILES = requestTypeCreator('GET_REQUESTER_CASE_ALL_FILES');
export const GET_REQUESTER_CASE_CHAT_FILES = requestTypeCreator('GET_REQUESTER_CASE_CHAT_FILES');
export const CREATE_REQUESTER_CASE = requestTypeCreator('CREATE_REQUESTER_CASE');
export const UPDATE_REQUESTER_CASE = requestTypeCreator('UPDATE_REQUESTER_CASE');
export const UPDATE_REQUESTER_CASE_OPTION = requestTypeCreator('UPDATE_REQUESTER_CASE_OPTION');
export const UPDATE_CHECKED_SECTIONS = requestTypeCreator('UPDATE_CHECKED_SECTIONS');
export const UPDATE_CHECKED_SECTIONS_FOR_STACK = requestTypeCreator('UPDATE_CHECKED_SECTIONS_FOR_STACK');

export const UPLOAD_REQUESTER_DOCUMENT = requestTypeCreator('UPLOAD_REQUESTER_DOCUMENT');
export const DELETE_REQUESTER_DOCUMENT = requestTypeCreator('DELETE_REQUESTER_DOCUMENT');
export const EDIT_REQUESTER_DOCUMENT = requestTypeCreator('EDIT_REQUESTER_DOCUMENT');

export const UPLOAD_REQUESTER_SIGNATURE = requestTypeCreator('UPLOAD_REQUESTER_SIGNATURE');
export const DELETE_REQUESTER_SIGNATURE = requestTypeCreator('DELETE_REQUESTER_SIGNATURE');

export const GET_REQUESTER_CASE_POPUPS = requestTypeCreator('GET_REQUESTER_CASE_POPUPS');
export const CREATE_REQUESTER_POPUP = requestTypeCreator('CREATE_REQUESTER_POPUP');
export const UPDATE_REQUESTER_POPUP = requestTypeCreator('UPDATE_REQUESTER_POPUP');
export const DELETE_REQUESTER_POPUP = requestTypeCreator('DELETE_REQUESTER_POPUP');
export const UPDATE_REQUESTER_CASE_POPUP_OPTION = requestTypeCreator('UPDATE_REQUESTER_CASE_POPUP_OPTION');

export const REQUESTER_PREPARED_STATUS = requestTypeCreator('REQUESTER_PREPARED_STATUS');
export const REQUEST_CASE_ASSIGN_AGENT = requestTypeCreator('REQUEST_CASE_ASSIGN_AGENT');
export const REQUEST_CASE_CHANGE_STATUS = requestTypeCreator('REQUEST_CASE_CHANGE_STATUS');
export const REQUEST_CASE_CHAT_ACTIVATE = requestTypeCreator('REQUEST_CASE_CHAT_ACTIVATE');
export const REQUEST_CASE_PINNING = requestTypeCreator('REQUEST_CASE_PINNING');
export const REQUEST_CASE_ARCHIVE = requestTypeCreator('REQUEST_CASE_ARCHIVE');
export const REQUEST_CASE_ADD_TAG = requestTypeCreator('REQUEST_CASE_ADD_TAG');
export const REQUEST_CASE_REMOVE_TAG = requestTypeCreator('REQUEST_CASE_REMOVE_TAG');
export const REQUEST_CASE_QUESTION = requestTypeCreator('REQUEST_CASE_QUESTION');

export const REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY = requestTypeCreator('REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY');

export const REQUESTER_CASE_EMAIL_REQUEST = requestTypeCreator('REQUESTER_CASE_EMAIL_REQUEST');

export const REQUEST_CASE_ACTIVITIES = requestTypeCreator('REQUEST_CASE_ACTIVITIES');
export const REQUEST_CASE_ACTIVITIES_EXPORT = requestTypeCreator('REQUEST_CASE_ACTIVITIES_EXPORT');

export const REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE = requestTypeCreator('REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE');

export const CREATE_CASE_WATCHER = requestTypeCreator('CREATE_CASE_WATCHER');
export const DELETE_CASE_WATCHER = requestTypeCreator('DELETE_CASE_WATCHER');

export const UPDATE_STATUS_CHANGE_APPROVE_REQUEST = requestTypeCreator('UPDATE_STATUS_CHANGE_APPROVE_REQUEST');
export const UNSET_STATUS_CHANGE_APPROVE_REQUEST = requestTypeCreator('UNSET_STATUS_CHANGE_APPROVE_REQUEST');
export const DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS = requestTypeCreator(
    'DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS',
);

export const CREATE_ADDITIONAL_FORM_INVITE = requestTypeCreator('CREATE_ADDITIONAL_FORM_INVITE');
export const DELETE_ADDITIONAL_FORM_INVITE = requestTypeCreator('DELETE_ADDITIONAL_FORM_INVITE');

export const CREATE_SUBFORM_REQUEST = requestTypeCreator('CREATE_SUBFORM_REQUEST');
export const SUBMIT_SUBFORM_REQUEST = requestTypeCreator('SUBMIT_SUBFORM_REQUEST');
export const DELETE_SUBFORM_REQUEST = requestTypeCreator('DELETE_SUBFORM_REQUEST');

export const IGNORE_CASE_HAS_TASK = requestTypeCreator('IGNORE_CASE_HAS_TASK');

export const REQUESTER_CASE_SLOTS_LIST = requestTypeCreator('REQUESTER_CASE_SLOTS_LIST');
export const CREATE_REQUESTER_CASE_HAS_SLOT = requestTypeCreator('CREATE_REQUESTER_CASE_HAS_SLOT');
export const DELETE_REQUESTER_CASE_HAS_SLOT = requestTypeCreator('DELETE_REQUESTER_CASE_HAS_SLOT');

export const REQUESTER_CASE_BUDGETS_LIST = requestTypeCreator('REQUESTER_CASE_BUDGETS_LIST');
export const CREATE_REQUESTER_CASE_BUDGET = requestTypeCreator('CREATE_REQUESTER_CASE_BUDGET');
export const UPDATE_REQUESTER_CASE_BUDGET = requestTypeCreator('UPDATE_REQUESTER_CASE_BUDGET');
export const DELETE_REQUESTER_CASE_BUDGET = requestTypeCreator('DELETE_REQUESTER_CASE_BUDGET');

export const REFRESH_CASE_INFORMATION = requestTypeCreator('REFRESH_CASE_INFORMATION');

export const getAllRequesterCases = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_ALL_REQUESTER_CASES.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_ALL_REQUESTER_CASES.REQUEST,
    }),
    requestBackground: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_ALL_REQUESTER_CASES.REQUEST_BACKGROUND,
    }),
    success: (payload: RequesterAllCaseTypes[]): IActionType<RequesterAllCaseTypes[]> => ({
        payload,
        type: GET_ALL_REQUESTER_CASES.SUCCESS,
    }),
};

export const getRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE.REQUEST,
    }),
    requestBackground: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE.REQUEST_BACKGROUND,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: GET_REQUESTER_CASE.SUCCESS,
    }),
};

export const getRequesterCaseDrawerInfo = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE_DRAWER.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_DRAWER.REQUEST,
    }),
    success: (payload: RequesterCaseDrawerTypes): IActionType<RequesterCaseDrawerTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_DRAWER.SUCCESS,
    }),
};

export const getRequesterCaseAllFiles = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE_ALL_FILES.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_ALL_FILES.REQUEST,
    }),
    success: (payload: RequesterCaseFilesTypes): IActionType<RequesterCaseFilesTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_ALL_FILES.SUCCESS,
    }),
};

export const getRequesterCaseChatFiles = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE_CHAT_FILES.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_CHAT_FILES.REQUEST,
    }),
    success: (payload: ChatFileType[]): IActionType<ChatFileType[]> => ({
        payload,
        type: GET_REQUESTER_CASE_CHAT_FILES.SUCCESS,
    }),
};

export const createRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_REQUESTER_CASE.ERROR,
    }),
    request: (payload: RequesterCaseCreateRequestTypes): IActionType<RequesterCaseCreateRequestTypes> => ({
        payload,
        type: CREATE_REQUESTER_CASE.REQUEST,
    }),
    success: (payload: RequesterCaseCreateResponseTypes): IActionType<RequesterCaseCreateResponseTypes> => ({
        payload,
        type: CREATE_REQUESTER_CASE.SUCCESS,
    }),
};

export const updateRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_CASE.ERROR,
    }),
    request: (payload: RequesterCaseUpdatingTypes): IActionType<RequesterCaseUpdatingTypes> => ({
        payload,
        type: UPDATE_REQUESTER_CASE.REQUEST,
    }),
    success: (payload: RequesterCaseUpdateResultsResponse): IActionType<RequesterCaseUpdateResultsResponse> => ({
        payload,
        type: UPDATE_REQUESTER_CASE.SUCCESS,
    }),
};

export const updateRequesterCaseOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_OPTION.ERROR,
    }),
    request: (payload: RequesterCaseOptionUpdatingTypes): IActionType<RequesterCaseOptionUpdatingTypes> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_OPTION.REQUEST,
    }),
    success: () => ({
        type: UPDATE_REQUESTER_CASE_OPTION.SUCCESS,
    }),
};

export const updateCheckedSections = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS.ERROR,
    }),
    request: (payload: RequesterCaseUpdatingTypes): IActionType<RequesterCaseUpdatingTypes> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS.REQUEST,
    }),
    success: (payload: string[]): IActionType<string[]> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS.SUCCESS,
    }),
};

export const updateCheckedSectionsForStack = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS_FOR_STACK.ERROR,
    }),
    request: (payload: RequesterCaseUpdatingTypes): IActionType<RequesterCaseUpdatingTypes> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS_FOR_STACK.REQUEST,
    }),
    success: (payload: string[]): IActionType<string[]> => ({
        payload,
        type: UPDATE_CHECKED_SECTIONS_FOR_STACK.SUCCESS,
    }),
};

export const uploadRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPLOAD_REQUESTER_DOCUMENT.ERROR,
    }),
    request: (payload: UploadRequesterCaseTypes): IActionType<UploadRequesterCaseTypes> => ({
        payload,
        type: UPLOAD_REQUESTER_DOCUMENT.REQUEST,
    }),
    success: (payload: UploadRequesterCaseResponseTypes): IActionType<UploadRequesterCaseResponseTypes> => ({
        payload,
        type: UPLOAD_REQUESTER_DOCUMENT.SUCCESS,
    }),
};

export const uploadRequesterSignature = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPLOAD_REQUESTER_SIGNATURE.ERROR,
    }),
    request: (payload: UploadRequesterSignatureTypes): IActionType<UploadRequesterSignatureTypes> => ({
        payload,
        type: UPLOAD_REQUESTER_SIGNATURE.REQUEST,
    }),
    success: (payload: UploadRequesterSignatureResponseTypes): IActionType<UploadRequesterSignatureResponseTypes> => ({
        payload,
        type: UPLOAD_REQUESTER_SIGNATURE.SUCCESS,
    }),
};

export const deleteRequesterSignature = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_REQUESTER_SIGNATURE.ERROR,
    }),
    request: (payload: DeleteRequesterDocumentTypes): IActionType<DeleteRequesterDocumentTypes> => ({
        payload,
        type: DELETE_REQUESTER_SIGNATURE.REQUEST,
    }),
    success: (payload: UploadRequesterSignatureResponseTypes): IActionType<UploadRequesterSignatureResponseTypes> => ({
        payload,
        type: DELETE_REQUESTER_SIGNATURE.SUCCESS,
    }),
};

export const getRequesterCasePopups = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_REQUESTER_CASE_POPUPS.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: GET_REQUESTER_CASE_POPUPS.REQUEST,
    }),
    success: (payload: RequesterCasePopupType[]): IActionType<RequesterCasePopupType[]> => ({
        payload,
        type: GET_REQUESTER_CASE_POPUPS.SUCCESS,
    }),
};

export const createRequesterPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_REQUESTER_POPUP.ERROR,
    }),
    request: (payload: RequesterCasePopupCreatingTypes): IActionType<RequesterCasePopupCreatingTypes> => ({
        payload,
        type: CREATE_REQUESTER_POPUP.REQUEST,
    }),
    success: (payload: RequesterCasePopupType[]): IActionType<RequesterCasePopupType[]> => ({
        payload,
        type: CREATE_REQUESTER_POPUP.SUCCESS,
    }),
};

export const updateRequesterPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_POPUP.ERROR,
    }),
    request: (payload: RequesterCasePopupUpdatingTypes): IActionType<RequesterCasePopupUpdatingTypes> => ({
        payload,
        type: UPDATE_REQUESTER_POPUP.REQUEST,
    }),
    success: (payload: RequesterCasePopupType[]): IActionType<RequesterCasePopupType[]> => ({
        payload,
        type: UPDATE_REQUESTER_POPUP.SUCCESS,
    }),
};

export const deleteRequesterPopup = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_REQUESTER_POPUP.ERROR,
    }),
    request: (payload: RequesterCasePopupDeletingTypes): IActionType<RequesterCasePopupDeletingTypes> => ({
        payload,
        type: DELETE_REQUESTER_POPUP.REQUEST,
    }),
    success: (payload: RequesterCasePopupType[]): IActionType<RequesterCasePopupType[]> => ({
        payload,
        type: DELETE_REQUESTER_POPUP.SUCCESS,
    }),
};

export const updateRequesterCasePopupOption = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_POPUP_OPTION.ERROR,
    }),
    request: (payload: RequesterCasePopupOptionUpdatingTypes): IActionType<RequesterCasePopupOptionUpdatingTypes> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST,
    }),
    success: () => ({
        type: UPDATE_REQUESTER_CASE_POPUP_OPTION.SUCCESS,
    }),
};

export const deleteRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_REQUESTER_DOCUMENT.ERROR,
    }),
    request: (payload: DeleteRequesterDocumentTypes): IActionType<DeleteRequesterDocumentTypes> => ({
        payload,
        type: DELETE_REQUESTER_DOCUMENT.REQUEST,
    }),
    success: (payload: UploadRequesterCaseResponseTypes): IActionType<UploadRequesterCaseResponseTypes> => ({
        payload,
        type: DELETE_REQUESTER_DOCUMENT.SUCCESS,
    }),
};

export const editRequesterCase = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: EDIT_REQUESTER_DOCUMENT.ERROR,
    }),
    request: (payload: EditRequesterDocumentTypes): IActionType<EditRequesterDocumentTypes> => ({
        payload,
        type: EDIT_REQUESTER_DOCUMENT.REQUEST,
    }),
    success: (payload: UploadRequesterCaseResponseTypes): IActionType<UploadRequesterCaseResponseTypes> => ({
        payload,
        type: EDIT_REQUESTER_DOCUMENT.SUCCESS,
    }),
};

export const requesterPreparedStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUESTER_PREPARED_STATUS.ERROR,
    }),
    request: (payload: RequesterPreparedStatusType): IActionType<RequesterPreparedStatusType> => ({
        payload,
        type: REQUESTER_PREPARED_STATUS.REQUEST,
    }),
    success: (payload: UploadRequesterCaseResponseTypes): IActionType<UploadRequesterCaseResponseTypes> => ({
        payload,
        type: REQUESTER_PREPARED_STATUS.SUCCESS,
    }),
};

export const requestCaseAssignAgent = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT.ERROR,
    }),
    request: (payload: RequestCaseAssignAgentType): IActionType<RequestCaseAssignAgentType> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT.REQUEST,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: REQUEST_CASE_ASSIGN_AGENT.SUCCESS,
    }),
};

export const requestCaseChangeStatus = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS.ERROR,
    }),
    request: (payload: RequestCaseChangeStatusType): IActionType<RequestCaseChangeStatusType> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS.REQUEST,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: REQUEST_CASE_CHANGE_STATUS.SUCCESS,
    }),
};

export const requestCaseChatActivate = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_CHAT_ACTIVATE.ERROR,
    }),
    request: (payload: RequestCaseChatActivateRequestTypes): IActionType<RequestCaseChatActivateRequestTypes> => ({
        payload,
        type: REQUEST_CASE_CHAT_ACTIVATE.REQUEST,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: REQUEST_CASE_CHAT_ACTIVATE.SUCCESS,
    }),
};

export const requestCasePinning = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_PINNING.ERROR,
    }),
    request: (payload: RequestCaseChatActivateRequestTypes): IActionType<RequestCaseChatActivateRequestTypes> => ({
        payload,
        type: REQUEST_CASE_PINNING.REQUEST,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: REQUEST_CASE_PINNING.SUCCESS,
    }),
};

export const requestCaseQuestion = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_QUESTION.ERROR,
    }),
    request: (payload: RequestCaseQuestionRequestTypes): IActionType<RequestCaseQuestionRequestTypes> => ({
        payload,
        type: REQUEST_CASE_QUESTION.REQUEST,
    }),
    success: () => ({
        type: REQUEST_CASE_QUESTION.SUCCESS,
    }),
};

export const toggleRequesterCaseRemovingPossibility = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.ERROR,
    }),
    request: (
        payload: RequesterCaseToggleRemovingPossibilityRequestTypes,
    ): IActionType<RequesterCaseToggleRemovingPossibilityRequestTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.REQUEST,
    }),
    success: (payload: RequesterAllCaseItemTypes): IActionType<RequesterAllCaseItemTypes> => ({
        payload,
        type: REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.SUCCESS,
    }),
};

export const requesterArchiveDownload = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ARCHIVE.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: REQUEST_CASE_ARCHIVE.REQUEST,
    }),
    success: (payload: Blob | string): IActionType<Blob | string> => ({
        payload,
        type: REQUEST_CASE_ARCHIVE.SUCCESS,
    }),
};

export const addRequesterTag = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.ERROR,
    }),
    request: (payload: RequesterCaseAddTagRequestTypes): IActionType<RequesterCaseAddTagRequestTypes> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.REQUEST,
    }),
    success: (payload: RequesterCaseTagTypes[]): IActionType<RequesterCaseTagTypes[]> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.SUCCESS,
    }),
};

export const deleteRequesterTag = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.ERROR,
    }),
    request: (payload: RequesterCaseRemoveTagRequestTypes): IActionType<RequesterCaseRemoveTagRequestTypes> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.REQUEST,
    }),
    success: (payload: RequesterCaseTagTypes[]): IActionType<RequesterCaseTagTypes[]> => ({
        payload,
        type: REQUEST_CASE_ADD_TAG.SUCCESS,
    }),
};

export const getRequestCaseActivities = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES.ERROR,
    }),
    request: (payload: RequesterCaseActivitiesRequestTypes): IActionType<RequesterCaseActivitiesRequestTypes> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES.REQUEST,
    }),
    success: (payload: RequesterCaseActivitiesTypes[]): IActionType<RequesterCaseActivitiesTypes[]> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES.SUCCESS,
    }),
};

export const exportRequestCaseActivities = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES_EXPORT.ERROR,
    }),
    request: (
        payload: RequesterCaseActivitiesExportRequestTypes,
    ): IActionType<RequesterCaseActivitiesExportRequestTypes> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES_EXPORT.REQUEST,
    }),
    success: (payload: Blob | string): IActionType<Blob | string> => ({
        payload,
        type: REQUEST_CASE_ACTIVITIES_EXPORT.SUCCESS,
    }),
};

export const getRequestCaseAttachmentsForArchive = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.ERROR,
    }),
    request: (payload: RequesterCaseGettingTypes): IActionType<RequesterCaseGettingTypes> => ({
        payload,
        type: REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST,
    }),
    success: (
        payload: RequesterCaseAttachmentsForArchiveTypes[],
    ): IActionType<RequesterCaseAttachmentsForArchiveTypes[]> => ({
        payload,
        type: REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.SUCCESS,
    }),
};

export const createCaseWatcher = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_CASE_WATCHER.ERROR,
    }),
    request: (payload: CaseWatcherCreatingTypes): IActionType<CaseWatcherCreatingTypes> => ({
        payload,
        type: CREATE_CASE_WATCHER.REQUEST,
    }),
    success: (payload: CaseWatcherTypes[]): IActionType<CaseWatcherTypes[]> => ({
        payload,
        type: CREATE_CASE_WATCHER.SUCCESS,
    }),
};

export const deleteCaseWatcher = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_CASE_WATCHER.ERROR,
    }),
    request: (payload: CaseWatcherRemovingTypes): IActionType<CaseWatcherRemovingTypes> => ({
        payload,
        type: DELETE_CASE_WATCHER.REQUEST,
    }),
    success: (payload: CaseWatcherTypes[]): IActionType<CaseWatcherTypes[]> => ({
        payload,
        type: DELETE_CASE_WATCHER.SUCCESS,
    }),
};

export const requesterCaseEmailRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUESTER_CASE_EMAIL_REQUEST.ERROR,
    }),
    request: (payload: RequesterCaseEmailRequestTypes): IActionType<RequesterCaseEmailRequestTypes> => ({
        payload,
        type: REQUESTER_CASE_EMAIL_REQUEST.REQUEST,
    }),
    success: () => ({
        type: REQUESTER_CASE_EMAIL_REQUEST.SUCCESS,
    }),
};

export const updateStatusChangeApproveRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_STATUS_CHANGE_APPROVE_REQUEST.ERROR,
    }),
    request: (payload: StatusChangeApproveUpdateRequestType): IActionType<StatusChangeApproveUpdateRequestType> => ({
        payload,
        type: UPDATE_STATUS_CHANGE_APPROVE_REQUEST.REQUEST,
    }),
    success: (payload: RequesterCaseTypes): IActionType<RequesterCaseTypes> => ({
        payload,
        type: UPDATE_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS,
    }),
};

export const unsetStatusChangeApproveRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UNSET_STATUS_CHANGE_APPROVE_REQUEST.ERROR,
    }),
    request: (payload: UnsetStatusChangeApproveRequestType): IActionType<UnsetStatusChangeApproveRequestType> => ({
        payload,
        type: UNSET_STATUS_CHANGE_APPROVE_REQUEST.REQUEST,
    }),
    success: (payload: RequesterCaseApproveTypes[]): IActionType<RequesterCaseApproveTypes[]> => ({
        payload,
        type: UNSET_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS,
    }),
};

export const deleteAllStatusChangeApproveRequests = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.ERROR,
    }),
    request: (
        payload: DeleteAllStatusChangeApproveRequestType,
    ): IActionType<DeleteAllStatusChangeApproveRequestType> => ({
        payload,
        type: DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.REQUEST,
    }),
    success: (payload: RequesterCaseApproveTypes[]): IActionType<RequesterCaseApproveTypes[]> => ({
        payload,
        type: DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.SUCCESS,
    }),
};

export const createAdditionalFormInvite = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_ADDITIONAL_FORM_INVITE.ERROR,
    }),
    request: (
        payload: InviteAdditionalFormCreateRequestTypes,
    ): IActionType<InviteAdditionalFormCreateRequestTypes> => ({
        payload,
        type: CREATE_ADDITIONAL_FORM_INVITE.REQUEST,
    }),
    success: (payload: InviteAdditionalFormTypes[]): IActionType<InviteAdditionalFormTypes[]> => ({
        payload,
        type: CREATE_ADDITIONAL_FORM_INVITE.SUCCESS,
    }),
};

export const deleteAdditionalFormInvite = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_ADDITIONAL_FORM_INVITE.ERROR,
    }),
    request: (
        payload: InviteAdditionalFormDeleteRequestTypes,
    ): IActionType<InviteAdditionalFormDeleteRequestTypes> => ({
        payload,
        type: DELETE_ADDITIONAL_FORM_INVITE.REQUEST,
    }),
    success: (payload: InviteAdditionalFormTypes[]): IActionType<InviteAdditionalFormTypes[]> => ({
        payload,
        type: DELETE_ADDITIONAL_FORM_INVITE.SUCCESS,
    }),
};

export const createSubFormRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_SUBFORM_REQUEST.ERROR,
    }),
    request: (payload: CreateSubFormRequestFormTypes): IActionType<CreateSubFormRequestFormTypes> => ({
        payload,
        type: CREATE_SUBFORM_REQUEST.REQUEST,
    }),
    success: (payload: SubFormRequestTypes[]): IActionType<SubFormRequestTypes[]> => ({
        payload,
        type: CREATE_SUBFORM_REQUEST.SUCCESS,
    }),
};

export const submitSubFormRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SUBMIT_SUBFORM_REQUEST.ERROR,
    }),
    request: (payload: SubFormRequestSubmittingRequestTypes): IActionType<SubFormRequestSubmittingRequestTypes> => ({
        payload,
        type: SUBMIT_SUBFORM_REQUEST.REQUEST,
    }),
    success: (payload: SubFormRequestTypes[]): IActionType<SubFormRequestTypes[]> => ({
        payload,
        type: SUBMIT_SUBFORM_REQUEST.SUCCESS,
    }),
};

export const deleteSubFormRequest = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_SUBFORM_REQUEST.ERROR,
    }),
    request: (payload: SubFormRequestDeleteRequestTypes): IActionType<SubFormRequestDeleteRequestTypes> => ({
        payload,
        type: DELETE_SUBFORM_REQUEST.REQUEST,
    }),
    success: (payload: SubFormRequestTypes[]): IActionType<SubFormRequestTypes[]> => ({
        payload,
        type: DELETE_SUBFORM_REQUEST.SUCCESS,
    }),
};

export const ignoreCaseHasTask = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: IGNORE_CASE_HAS_TASK.ERROR,
    }),
    request: (payload: CaseHasTaskIgnoringTypes): IActionType<CaseHasTaskIgnoringTypes> => ({
        payload,
        type: IGNORE_CASE_HAS_TASK.REQUEST,
    }),
    success: (payload: CaseHasTasksTypes[]): IActionType<CaseHasTasksTypes[]> => ({
        payload,
        type: IGNORE_CASE_HAS_TASK.SUCCESS,
    }),
};

export const getRequesterCaseSlotsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUESTER_CASE_SLOTS_LIST.ERROR,
    }),
    request: (payload: RequesterCaseSlotsListRequestTypes): IActionType<RequesterCaseSlotsListRequestTypes> => ({
        payload,
        type: REQUESTER_CASE_SLOTS_LIST.REQUEST,
    }),
    success: (payload: RequesterCaseHasSlotTypes[]): IActionType<RequesterCaseHasSlotTypes[]> => ({
        payload,
        type: REQUESTER_CASE_SLOTS_LIST.SUCCESS,
    }),
};

export const createRequesterCaseHasSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_REQUESTER_CASE_HAS_SLOT.ERROR,
    }),
    request: (
        payload: RequesterCaseHasSlotCreationRequestTypes,
    ): IActionType<RequesterCaseHasSlotCreationRequestTypes> => ({
        payload,
        type: CREATE_REQUESTER_CASE_HAS_SLOT.REQUEST,
    }),
    success: (payload: RequesterCaseHasSlotTypes[]): IActionType<RequesterCaseHasSlotTypes[]> => ({
        payload,
        type: CREATE_REQUESTER_CASE_HAS_SLOT.SUCCESS,
    }),
};

export const deleteRequesterCaseHasSlot = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_REQUESTER_CASE_HAS_SLOT.ERROR,
    }),
    request: (
        payload: RequesterCaseHasSlotDeletingRequestTypes,
    ): IActionType<RequesterCaseHasSlotDeletingRequestTypes> => ({
        payload,
        type: DELETE_REQUESTER_CASE_HAS_SLOT.REQUEST,
    }),
    success: (payload: RequesterCaseHasSlotTypes[]): IActionType<RequesterCaseHasSlotTypes[]> => ({
        payload,
        type: DELETE_REQUESTER_CASE_HAS_SLOT.SUCCESS,
    }),
};

export const getRequesterCaseBudgetsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: REQUESTER_CASE_BUDGETS_LIST.ERROR,
    }),
    request: (payload: RequesterCaseBudgetsListRequestTypes): IActionType<RequesterCaseBudgetsListRequestTypes> => ({
        payload,
        type: REQUESTER_CASE_BUDGETS_LIST.REQUEST,
    }),
    success: (payload: RequesterCaseHasBudgetTypes[]): IActionType<RequesterCaseHasBudgetTypes[]> => ({
        payload,
        type: REQUESTER_CASE_BUDGETS_LIST.SUCCESS,
    }),
};

export const createRequesterCaseBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_REQUESTER_CASE_BUDGET.ERROR,
    }),
    request: (payload: RequesterCaseBudgetCreateRequestTypes): IActionType<RequesterCaseBudgetCreateRequestTypes> => ({
        payload,
        type: CREATE_REQUESTER_CASE_BUDGET.REQUEST,
    }),
    success: (payload: RequesterCaseHasBudgetTypes[]): IActionType<RequesterCaseHasBudgetTypes[]> => ({
        payload,
        type: CREATE_REQUESTER_CASE_BUDGET.SUCCESS,
    }),
};

export const updateRequesterCaseBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_BUDGET.ERROR,
    }),
    request: (payload: RequesterCaseBudgetUpdateRequestTypes): IActionType<RequesterCaseBudgetUpdateRequestTypes> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_BUDGET.REQUEST,
    }),
    success: (payload: RequesterCaseHasBudgetTypes[]): IActionType<RequesterCaseHasBudgetTypes[]> => ({
        payload,
        type: UPDATE_REQUESTER_CASE_BUDGET.SUCCESS,
    }),
};

export const deleteRequesterCaseBudget = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_REQUESTER_CASE_BUDGET.ERROR,
    }),
    request: (payload: RequesterCaseBudgetDeleteRequestTypes): IActionType<RequesterCaseBudgetDeleteRequestTypes> => ({
        payload,
        type: DELETE_REQUESTER_CASE_BUDGET.REQUEST,
    }),
    success: (payload: RequesterCaseHasBudgetTypes[]): IActionType<RequesterCaseHasBudgetTypes[]> => ({
        payload,
        type: DELETE_REQUESTER_CASE_BUDGET.SUCCESS,
    }),
};

export const refreshCaseInformation = {
    request: () => ({
        type: REFRESH_CASE_INFORMATION.REQUEST,
    }),
};
