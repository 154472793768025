import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';

import {CREATE_REQUESTER_POPUP, DELETE_REQUESTER_POPUP} from 'appRedux/actions/requestCase';
import {FormPopupTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

import PopupContent from 'pages/client/form/fieldPopup/PopupContent';
import PopupRowForm from 'pages/client/form/fieldPopup/PopupRowForm';
import FormPopupWrapper from 'pages/client/form/wrappers/FormPopupWrapper';

import {ADMIN_LIGHT_GRAY_COLOR, theme} from 'config/theme';

interface FormFieldWrapperType {
    formId: number;
    pageId: number;
    sectionId: number;
    disabled: boolean;
    popup: FormPopupTypes;
    clientInformation: unknown;
    onSaveClicked: (isCallAlert?: boolean) => void;
    previewMode?: boolean;
}

const FormPopup: FC<FormFieldWrapperType> = ({
    formId,
    pageId,
    sectionId,
    popup,
    clientInformation,
    disabled,
    onSaveClicked,
    previewMode,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);

    const ref = useRef<HTMLDivElement | undefined>();

    const {title, id, fields} = popup;

    const {
        requestCase: {
            currentCase: {popupItems},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentPopupItems = useMemo(
        () => (popupItems ? popupItems.filter(item => item.popupId === id) : []),
        [popupItems, id],
    );
    const submittedPopupItems = useMemo(() => currentPopupItems.filter(item => item.isSubmitted), [popupItems]);

    const newPopupItem = useMemo(() => currentPopupItems.find(item => !item.isSubmitted), [popupItems, id]);
    const newPopupItemIsEdited = useMemo(
        () => newPopupItem?.options.length || newPopupItem?.signatures.length || newPopupItem?.result,
        [newPopupItem],
    );

    const [itemIndex, setItemIndex] = useState<number | null>(null);
    const [isShowNewContent, setIsShowNewContent] = useState<boolean>(false);

    const createRequesterPopup = useCallback(
        data => dispatch({type: CREATE_REQUESTER_POPUP.REQUEST, payload: data}),
        [dispatch],
    );

    const deleteRequesterPopup = useCallback(
        data => dispatch({type: DELETE_REQUESTER_POPUP.REQUEST, payload: data}),
        [dispatch],
    );

    const toggleModal = () => {
        setIsShowNewContent(previous => !previous);
    };

    const onAddFieldPopupClick = async () => {
        await new Promise(resolve => {
            if (!newPopupItem) return resolve('');
            deleteRequesterPopup({
                uuid: newPopupItem.uuid,
                callback: () => {
                    resolve('');
                },
            });
        });

        createRequesterPopup({
            id: Number(requestCase),
            popup: id,
            result: null,
            isSubmitted: false,
            showAlert,
            callback: () => {
                toggleModal();
                setItemIndex(null);
            },
        });
    };

    const onOpenNewFieldPopupClick = () => {
        if (newPopupItem) {
            toggleModal();
            setItemIndex(null);
        }
    };

    const onEditClick = (index: number | null) => {
        setIsShowNewContent(false);
        setItemIndex(index);
    };

    if (fields.length === 0) {
        return null;
    }
    return (
        <FormPopupWrapper wrapperRef={ref} popup={popup} formId={formId} previewMode={previewMode}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
                {submittedPopupItems.length > 0 ? (
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >
                        {`${submittedPopupItems.length} ${t('requester.casePage.entries')}`}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            textTransform: 'none',
                            color: theme.palette.info.main,
                            pr: 1,
                        }}
                    >
                        {t('common.none')}
                    </Typography>
                )}
            </Box>
            <Box
                sx={{
                    border: `1px solid ${theme.palette.info.contrastText}`,
                    borderRadius: '10px',
                    overflow: 'hidden',
                    mb: 1,
                }}
            >
                <PopupContent
                    popup={popup}
                    pageId={pageId}
                    formId={formId}
                    sectionId={sectionId}
                    initialItems={submittedPopupItems}
                    onEditClick={onEditClick}
                    disabled={disabled}
                    onSaveClicked={onSaveClicked}
                    selectedIndex={itemIndex}
                    clientInformation={clientInformation}
                />

                {!disabled && (
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: isShowNewContent ? 'space-between' : 'center',
                            alignItems: 'center',
                            height: '48px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: isShowNewContent ? 'default' : 'pointer',
                                gap: 1,
                                py: 1,
                                px: 2,
                            }}
                            onClick={!isShowNewContent ? onAddFieldPopupClick : undefined}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.info.main,
                                    fontWeight: 600,
                                }}
                            >
                                {isShowNewContent ? t('requester.casePage.newEntry') : t('requester.casePage.addEntry')}
                            </Typography>

                            {!isShowNewContent && (
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.info.main,
                                        fontWeight: 600,
                                        height: 24,
                                        width: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AddIcon sx={{color: theme.palette.background.default}} />
                                </Box>
                            )}
                        </Box>
                        {!isShowNewContent && newPopupItemIsEdited && (
                            <AgentInverseButton
                                data-id={`input#form-popup-${formId}-${pageId}-${sectionId}-${popup.id}-restore`}
                                onClick={onOpenNewFieldPopupClick}
                                title={t('requester.casePage.restoreNewEntry')}
                                sx={{position: 'absolute', right: 4, top: 8}}
                            />
                        )}
                        {isShowNewContent && (
                            <IconButton sx={{p: 0.5, mr: 2}} onClick={toggleModal}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Box>
                )}

                {isShowNewContent && itemIndex === null && (
                    <Box
                        sx={{
                            height: isShowNewContent ? 'auto' : '0px',
                            py: isShowNewContent ? 1 : 0,
                            mx: 1,
                            px: 1,
                            borderTop: `1px solid ${ADMIN_LIGHT_GRAY_COLOR}`,
                        }}
                    >
                        {isShowNewContent && newPopupItem && (
                            <PopupRowForm
                                toggleModal={toggleModal}
                                initialItem={newPopupItem}
                                formId={formId}
                                pageId={pageId}
                                sectionId={sectionId}
                                popup={popup}
                                clientInformation={clientInformation}
                                disabled={disabled}
                                onSaveClicked={onSaveClicked}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </FormPopupWrapper>
    );
};

export default FormPopup;
