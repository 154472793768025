import React, {FC, useContext} from 'react';

import {PreparedTemplatePdfAttachmentTypes} from 'appRedux/actions/organization/types';
import {View, Text, Page} from '@react-pdf/renderer';
import {useTranslation} from 'react-i18next';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';

import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';
import {AttachmentComponent} from 'pages/agent/pdfExport/pdfPartials/ImagePdfComponent';

interface TemplateAttachmentPagesType {
    templateAttachments: PreparedTemplatePdfAttachmentTypes[];
}

const TemplateAttachmentPages: FC<TemplateAttachmentPagesType> = ({templateAttachments}) => {
    const [t] = useTranslation();

    const {preview} = useContext(PdfDocumentContext);

    return (
        <>
            {templateAttachments.map(file => {
                const {fileData, title, uuid} = file;
                const isImg = typeof fileData === 'string';

                if (isImg)
                    return (
                        <Page size="A4" style={pdfStyles.attachmentsPage} key={`file-${uuid}`}>
                            <View fixed style={pdfStyles.attachmentHeader}>
                                <Text>{`${file.title || t('orguser.requesterCase.attachments')}:`}</Text>
                            </View>
                            <AttachmentComponent src={fileData} preview={preview} />
                        </Page>
                    );

                return fileData.map((image, i) => (
                    <Page size="A4" style={pdfStyles.attachmentsPage} key={`file-${uuid}-${i}`}>
                        <View fixed style={pdfStyles.template}>
                            <AttachmentComponent src={image} preview={preview} isPdf={true} />
                        </View>
                        <View fixed style={pdfStyles.attachmentHeader}>
                            <Text>{`${title || t('orguser.requesterCase.attachments')}:`}</Text>
                        </View>
                    </Page>
                ));
            })}
        </>
    );
};

export default TemplateAttachmentPages;
