import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';

import {RootReducer} from 'appRedux/reducers';
import {REQUEST_CASE_CREATE_STACK} from 'appRedux/actions/admin';

import {AdminContext} from 'contexts/admin/context';
import {RouteContext} from 'contexts/route/context';
import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {
    PARAMETER_TAG,
    DEFAULT_PAGE,
    PARAMETER_FORM,
    PARAMETER_SEARCH,
    ITEMS_PER_PAGE_DESKTOP,
    PARAMETER_AGENT,
} from 'config/index';

const CreateStackButton: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {uuid} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {selectedIds, setSelectedIds, setSelectAllFiltered, setIsSelectClicked} = useContext(AdminContext);
    const {isSwimlaneCasesPage, isBoardCasesPage} = useContext(RouteContext);

    const {
        admin: {isLoading, requesterCasesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedCases = requesterCasesList?.filter(item => item.caseId && selectedIds.includes(item.caseId)) || [];
    const selectedForms = [...new Set(selectedCases.map(item => item.formId))];
    const selectedStatuses = [...new Set(selectedCases.map(item => item.status))];

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);

    const values = {
        board: uuid,
        workflowStatusId: selectedCases[0].statusUuid,
        ids: selectedIds.join(','),
        page: DEFAULT_PAGE,
        perPage: ITEMS_PER_PAGE_DESKTOP,
        agent: getParameterFromUrl(agentFromUrl),
        form: getParameterFromUrl(formFromUrl),
        tags: getParameterFromUrl(tagsFromUrl),
        search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        isBoardCasesPage,
        isSwimlaneCasesPage,
    };

    const createRequesterCaseStack = useCallback(
        data => dispatch({type: REQUEST_CASE_CREATE_STACK.REQUEST, payload: data}),
        [dispatch],
    );

    const onCreateStackClicked = () => {
        createRequesterCaseStack({
            ...values,
            callback: () => {
                setSelectAllFiltered(false);
                setSelectedIds([]);
                setIsSelectClicked(false);
            },
            showAlert,
        });
    };

    console.log({selectedCases, isSwimlaneCasesPage, isBoardCasesPage, selectedForms});

    if (
        selectedCases.length < 2 ||
        !(isSwimlaneCasesPage || isBoardCasesPage) ||
        selectedForms.length > 1 ||
        selectedStatuses.length > 1
    )
        return null;

    return (
        <>
            <AgentSaveButton
                isLoading={isLoading}
                isSubmit={false}
                title={t('orguser.requesterCase.stackCases')}
                onClick={onCreateStackClicked}
                isBigButton
            />
        </>
    );
};

export default CreateStackButton;
