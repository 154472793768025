import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import {CREATE_REQUESTER_CASE_HAS_SLOT, DELETE_REQUESTER_CASE_HAS_SLOT} from 'appRedux/actions/requestCase';
import {ResourceFieldSlotClientItemTypes} from 'appRedux/actions/resourceFields/types';
import {RequesterCaseHasSlotTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {STATUS_OPENING} from 'config/index';

interface SlotSelectorItemType {
    sectionId: number;
    currentFieldSlot?: RequesterCaseHasSlotTypes;
    item: ResourceFieldSlotClientItemTypes;
}

const SlotSelectorItem: FC<SlotSelectorItemType> = ({item, sectionId, currentFieldSlot}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);
    const {toggleSectionChecked} = useContext(ClientFormContext);

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [, setIsUpdated] = useState<boolean>(false);

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {title, uuid, id, description, availableQty} = item;

    const selectSlot = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_HAS_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const unselectSlot = useCallback(
        data => dispatch({type: DELETE_REQUESTER_CASE_HAS_SLOT.REQUEST, payload: data}),
        [dispatch],
    );

    const toggleSelector = () => {
        setIsChecked(previous => !previous);
        setIsUpdated(previous => !previous);
    };

    const onClick = () => {
        if (isClientMode) {
            if (currentFieldSlot) {
                unselectSlot({
                    sectionId,
                    uuid: currentFieldSlot.uuid,
                    showAlert,
                    toggleSectionChecked,
                    callback: toggleSelector,
                });
            } else if (availableQty > 0) {
                selectSlot({
                    id: requestCase,
                    sectionId,
                    slot: id,
                    showAlert,
                    toggleSectionChecked,
                    callback: toggleSelector,
                });
            }
        } else {
            toggleSelector();
        }
    };

    useEffect(() => {
        setIsChecked(Boolean(currentFieldSlot));
        setIsUpdated(previous => !previous);
    }, [currentFieldSlot]);

    return (
        <Box>
            <FormControlLabel
                key={`checkbox-${uuid}`}
                disabled={(!currentFieldSlot && availableQty === 0) || status !== STATUS_OPENING}
                control={<Checkbox checked={isChecked} value={id} onClick={onClick} />}
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{fontWeight: 700, mr: 1}}>{title}</Typography>
                        {description && <DescriptionPopover text={description} />}
                    </Box>
                }
            />
        </Box>
    );
};

export default SlotSelectorItem;
