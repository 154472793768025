import React, {FC, useCallback, useContext} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

import {CREATE_CLIENT_TRANSLATION_NOTIFICATION} from 'appRedux/actions/clientTranslation';
import {ClientTranslationNotificationCreateFormTypes} from 'appRedux/actions/clientTranslation/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import {validation} from 'components/Forms/CreateRequestTranslationForm/validation';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {preventSendForm} from 'helpers/index';

interface CreateRequestTranslationFormType {
    openModal: boolean;
    closeModal: () => void;
    caseId: number;
    sectionId: number;
}

const CreateRequestTranslationForm: FC<CreateRequestTranslationFormType> = ({
    caseId,
    sectionId,
    closeModal,
    openModal,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {agentList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createTranslationNotification = useCallback(
        data => dispatch({type: CREATE_CLIENT_TRANSLATION_NOTIFICATION.REQUEST, payload: data}),
        [dispatch],
    );

    const createTranslationRequest = (values: ClientTranslationNotificationCreateFormTypes) => {
        createTranslationNotification({
            ...values,
            id: caseId,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <ModalWrapper
            title={t('orguser.requesterCase.translation.requestTranslation')}
            isShowModal={openModal}
            toggleModal={closeModal}
        >
            <Formik
                initialValues={{
                    user: 0,
                    section: sectionId,
                }}
                validationSchema={validation}
                onSubmit={createTranslationRequest}
            >
                {(formik: FormikProps<ClientTranslationNotificationCreateFormTypes>) => {
                    return (
                        <Form onKeyDown={preventSendForm}>
                            <FormControl required fullWidth>
                                <Select
                                    fullWidth
                                    name="user"
                                    variant="standard"
                                    IconComponent={KeyboardArrowDownIcon}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& div': {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        },
                                    }}
                                >
                                    {agentList?.map(agent => {
                                        const {id, avatarType, avatarLink, firstName, lastName, email} = agent;
                                        const avatarTitle = getAvatarTitle(firstName, lastName, email);
                                        return (
                                            <MenuItem
                                                key={email}
                                                value={id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    mb: 1,
                                                }}
                                            >
                                                <AvatarImage
                                                    size={25}
                                                    avatarType={avatarType}
                                                    avatarLink={avatarLink}
                                                    avatarTitle={avatarTitle}
                                                />
                                                <Typography sx={{ml: 1, fontWeight: 600}}>
                                                    {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </ModalWrapper>
    );
};

export default CreateRequestTranslationForm;
