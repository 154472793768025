import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FORMS_LIST_FOR_REQUESTER} from 'appRedux/actions/forms';
import {FormForRequesterItemBasic} from 'appRedux/actions/forms/types';
import {GET_ALL_REQUESTER_CASES} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import EmptyArea from 'components/EmptyArea/EmptyArea';

import LanguageDropdownSwitcher from 'pages/client/formsList/LanguageDropdownSwitcher';
import FormListItem from 'pages/client/formsList/FormListItem';
import Search from 'pages/client/formsList/Search';

const ClientFormsListForRequesterPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isMobile} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);

    const [searchValue, setSearchValue] = useState<string>('');

    const {
        admin: {isLoading, formsListForRequester},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getAllRequesterCases = useCallback(
        data => dispatch({type: GET_ALL_REQUESTER_CASES.REQUEST, payload: data}),
        [dispatch],
    );

    const getFormsListForRequester = useCallback(
        data => dispatch({type: FORMS_LIST_FOR_REQUESTER.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (profile) {
            getAllRequesterCases({
                id: profile.id,
            });
            getFormsListForRequester({
                showAlert,
            });
        }
    }, [profile]);

    const filteredFormsList = searchValue
        ? formsListForRequester.filter(
              item =>
                  item.title.toLowerCase().includes(searchValue) ||
                  (item.description && item.description.toLowerCase().includes(searchValue)),
          )
        : formsListForRequester;

    return (
        <ClientScreenWrapper>
            <Typography
                align="center"
                sx={{
                    fontSize: isMobile ? 24 : 36,
                    fontWeight: 600,
                    mt: isMobile ? 1 : 3,
                    mb: 3,
                }}
            >
                {t(`common.menu.forms`)}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Search searchValue={searchValue} setSearchValue={setSearchValue} />
                <EmptyArea />
                <LanguageDropdownSwitcher />
            </Box>
            {filteredFormsList.length > 0
                ? filteredFormsList.map((item: FormForRequesterItemBasic) => {
                      return <FormListItem key={`formlist-${item.id}`} item={item} />;
                  })
                : isLoading
                ? null
                : t('common.none')}
        </ClientScreenWrapper>
    );
};

export default ClientFormsListForRequesterPage;
