import get from 'lodash/get';

import {AgentItemBasic, RequesterCaseResponseTypes} from 'appRedux/actions/admin/types';
import {
    FormFieldFilterType,
    FormTagType,
    FormItemBasic,
    FormVersionTypes,
    FormTranslationItemTypes,
    FieldOptionType,
} from 'appRedux/actions/forms/types';

import {FilterOptionType} from 'components/AdminScreenComponents/types';
import {getParameterFromUrl} from 'components/AdminScreenComponents/translationsHelper';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {getOptionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {
    DEFAULT_FILTER_OPTION,
    DEFAULT_NONE,
    STATUS_DECLINED,
    STATUS_FORWARDED,
    STATUS_HELPED,
    STATUS_DELETED,
} from 'config/index';

export const TABLE_WIDTH_PINNED = 30;
export const TABLE_WIDTH_USERNAME = 180;
export const TABLE_WIDTH_FORM = 180;
export const TABLE_WIDTH_ACTIONS = 150;

export interface FormFieldOptionTypes {
    id: number;
    value: string | null;
}

export const getTagsForFilter = (tags: FormTagType[]): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    tags.forEach((item: FormTagType) => {
        results.push({
            name: String(item.id),
            label: item.title,
        });
    });
    return results;
};

export const getFormVersionsList = (forms: FormItemBasic[]): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    forms.forEach((form: FormItemBasic) => {
        const versions: FormVersionTypes[] = get(form, 'versions', []);
        versions.forEach((formVersion: FormVersionTypes) => {
            const {id, title} = formVersion;
            results.push({
                name: String(id),
                label: `${form.title} (${title})`,
                workflowId: Number(form.workflow),
                uuid: formVersion.uuid,
            });
        });
    });
    return results;
};

export const getFormsForFilter = (forms: FormItemBasic[]): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    forms.forEach((form: FormItemBasic) => {
        const versions: FormVersionTypes[] = get(form, 'versions', []);
        if (versions.length === 1) {
            versions.forEach((formVersion: FormVersionTypes) => {
                const {id} = formVersion;
                results.push({
                    name: String(id),
                    label: form.title,
                });
            });
        } else if (versions.length > 1) {
            const subItems: FilterOptionType[] = [];
            versions.forEach((formVersion: FormVersionTypes) => {
                const {title, id} = formVersion;
                subItems.push({
                    name: String(id),
                    label: title,
                });
            });
            results.push({
                name: form.title,
                label: form.title,
                subItems: subItems,
            });
        }
    });
    return results;
};

export const getAgentsForFilter = (agents: AgentItemBasic[]): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    results.push({
        name: DEFAULT_FILTER_OPTION,
        label: 'common.all',
    });
    agents.forEach(item => {
        const {id, firstName, lastName, email, casesNumber} = item;
        if (casesNumber > 0) {
            results.push({
                name: String(id),
                label: firstName && lastName ? `${firstName} ${lastName}` : email,
            });
        }
    });
    results.push({
        name: DEFAULT_NONE,
        label: 'orguser.requesterCase.notAssigned',
    });
    return results;
};

export const getFormFieldOptions = (
    formFieldFilters: FormFieldFilterType[],
    searchParams: URLSearchParams,
): FormFieldOptionTypes[] => {
    const results: FormFieldOptionTypes[] = [];
    formFieldFilters.forEach(field => {
        results.push({
            id: Number(field.id),
            value: searchParams.get(`field${field.id}`),
        });
    });
    return results;
};

export const getAnswerFromResultJson = (result: any, key: string): string | null => {
    for (const iKey in result) {
        if (get(result, iKey, false)) {
            if (iKey === key) {
                return result[iKey];
            } else if (typeof result[iKey] === 'object' && result[iKey] !== null) {
                const search = getAnswerFromResultJson(result[iKey], key);
                if (search) {
                    return search;
                }
            }
        }
    }
    return null;
};

export const isResultHasOption = (result: string, fieldId: number, value: string): boolean => {
    const resultJson = JSON.parse(result);
    const userAnswer = getAnswerFromResultJson(resultJson, `field-${fieldId}`);
    return Boolean(userAnswer && userAnswer.includes(value));
};

export const getRequesterCases = (
    requesterCasesList: RequesterCaseResponseTypes[],
    sortBy: string | null,
    sortType: string | null,
    fieldOptions: FormFieldOptionTypes[],
) => {
    let requesterCases = requesterCasesList;
    if (fieldOptions.length > 0) {
        fieldOptions.forEach((option: FormFieldOptionTypes) => {
            const {id, value} = option;
            if (value && value !== DEFAULT_FILTER_OPTION) {
                requesterCases = requesterCases.filter(
                    requesterCase => requesterCase.resultJson && isResultHasOption(requesterCase.resultJson, id, value),
                );
            }
        });
    }
    return requesterCases;
};

export const getFormFieldOptionsList = (
    formId: number,
    options: FieldOptionType[],
    translations: FormTranslationItemTypes[],
): FilterOptionType[] => {
    const results: FilterOptionType[] = [];
    options.forEach(item => {
        const keyword = getOptionKeyword(formId, item.id, 'title');
        results.push({
            name: String(item.id),
            label: getFormTranslatedLabel(translations, keyword, item.title),
        });
    });
    return results;
};

export const getCasesStatus = (statusFromUrl: string | null, currentTab: string | null): string => {
    const fromUrl = getParameterFromUrl(statusFromUrl);
    if (fromUrl) {
        return fromUrl;
    }
    if (currentTab && currentTab.includes(STATUS_FORWARDED)) {
        return STATUS_FORWARDED;
    }
    if (currentTab && currentTab.includes(STATUS_DECLINED)) {
        return STATUS_DECLINED;
    }
    if (currentTab && currentTab.includes(STATUS_HELPED)) {
        return STATUS_HELPED;
    }
    if (currentTab && currentTab.includes(STATUS_DELETED)) {
        return STATUS_DELETED;
    }
    return '';
};
