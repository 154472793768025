import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import Markdown from 'react-markdown';
import {useParams} from 'react-router-dom';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_OPTION, UPDATE_REQUESTER_CASE_POPUP_OPTION} from 'appRedux/actions/requestCase';
import {RequesterCaseOptionTypes} from 'appRedux/actions/requestCase/types';
import {FieldOptionType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FormSelectorWrapper from 'pages/client/form/wrappers/FormSelectorWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {isRequesterCaseOptionPresented} from 'pages/client/form/fieldTypes/helper';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {ignoreHtmlInString, getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

const FormCheckbox: FC<FormFieldPropsType> = ({
    formId,
    isPopup,
    pageId,
    sectionId,
    field,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    disabled,
    popupContent,
    previewMode,
    popupItemId,
    onSaveClicked,
}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [previewModeSelectedOptions, setPreviewModeSelectedOptions] = useState<string[]>([]);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const updateRequesterCaseOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const updateRequesterCasePopupOption = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST, payload: data}),
        [dispatch],
    );

    const options = field.fieldOptions || [];
    const keywordsOptions = options.map(opt => getOptionKeyword(formId, opt.id, opt.text ? 'text' : 'title'));
    const translatedOptions = keywordsOptions.map((keyword, i) =>
        getFormTranslatedLabel(translations, keyword, options[i].text || options[i].title),
    );

    const initialValue = getInitialFieldValue(pageId, sectionId, field.id, clientInformation, isPopup, popupContent);

    const [selectedOptions, setSelectedOptions] = useState<string[]>(
        initialValue ? initialValue.split(OPTIONS_SEPARATOR) : [],
    );

    const onCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const option = event.target.value;
        if (selectedOptions.includes(option)) {
            for (let i = 0, n = selectedOptions.length; i < n; i++) {
                if (selectedOptions[i] === option) {
                    selectedOptions.splice(i, 1);
                }
            }
        } else {
            setErrorField && setErrorField(null);
            selectedOptions.push(option);
        }
        const value = selectedOptions.join(OPTIONS_SEPARATOR);
        if (previewMode) {
            setPreviewModeSelectedOptions(selectedOptions);
            setIsUpdated(previous => !previous);
        } else if (isPopup && onPopupFieldChange) {
            updateRequesterCasePopupOption({
                id: Number(requestCase),
                optionId: Number(option),
                requesterCaseHasPopupId: popupItemId,
                showAlert,
                callback: () => {
                    onPopupFieldChange(field.id, value);
                    setSelectedOptions(selectedOptions);
                    setIsInitialRender(false);
                    setIsUpdated(previous => !previous);
                },
            });
        } else if (handleChangeInformation) {
            updateRequesterCaseOption({
                id: Number(requestCase),
                optionId: Number(option),
                showAlert,
                callback: () => {
                    handleChangeInformation(pageId, sectionId, field.id, value);
                    setSelectedOptions(selectedOptions);
                    setIsInitialRender(false);
                    setIsUpdated(previous => !previous);
                },
            });
        }
    };

    useEffect(() => {
        setSelectedOptions(initialValue ? initialValue.split(OPTIONS_SEPARATOR) : []);
    }, [initialValue]);

    useEffect(() => {
        if (field.id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    const requesterCaseOptions: RequesterCaseOptionTypes[] = useMemo(() => {
        if (isPopup && popupItemId) {
            const currentItem = currentCase.popupItems.find(item => item.id === popupItemId);
            return currentItem?.options || [];
        } else {
            return get(currentCase, 'options', []);
        }
    }, [currentCase, isPopup, popupItemId]);

    return (
        <FormSelectorWrapper
            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            field={field}
            formId={formId}
            previewMode={previewMode}
        >
            <FormGroup defaultValue={initialValue} onChange={onCheckboxSelect}>
                {options.map((option: FieldOptionType, index: number) => {
                    const translatedValue = get(translatedOptions, index, null);
                    const value =
                        !previewMode && translatedValue
                            ? ignoreHtmlInString(translatedValue)
                            : option.text || option.title;
                    return (
                        <FormControlLabel
                            data-id={`input#form-selector-${formId}-${pageId}-${sectionId}-${field.id}-checkbox-${index}`}
                            key={`checkbox-${index}`}
                            disabled={disabled}
                            control={
                                <Checkbox
                                    checked={
                                        previewMode
                                            ? previewModeSelectedOptions.includes(String(option.id))
                                            : isRequesterCaseOptionPresented(option.id, requesterCaseOptions)
                                    }
                                    value={option.id}
                                />
                            }
                            label={
                                option.text ? (
                                    <Markdown>{value}</Markdown>
                                ) : (
                                    <Typography variant="body1">{value}</Typography>
                                )
                            }
                        />
                    );
                })}
            </FormGroup>
        </FormSelectorWrapper>
    );
};

export default FormCheckbox;
