import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {BoardItemTypes, BoardStatusTypes} from 'appRedux/actions/workflow/types';
import {REQUESTER_CASES_LIST, AGENT_LIST} from 'appRedux/actions/admin';
import {RootReducer} from 'appRedux/reducers';

import ColumnHeader from 'components/BlockView/ColumnHeader';
import CasesColumn from 'components/BlockView/CasesColumn';
import {
    BoardViewCasesTypes,
    getBoardWidth,
    getBoardViewColumns,
    getCustomBoardsColumnNumber,
    COLUMN_HEADER_HEIGHT,
    DASHBOARD_SCROLLBAR_WIDTH,
} from 'components/BlockView/helper';
import {getParameterFromUrl, getSearchFromUrl} from 'components/AdminScreenComponents/translationsHelper';
import MultipleActionButtonsPanel from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/MultipleActionButtonsPanel';

import {getVisibleHeight} from 'helpers/getVisibleHeight';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';
import {
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_FORM,
    PARAMETER_AGENT,
    PARAMETER_SEARCH,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
    PARAMETER_COLUMN_SIZE,
} from 'config/index';

interface CustomBoardCasesType {
    currentBoard: BoardItemTypes;
}

const CustomBoardCases: FC<CustomBoardCasesType> = ({currentBoard}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {statuses, uuid} = currentBoard;

    const getAgents = useCallback(() => dispatch({type: AGENT_LIST.REQUEST}), [dispatch]);

    const getRequesterCasesCustomBoard = useCallback(
        data => dispatch({type: REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD, payload: data}),
        [dispatch],
    );

    const {
        admin: {requesterCasesColumns},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const boardViewCases: BoardViewCasesTypes[] = getBoardViewColumns(requesterCasesColumns);

    const agentFromUrl = searchParams.get(PARAMETER_AGENT);
    const formFromUrl = searchParams.get(PARAMETER_FORM);
    const searchFromUrl = searchParams.get(PARAMETER_SEARCH);
    const lastCreatedFromUrl = searchParams.get(PARAMETER_LAST_CREATED);
    const lastUpdatedFromUrl = searchParams.get(PARAMETER_LAST_UPDATED);
    const tagsFromUrl = searchParams.get(PARAMETER_TAG);
    const optionsFromUrl = searchParams.get(PARAMETER_OPTION);
    const columnSize = localStorage.getItem(PARAMETER_COLUMN_SIZE);

    useEffect(() => {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'hidden';
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.overflowX = 'auto';
            document.body.style.overflowY = 'auto';
        };
    }, []);

    useEffect(() => {
        getRequesterCasesCustomBoard({
            uuid,
            agent: getParameterFromUrl(agentFromUrl),
            form: getParameterFromUrl(formFromUrl),
            tags: getParameterFromUrl(tagsFromUrl),
            options: getParameterFromUrl(optionsFromUrl),
            lastCreated: getParameterFromUrl(lastCreatedFromUrl),
            lastUpdated: getParameterFromUrl(lastUpdatedFromUrl),
            search: searchFromUrl && searchFromUrl.length >= 3 ? getSearchFromUrl(searchFromUrl) : '',
        });
    }, [
        uuid,
        agentFromUrl,
        formFromUrl,
        searchFromUrl,
        tagsFromUrl,
        optionsFromUrl,
        lastCreatedFromUrl,
        lastUpdatedFromUrl,
    ]);

    useEffect(() => {
        getAgents();
    }, []);

    const columnsNumber = getCustomBoardsColumnNumber(statuses);

    const columnsWrapperRef = useRef<HTMLDivElement>(null);

    const [columnsHeight, setColumnsHeight] = useState(0);

    useEffect(() => {
        if (columnsWrapperRef && columnsWrapperRef.current) {
            setColumnsHeight(getVisibleHeight(columnsWrapperRef.current));
        }

        const onResize = () => {
            console.log('resize', getVisibleHeight(columnsWrapperRef.current));
            setColumnsHeight(getVisibleHeight(columnsWrapperRef.current));
        };

        window.onresize = onResize;

        return () => {
            window.onresize = () => {
                return;
            };
        };
    }, [columnsWrapperRef]);

    return (
        <Box
            sx={{
                ml: -6,
                mr: -4,
                mb: -3,
                pl: 7.2,
                pr: 4,
                overflowX: 'auto',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    '-webkit-appearance': 'none',
                    '&:horizontal': {
                        height: DASHBOARD_SCROLLBAR_WIDTH + 'px',
                    },
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '8px',
                    border: '2px solid white' /* should match background, can't be transparent */,
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#fff',
                    height: DASHBOARD_SCROLLBAR_WIDTH + 'px',
                },
                height: columnsHeight + COLUMN_HEADER_HEIGHT + 'px',
            }}
        >
            <Grid
                container
                sx={{
                    pb: 2,
                    zIndex: 100,
                    backgroundColor: CLIENT_BACKGROUND_COLOR,
                    width: `max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%)`,
                    height: COLUMN_HEADER_HEIGHT,
                }}
                spacing={2}
                columns={2 * columnsNumber}
            >
                {statuses.map((item: BoardStatusTypes) => {
                    if (!item.isActive) return null;
                    return (
                        <Grid item sm={2} key={`columnHeader-${item.uuid}`}>
                            <ColumnHeader
                                title={item.title}
                                color={item.color}
                                subtitle={get(requesterCasesColumns, [item.statusUuid, 'count'], 0)}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <Grid
                ref={columnsWrapperRef}
                container
                spacing={0}
                sx={{
                    mt: 2,
                    ml: -2,
                    width: `max(${getBoardWidth(columnsNumber, Number(columnSize))}px, 100%)`,
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'stretch',
                }}
                columns={2 * columnsNumber}
            >
                {boardViewCases.map(item => (
                    <CasesColumn item={item} key={`${item.status}`} height={columnsHeight} />
                ))}
            </Grid>
            <MultipleActionButtonsPanel />
        </Box>
    );
};

export default CustomBoardCases;
