import * as yup from 'yup';

import {decimalOrIntegerValidation} from 'helpers/validation';

export const validationSchema = () =>
    yup.object({
        title: yup.string().required('messages.validation.isRequired'),
    });

export const validationBudgetSchema = () =>
    yup.object({
        title: yup.string().required('messages.validation.isRequired'),
        amount: yup
            .number()
            .typeError('messages.validation.isNumberRequired')
            .required('messages.validation.isRequired')
            .positive('messages.validation.onlyPositiveValue')
            .test('decimal', 'messages.validation.decimalFormatRequired', value =>
                decimalOrIntegerValidation(String(value)),
            ),
    });

export const validationSlotSchema = () =>
    yup.object({
        title: yup.string().required('messages.validation.isRequired'),
        eventName: yup.string().required('messages.validation.isRequired'),
        qty: yup.number().required('messages.validation.isRequired').positive('messages.validation.onlyPositiveValue'),
    });

export const validationBookframeSchema = () =>
    yup.object({
        startTime: yup.string().required('messages.validation.isRequired'),
        endTime: yup.string().required('messages.validation.isRequired'),
    });

export const validationExceptionSchema = () =>
    yup.object({
        date: yup.string().required('messages.validation.isRequired'),
    });

export const validationCalendarSlotSchema = () =>
    yup.object({
        name: yup.string().required('messages.validation.isRequired'),
        duration: yup
            .number()
            .required('messages.validation.isRequired')
            .positive('messages.validation.onlyPositiveValue'),
    });
