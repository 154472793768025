import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import SlotInformationItem from 'pages/agent/requesterPage/sections/SlotInformationItem';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface RequesterCaseHasSlotInformationType {
    field: FormFieldTypes;
    keywordTitle: string;
    keywordDescription: string;
}

const RequesterCaseHasSlotInformation: FC<RequesterCaseHasSlotInformationType> = ({
    field,
    keywordTitle,
    keywordDescription,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCaseSlots},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentSlot = currentCaseSlots
        ? currentCaseSlots.find(slot => slot.slotId === field.resourceFieldSlot)
        : null;
    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            {currentSlot ? (
                <SlotInformationItem currentSlot={currentSlot} />
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </InformationWrapper>
    );
};

export default RequesterCaseHasSlotInformation;
