import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_REQUESTER_CASE_BUDGET, REQUESTER_CASE_BUDGETS_LIST} from 'appRedux/actions/requestCase';
import {RequesterCaseBudgetFormTypes, RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes, ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';

import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import RequesterCaseBudgetForm from 'components/Forms/RequesterCaseBudgetForm/RequesterCaseBudgetForm';
import RequesterCaseBudgetInputCalendarForm from 'components/Forms/RequesterCaseBudgetForm/RequesterCaseBudgetInputCalendarForm';
import {TYPE_BUDGET_INPUT_CALENDAR} from 'components/Forms/FormBuilder/FieldForm/helper';
import {RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';

import {REQUESTER_CASE_HAS_BUDGET_BOOKED} from 'pages/client/form/fieldTypes/helper';

interface UpdateRequesterCaseBudgetFormType {
    caseId: number;
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    currentBudget: RequesterCaseHasBudgetTypes;
    field: FormFieldTypes;
    closeModal?: () => void;
}

const UpdateRequesterCaseBudgetForm: FC<UpdateRequesterCaseBudgetFormType> = ({
    caseId,
    currentBudget,
    field,
    resourceFieldBudgetInformation,
    closeModal,
}) => {
    const dispatch = useDispatch();

    const {uuid, amount, amountUser, startAt, endAt, startUserAt, endUserAt} = currentBudget;

    const {showAlert} = useContext(AlertContext);
    const {isClientMode} = useContext(RouteContext);

    const updateRequesterCaseBudget = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_BUDGET.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseBudgets = useCallback(
        data => dispatch({type: REQUESTER_CASE_BUDGETS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: RequesterCaseBudgetFormTypes) => {
        updateRequesterCaseBudget({
            ...values,
            uuid,
            showAlert,
            callback: () => {
                getRequesterCaseBudgets({
                    id: caseId,
                    showAlert,
                });
                closeModal && closeModal();
            },
        });
    };

    const {id, resourceField, typeBudgetInput, resourceFieldBudget} = field;

    const isMoneyType = resourceFieldBudgetInformation.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY;

    if (!resourceField || !resourceFieldBudget) return null;

    if (typeBudgetInput === TYPE_BUDGET_INPUT_CALENDAR) {
        return (
            <RequesterCaseBudgetInputCalendarForm
                initialValues={{
                    formField: id,
                    resourceField,
                    resourceFieldBudget,
                    amount: 0,
                    amountUser: 0,
                    startAt,
                    endAt,
                    startUserAt,
                    endUserAt,
                }}
                availableAmount={resourceFieldBudgetInformation.amount}
                isDisabled={currentBudget.status === REQUESTER_CASE_HAS_BUDGET_BOOKED}
                onSubmitClicked={onSubmitClicked}
            />
        );
    }
    return (
        <RequesterCaseBudgetForm
            initialValues={{
                formField: id,
                resourceField,
                resourceFieldBudget,
                amount: isMoneyType && !isClientMode ? (0.01 * Number(amount)).toFixed(2) : amount,
                amountUser: isMoneyType && isClientMode ? (0.01 * Number(amountUser)).toFixed(2) : amountUser,
                startAt,
                endAt,
                startUserAt,
                endUserAt,
            }}
            resourceFieldBudgetInformation={resourceFieldBudgetInformation}
            isDisabled={currentBudget.status === REQUESTER_CASE_HAS_BUDGET_BOOKED}
            onSubmitClicked={onSubmitClicked}
        />
    );
};

export default UpdateRequesterCaseBudgetForm;
