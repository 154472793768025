import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import {
    TYPE_BUDGET_DEFINITION_REQUESTER,
    TYPE_BUDGET_DEFINITION_BOTH,
} from 'components/Forms/FormBuilder/FieldForm/helper';

import {getBudgetAmountValue} from 'helpers/resourceFieldsHelper';

interface BookedAmountInformationType {
    budgetType: number;
    typeBudgetDefinition: number;
    currentBudget: RequesterCaseHasBudgetTypes;
}

const BookedAmountInformation: FC<BookedAmountInformationType> = ({
    budgetType,
    typeBudgetDefinition,
    currentBudget,
}) => {
    const [t] = useTranslation();

    const {amount, amountUser} = currentBudget;

    if (typeBudgetDefinition === TYPE_BUDGET_DEFINITION_REQUESTER) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.askedAmount')}:`}</Typography>
                <Typography>{getBudgetAmountValue(budgetType, amountUser)}</Typography>
            </Box>
        );
    }

    if (typeBudgetDefinition === TYPE_BUDGET_DEFINITION_BOTH) {
        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.askedAmount',
                    )}:`}</Typography>
                    <Typography>{getBudgetAmountValue(budgetType, amountUser)}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'orguser.resourceFields.bookedAmount',
                    )}:`}</Typography>
                    <Typography>{getBudgetAmountValue(budgetType, amount)}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.bookedAmount')}:`}</Typography>
            <Typography>{getBudgetAmountValue(budgetType, amount)}</Typography>
        </Box>
    );
};

export default BookedAmountInformation;
