import get from 'lodash/get';

import {store} from 'appRedux/store';
import {setErrors} from 'appRedux/actions/errors';
import {RefreshTokenResponseTypes} from 'appRedux/actions/auth/types';

import {
    LOCAL_STORAGE_LOGOUT_TIME,
    LOCAL_STORAGE_PERSIST_ROOT,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_TOKEN,
    getRefreshToken,
    getToken,
    setRefreshToken,
    setToken,
} from 'services/localStorage';

import {isPublicPath} from 'helpers/authorizationHelper';
import {getExpirationAndCurrentTimeDifference} from 'helpers/menuHelper';

import {REFRESH_TOKEN_AFTER_PENDING, TWO_FACTOR_CONFIRMATION_URL} from 'config/index';

interface Options {
    [key: string]: {[key: string]: string} | string | undefined;
}

interface GlobalOptions {
    headers: {
        [key: string]: string | undefined;
    };
    mode?: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
}

export const ERROR_400 = 'ERROR_400';
export const ERROR_401 = 'ERROR_401';
export const ERROR_403 = 'ERROR_403';
export const ERROR_404 = 'ERROR_404';
export const ERROR_500 = 'ERROR_500';
export const ERROR_FETCH = 'ERROR_FETCH';

/**
 * These API-requests should work both with/without token
 */
export const URL_WITHOUT_TOKEN = [
    'case/anonymous',
    'translations/en',
    'translations/de',
    'translations/es',
    'translations/fr',
    'translations/it',
    'translations/ua',
    'translations/ru',
    'translations/dari',
    'language/list',
    'instance/settings',
    'instance/files',
    'openid/auth',
    'openid/login',
    'openid/registration',
    'organization/domain',
    'organization/info/en',
    'organization/info/de',
    'organization/info/es',
    'organization/info/fr',
    'organization/info/it',
    'organization/info/ua',
    'organization/info/ru',
    'organization/info/dari',
    'forget-nickname',
    'form/base',
    'form/invite',
    'redirect/auth',
    'reset-password',
    'password-recovery',
    'login_check',
    'user/email',
];

export const FORM_TRANSLATION_URL_TEMPLATE = new RegExp(/form\/(.*)\/lang\/en/);

const REFRESH_TOKEN_URL = 'token/refresh';

const clearLocalStorage = async () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_PERSIST_ROOT);
    localStorage.removeItem(LOCAL_STORAGE_LOGOUT_TIME);
};

export const handleRefreshToken = async (refreshToken: unknown, token: unknown): Promise<any> => {
    try {
        if (typeof refreshToken !== 'string') throw new Error();
        if (typeof token !== 'string') throw new Error();

        const refreshOptions = {
            method: 'POST',
            body: JSON.stringify({
                refresh_token: refreshToken,
            }),
        };

        const urlParams = new URLSearchParams(get(refreshOptions, 'params', {}));
        const newUrl = `${process.env.REACT_APP_API_URL}/${REFRESH_TOKEN_URL}${
            urlParams.toString() ? '?' : ''
        }${urlParams.toString()}`;

        const global = await globalOptions(REFRESH_TOKEN_URL, token);
        const requestOptions = {...global, ...refreshOptions};

        const response = await fetch(newUrl, requestOptions as any);
        const data = (await checkStatus(response, refreshOptions)) as RefreshTokenResponseTypes;

        if (data.token && data.refresh_token) {
            await setToken(data.token);
            await setRefreshToken(data.refresh_token);
        }
    } catch (error) {
        store.dispatch(setErrors.generalError(ERROR_401));
        clearLocalStorage();
        window.location.reload();
    }
};

export const globalOptions = async (url: string, token: unknown): Promise<GlobalOptions> => {
    const {host} = location;
    const authorizationHeader = token && !URL_WITHOUT_TOKEN.includes(url) ? {Authorization: `Bearer ${token}`} : {};
    return {
        headers: {
            'Content-Type': 'application/json',
            'HERUPU-ORG': host,
            ...authorizationHeader,
        },
        mode: 'cors',
    };
};

const checkStatus = async <T>(response: Response, options: Options): Promise<ErrorEvent | T | Blob> => {
    let json: Promise<T> | Blob;

    try {
        if (options.responseType === 'blob') {
            json = await (response.blob() || response.json());
        } else {
            json = await response.json();
        }

        switch (true) {
            case response.status >= 200 && response.status < 300:
                return json;
            case response.status === 404:
                throw new Error(ERROR_404);
            case response.status === 401:
                if (!isPublicPath(String(options.isLoginCheck))) {
                    throw new Error(ERROR_401);
                }
                break;
            case response.status === 403:
                throw new Error(ERROR_403);
            default:
                return json;
        }
    } catch (error) {
        store.dispatch(setErrors.generalError(String(error)));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return json;
};

export const isUserVerificationUrl = (url: string): boolean => {
    return (
        url.includes('verify?') &&
        url.includes('id=') &&
        url.includes('expires=') &&
        url.includes('token=') &&
        url.includes('signature=')
    );
};

export const http = async (url: string, options: Options): Promise<unknown> => {
    const token = await getToken();
    const refreshToken = await getRefreshToken();

    const handleClearTokens = () => {
        store.dispatch(setErrors.generalError(ERROR_401));
        clearLocalStorage();
        window.location.reload();
    };

    const urlWithoutToken =
        URL_WITHOUT_TOKEN.includes(url) || FORM_TRANSLATION_URL_TEMPLATE.test(url) || isUserVerificationUrl(url);

    if (token && refreshToken) {
        const difference = getExpirationAndCurrentTimeDifference(String(token));

        if (difference && difference <= 0 && !urlWithoutToken) {
            handleClearTokens();
        } else if (difference && difference < REFRESH_TOKEN_AFTER_PENDING && url !== REFRESH_TOKEN_URL) {
            handleRefreshToken(refreshToken, token);
        }
    } else if (token && url === TWO_FACTOR_CONFIRMATION_URL) {
        const difference = getExpirationAndCurrentTimeDifference(String(token));
        if (difference && difference <= 0 && !urlWithoutToken) {
            handleClearTokens();
        }
    } else if (!urlWithoutToken) {
        handleClearTokens();
    }

    const global = await globalOptions(url, token);
    const newOptions = {...global, ...options};
    // remove headers for multipart/form-data upload
    if (
        url.includes('update/logo') ||
        url.includes('user/case') ||
        url.includes('form/logo') ||
        url.includes('chat/document') ||
        url.includes('organization/logo') ||
        url.includes('organization/favicon') ||
        url.includes('translation/import') ||
        url.includes('option/create') ||
        url.includes('option/update') ||
        url.includes('/upload')
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete newOptions?.headers['Content-Type'];
    }
    const urlParams = new URLSearchParams(get(options, 'params', {}));
    const newUrl = `${process.env.REACT_APP_API_URL}/${url}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`;

    try {
        const handleFetch = async () => fetch(newUrl, newOptions as any);

        let response = await handleFetch();

        if (response.status === 401 && !isPublicPath(String(options.isLoginCheck))) {
            await handleRefreshToken(refreshToken, token);
            response = await handleFetch();
        }

        return await checkStatus(response, options);
    } catch (error) {
        store.dispatch(setErrors.generalError(ERROR_FETCH));
    }
};
