import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import FormSection from 'pages/client/form/partials/FormSection';

import {STATUS_OPENING} from 'config/index';
import {theme} from 'config/theme';

interface FormPreviewType {
    formId: number;
    page: FormPageTypes;
    isActive?: boolean;
}

const FormPreview: FC<FormPreviewType> = ({formId, page, isActive}) => {
    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {sections} = page;

    const status = get(currentCase, 'status', null);

    const [clientInformation, setClientInformation] = useState({});

    const handleChangeInformation = (pageId: number, sectionId: number, fieldId: number, value: string) => {
        const pageKeyword = `page-${pageId}`;
        const sectionKeyword = `section-${sectionId}`;
        const fieldKeyword = `field-${fieldId}`;

        const pageContent = get(clientInformation, [pageKeyword], {});
        const sectionContent = get(clientInformation, [pageKeyword, sectionKeyword], {});

        setClientInformation({
            ...clientInformation,
            [pageKeyword]: {
                ...pageContent,
                [sectionKeyword]: {
                    ...sectionContent,
                    [fieldKeyword]: value,
                },
            },
        });
    };

    if (!isActive) return null;

    return (
        <Box
            sx={{
                borderColor: theme.palette.info.main,
                borderWidth: 2,
                borderRadius: 2,
                borderStyle: 'solid',
                p: 1,
            }}
        >
            <Box>
                <Box
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pl: 2,
                        pr: 2,
                    }}
                >
                    <Typography align="center" sx={{fontSize: 24, mb: 2}}>
                        {page.title}
                    </Typography>
                    {page.description && <MarkdownWithShowAll text={page.description} />}
                </Box>
                {sections &&
                    sections.map((section: FormSectionTypes, index: number) => {
                        if (!section.isRequired && status === STATUS_OPENING) {
                            return null;
                        }
                        return (
                            <Box
                                sx={{
                                    borderTop: index !== 0 ? `${theme.palette.info.main} 1px solid` : 'none',
                                }}
                                key={`page-${page.title}-section-${index}`}
                            >
                                <FormSection
                                    formId={formId}
                                    pageId={page.id}
                                    section={section}
                                    handleChangeInformation={handleChangeInformation}
                                    clientInformation={clientInformation}
                                    previewMode
                                />
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default FormPreview;
