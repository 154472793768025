import React, {FC, useRef} from 'react';
import {usePdf} from '@mikecousins/react-pdf';

import Box from '@mui/material/Box';

import {OrganizationPdfAttachmentTypes} from 'appRedux/actions/organization/types';

import HerupuSpinner from 'assets/herupuSpinner';
import {ERROR_TEXT_COLOR} from 'config/theme';

interface AttachmentPagePreviewType {
    attachment?: OrganizationPdfAttachmentTypes | null;
    isFrontPage?: boolean;
    description?: string;
}

const AttachmentPagePreview: FC<AttachmentPagePreviewType> = ({attachment, isFrontPage, description}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const pdfSource = attachment?.title.endsWith('pdf') ? attachment?.link : '';

    const {pdfDocument} = usePdf({
        file: pdfSource,
        page: 1,
        scale: 1,
        canvasRef,
        workerSrc: '/js/pdf.worker.min.js',
        onPageRenderSuccess: () => {
            if (canvasRef && canvasRef.current && canvasRef.current.style) {
                const heightScale = canvasRef.current.clientHeight / (containerRef.current?.clientHeight || 1);
                const widthScale = canvasRef.current.clientWidth / (containerRef.current?.clientWidth || 1);

                if (
                    heightScale >= widthScale &&
                    canvasRef.current.clientHeight > (containerRef.current?.clientHeight || 1)
                ) {
                    const height = `${containerRef.current?.clientHeight}px` || 'auto';
                    canvasRef.current.style.width = 'auto';
                    canvasRef.current.style.height = height;
                }

                if (
                    heightScale < widthScale &&
                    canvasRef.current.clientWidth > (containerRef.current?.clientWidth || 1)
                ) {
                    const width = `${containerRef.current?.clientWidth}px` || 'auto';
                    canvasRef.current.style.width = width;
                    canvasRef.current.style.height = 'auto';
                }
            }
        },
    });

    return (
        <Box
            sx={{
                paddingBottom: '141.4%',
                border: '1px solid rgba(0, 0, 0, 0.25)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                position: 'relative',
                maxWidth: '595px',
                maxHeight: '842px',
            }}
        >
            {pdfSource ? (
                !pdfDocument ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <HerupuSpinner size={80} />
                    </Box>
                ) : (
                    <Box
                        sx={{position: 'absolute', height: '100%', width: '100%', top: 0, bottom: 0, rigth: 0, left: 0}}
                        ref={containerRef}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                overflow: 'hidden',
                            }}
                            ref={containerRef}
                        >
                            <canvas ref={canvasRef} style={{}} />
                        </Box>
                    </Box>
                )
            ) : (
                attachment?.title && (
                    <Box
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <img src={attachment?.link} style={{width: '100%'}} />
                    </Box>
                )
            )}

            {description && (
                <Box
                    sx={{
                        position: 'absolute',
                        height: isFrontPage ? '65%' : '85%',
                        width: '85%',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: `2px solid ${ERROR_TEXT_COLOR}`,
                        color: ERROR_TEXT_COLOR,
                        backgroundColor: 'rgba(200, 18, 18, 0.15)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {description}
                </Box>
            )}
        </Box>
    );
};

export default AttachmentPagePreview;
