import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, FormikProps} from 'formik';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import {ResourceFieldBudgetItemTypes} from 'appRedux/actions/resourceFields/types';
import {FormFieldRequestType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {
    budgetDefinitionOptions,
    budgetInputTextOptions,
    budgetTransparency,
    ResourceFieldBudgetOptionTypes,
    TYPE_BUDGET_DEFINITION_FIXED,
} from 'components/Forms/FormBuilder/FieldForm/helper';
import {RESOURCE_FIELD_BUDGET_TYPE_DAYS, RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {theme} from 'config/theme';

interface ResourceFieldBudgetElementsType {
    formik: FormikProps<FormFieldRequestType>;
}

const ResourceFieldBudgetElements: FC<ResourceFieldBudgetElementsType> = ({formik}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {resourceFieldBudgetItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedBudgetItem = resourceFieldBudgetItems.find(item => item.id === formik.values.resourceFieldBudget);

    const showBudgetAmountField = formik.values.typeBudgetDefinition === TYPE_BUDGET_DEFINITION_FIXED;
    const showBudgetInputTypes =
        selectedBudgetItem && selectedBudgetItem.type === RESOURCE_FIELD_BUDGET_TYPE_DAYS && !showBudgetAmountField;

    const isTouched = get(formik, ['touched', 'resourceFieldBudget'], false);
    const errorMessage = get(formik, ['errors', 'resourceFieldBudget'], false);

    return (
        <Box>
            <Box>
                <Typography variant="body2" sx={{mb: 1}}>
                    {t('orguser.forms.formField.resourceFieldBudget')}
                </Typography>
                <FormControl
                    sx={{
                        backgroundColor: theme.palette.warning.contrastText,
                        borderRadius: 3,
                        pl: 3,
                        pr: 3,
                        pb: 2,
                        mb: 1,
                    }}
                    required
                    fullWidth
                >
                    <Select
                        sx={{
                            height: 50,
                        }}
                        name={'resourceFieldBudget'}
                        value={formik.values.resourceFieldBudget}
                        variant="standard"
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={formik.handleChange}
                    >
                        {resourceFieldBudgetItems.map((resourceFieldBudget: ResourceFieldBudgetItemTypes) => {
                            return (
                                <MenuItem
                                    key={`resourceField-${resourceFieldBudget.uuid}`}
                                    value={resourceFieldBudget.id}
                                >
                                    <Typography>{resourceFieldBudget.title}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
                </FormControl>
            </Box>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Typography variant="body2" sx={{mb: 1}}>
                        {t('orguser.forms.formField.typeBudgetDefinition')}
                    </Typography>
                    <FormControl
                        sx={{
                            backgroundColor: theme.palette.warning.contrastText,
                            borderRadius: 3,
                            pl: 3,
                            pr: 3,
                            pb: 2,
                            mb: 1,
                        }}
                        required
                        fullWidth
                    >
                        <Select
                            sx={{
                                height: 50,
                            }}
                            name={'typeBudgetDefinition'}
                            value={formik.values.typeBudgetDefinition}
                            variant="standard"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={formik.handleChange}
                        >
                            {budgetDefinitionOptions.map((option: ResourceFieldBudgetOptionTypes) => {
                                return (
                                    <MenuItem
                                        key={`resourceField-budgetDefinition-${option.value}`}
                                        value={option.value}
                                    >
                                        <Typography>{t(option.label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    {showBudgetAmountField && (
                        <Box sx={{mt: 2}}>
                            <Field
                                required
                                type={
                                    selectedBudgetItem && selectedBudgetItem.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY
                                        ? 'text'
                                        : 'number'
                                }
                                name="budgetAmount"
                                label={t('orguser.forms.formField.budgetAmount')}
                                placeholder={t('orguser.forms.formField.budgetAmount')}
                                component={FormikTextInput}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item sm={6}>
                    <Typography variant="body2" sx={{mb: 1}}>
                        {t('orguser.forms.formField.typeBudgetTransparency')}
                    </Typography>
                    <FormControl
                        sx={{
                            backgroundColor: theme.palette.warning.contrastText,
                            borderRadius: 3,
                            pl: 3,
                            pr: 3,
                            pb: 2,
                            mb: 1,
                        }}
                        required
                        fullWidth
                    >
                        <Select
                            sx={{
                                height: 50,
                            }}
                            name={'typeBudgetTransparency'}
                            value={formik.values.typeBudgetTransparency}
                            variant="standard"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={formik.handleChange}
                        >
                            {budgetTransparency.map((option: ResourceFieldBudgetOptionTypes) => {
                                return (
                                    <MenuItem
                                        key={`resourceField-budgetTransparency-${option.value}`}
                                        value={option.value}
                                    >
                                        <Typography>{t(option.label)}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {showBudgetInputTypes && (
                    <Grid item sm={6}>
                        <Typography variant="body2" sx={{mb: 1}}>
                            {t('orguser.forms.formField.typeBudgetInput')}
                        </Typography>
                        <FormControl
                            sx={{
                                backgroundColor: theme.palette.warning.contrastText,
                                borderRadius: 3,
                                pl: 3,
                                pr: 3,
                                pb: 2,
                                mb: 1,
                            }}
                            required
                            fullWidth
                        >
                            <Select
                                sx={{
                                    height: 50,
                                }}
                                name={'typeBudgetInput'}
                                value={formik.values.typeBudgetInput}
                                variant="standard"
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={formik.handleChange}
                            >
                                {budgetInputTextOptions.map((option: ResourceFieldBudgetOptionTypes) => {
                                    return (
                                        <MenuItem
                                            key={`resourceField-budgetInput-${option.value}`}
                                            value={option.value}
                                        >
                                            <Typography>{t(option.label)}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ResourceFieldBudgetElements;
