import moment from 'moment';

import {RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';

export const getBudgetAmountValue = (type: number, amount: string | number | null): string | number => {
    if (!amount) return 0;
    if (type === RESOURCE_FIELD_BUDGET_TYPE_MONEY) {
        const money = Number(amount) * 0.01;
        return money.toFixed(2);
    }
    return amount;
};

export const getBudgetAmountDaysDifference = (startDate: string, endDate: string): string | number => {
    const from = moment(startDate);
    const to = moment(endDate);
    const duration = moment.duration(to.diff(from));
    return duration.asDays();
};
