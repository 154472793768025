import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {FORM_FIELD_RANGE, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

export const getFieldTitleLabel = (field: FormFieldTypes, initialTitle: string): string => {
    const {options, type} = field;
    const optionsArray = options ? options.split(OPTIONS_SEPARATOR) : [];
    if (type === FORM_FIELD_RANGE && optionsArray.length > 0) {
        return `${initialTitle} (${optionsArray[0]}-${optionsArray[1]})`;
    }
    return initialTitle;
};
