import React, {FC, useContext} from 'react';
import get from 'lodash/get';
import {View, Text} from '@react-pdf/renderer';

import {RequesterCasePopupType} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {isSelectionFields, OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {EMPTY_DATA} from 'config/index';

interface PopupFieldPdfContentType {
    pageId: number;
    sectionId: number;
    popupId: number;
    item: RequesterCasePopupType;
    fields: FormFieldTypes[];
    formId: number;
}

const PopupFieldPdfContent: FC<PopupFieldPdfContentType> = ({fields, item, formId}) => {
    const {translations} = useContext(PdfDocumentContext);
    const content = item.result && JSON.parse(item.result);

    return (
        <View style={pdfStyles.popupContent} wrap={false}>
            {fields.map((field: FormFieldTypes, index: number) => {
                const {id, label, type} = field;
                const fieldKeyword = getFieldKeyword(Number(formId), id, 'label');
                const value = get(content, [`field-${id}`], EMPTY_DATA);

                return (
                    <>
                        {index !== 0 && <View style={pdfStyles.divider}></View>}
                        <View style={pdfStyles.fieldTitle}>
                            <Text> {`${getFormTranslatedLabel(translations, fieldKeyword, label)}:`}</Text>
                        </View>
                        <View style={pdfStyles.popupFieldValue}>
                            <Text>
                                {isSelectionFields(type)
                                    ? String(value).replaceAll(OPTIONS_SEPARATOR, ', ')
                                    : String(value)}
                            </Text>
                        </View>
                    </>
                );
            })}
        </View>
    );
};

export default PopupFieldPdfContent;
