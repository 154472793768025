import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';

import AssignAgentButton from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/AssignAgentButton';
import ExportCasesButton from 'components/AgentScreenComponents/_caseBlock/MultipleActionsButtonsPanel/ExportCasesButton';

import AddTagButton from 'pages/agent/tableView/AddTagButton';

import {PARAMETER_FORM} from 'config/index';
import CreateStackButton from './CreateStackButton';

const MultipleActionButtonsPanel: FC = () => {
    const [searchParams] = useSearchParams();

    const {selectedIds, isSelectClicked} = useContext(AdminContext);

    const formVersionFromUrl = searchParams.get(PARAMETER_FORM);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const myOrganization = organizationList && organizationList.length > 0 ? organizationList[0] : null;

    if (selectedIds.length > 0 && isSelectClicked) {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80vw',
                    zIndex: 9,
                }}
            >
                {myOrganization && myOrganization.isExportAllowed && <ExportCasesButton />}
                <AssignAgentButton />
                <CreateStackButton />
                {!!formVersionFromUrl && formVersionFromUrl !== 'all' && <AddTagButton />}
            </Box>
        );
    }
    return null;
};

export default MultipleActionButtonsPanel;
