import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {getSelectedOptionsList} from 'pages/agent/requesterPage/sections/helper';

import {getFormTranslatedLabel, getOptionKeyword} from 'helpers/translationsHelper';

interface PopupInformationSelectableItemRowType {
    formId: number;
    field: FormFieldTypes;
    keyword: string;
    popupItemId: number;
}

const PopupInformationSelectableItemRow: FC<PopupInformationSelectableItemRowType> = ({
    formId,
    field,
    keyword,
    popupItemId,
}) => {
    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {
            currentCase: {popupItems},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const options = popupItems.find(item => item.id === popupItemId)?.options;
    const selectedOptions = field.fieldOptions && options ? getSelectedOptionsList(field.fieldOptions, options) : [];

    return (
        <Box sx={{mb: 2}}>
            <Typography variant="body1" sx={{fontWeight: 600, mb: 1}}>
                {`${getFormTranslatedLabel(translations, keyword, field.label)}: `}
            </Typography>
            <Box>
                {selectedOptions &&
                    selectedOptions.map(option => {
                        const keywordOptionTitle = getOptionKeyword(formId, option.id, 'title');
                        const keywordOptionText = getOptionKeyword(formId, option.id, 'text');
                        const title = getFormTranslatedLabel(translations, keywordOptionTitle, option.title);
                        const text = option.text
                            ? getFormTranslatedLabel(translations, keywordOptionText, option.text)
                            : null;
                        return (
                            <Box sx={{width: '100%'}} key={option.id}>
                                - {title}
                                {text && <DescriptionPopover text={text} />}
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default PopupInformationSelectableItemRow;
