import {
    FORM_FIELD_RANGE,
    FORM_FIELD_RATING,
    OPTIONS_SEPARATOR,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

const getFieldMaxValue = (fieldType: number, options: string): string | undefined => {
    if (!options) return;

    let maxValue;
    switch (fieldType) {
        case FORM_FIELD_RATING:
            maxValue = ' / 5';
            break;
    }
    return maxValue;
};

export default getFieldMaxValue;
