import get from 'lodash/get';

import {
    AGENT_LIST,
    CASES_ACTIVITIES_LIST,
    REQUESTER_CASES_LIST,
    GET_PINNED_CASES,
    GET_WAITING_APPROVE_CASES,
    AGENT_TEMPLATE_GET,
    AGENT_TEMPLATE_UPDATE,
    TOGGLE_LANGUAGE,
    LANGUAGE_LIST,
    REQUEST_CASE_ASSIGN_AGENT_MULTIPLE,
    REQUEST_CASE_CHANGE_STATUS_MULTIPLE,
    REQUEST_CASE_DRAG_AND_DROP,
    REQUEST_CASE_TOGGLE_PINNING_MULTIPLE,
    REQUEST_CASE_EXPORT_MULTIPLE,
    REQUEST_CASE_EXPORT,
    REQUEST_CASE_CHANGE_TAG_MULTIPLE,
    REQUEST_CASE_CREATE_STACK,
    REQUEST_CASE_DELETE_STACK,
} from 'appRedux/actions/admin';
import {
    AgentItemBasic,
    RequesterItemBasic,
    RequesterResponseTypes,
    RequesterCaseResponseTypes,
    AgentTemplateType,
    BoardResponseType,
    BoardItemsResponseType,
    ListItemsRequestTypesResponseTypes,
    ListUpdatedItemsRequestTypesResponseTypes,
    SwimlaneBoardResponseType,
} from 'appRedux/actions/admin/types';

import {
    AGENT_FILTERS_LIST,
    AGENT_FILTERS_LIST_CREATE,
    AGENT_FILTERS_LIST_UPDATE,
    AGENT_FILTERS_LIST_DELETE,
    AGENT_FILTER_TAGS_LIST,
} from 'appRedux/actions/agentFilters';
import {AgentSavedFilterTypes} from 'appRedux/actions/agentFilters/types';

import {
    ORGANIZATION_LIST,
    CREATE_ORGANIZATION,
    UPDATE_ORGANIZATION,
    TOGGLE_TWO_FACTOR_ORGANIZATION,
    DELETE_ORGANIZATION,
    CHANGE_ORGANIZATION_STATUS,
    ORGANIZATION_MULTIPLE_STATUS_CHANGING,
    ORGANIZATION_CHANGE_DOMAIN,
    ORGANIZATION_UPLOAD_LOGO,
    ORGANIZATION_UPLOAD_FAVICON,
    ORGANIZATION_LOGO_DELETE,
    ORGANIZATION_FAVICON_DELETE,
    ORGANIZATION_LOGO_GET,
    ORGANIZATION_QUOTAS_LIST,
    CREATE_ORGANIZATION_QUOTA,
    UPDATE_ORGANIZATION_QUOTA,
    DELETE_ORGANIZATION_QUOTA,
    TOGGLE_ORGANIZATION_QUOTA_HARD,
    GET_ORGANIZATIONS_STATISTIC,
    SEND_ORGANIZATIONS_STATISTIC,
    CREATE_ORGANIZATION_SSO_CREDENTIALS,
    UPDATE_ORGANIZATION_SSO_CREDENTIALS,
    UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS,
    ORGANIZATION_TEMPLATES_LIST,
    ORGANIZATION_TEMPLATE_CREATE,
    ORGANIZATION_TEMPLATE_UPDATE,
    ORGANIZATION_PRIVACY_POLICY_LIST,
    CREATE_ORGANIZATION_PRIVACY_POLICY,
    UPDATE_ORGANIZATION_PRIVACY_POLICY,
    TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT,
    CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION,
    ORGANIZATION_PDF_TEMPLATES_LIST,
    CREATE_ORGANIZATION_PDF_TEMPLATE,
    UPDATE_ORGANIZATION_PDF_TEMPLATE,
    DELETE_ORGANIZATION_PDF_TEMPLATE,
    UPLOAD_ORGANIZATION_PDF_ATTACHMENT,
    DELETE_ORGANIZATION_PDF_ATTACHMENT,
} from 'appRedux/actions/organization';
import {
    OrganizationItemBasic,
    OrganizationQuotasItemBasic,
    OrganizationTemplateItemTypes,
    OrganizationPrivacyPolicyItemTypes,
    OrganizationPdfTemplatesTypes,
} from 'appRedux/actions/organization/types';

import {
    ORGANIZATION_INTEGRATION_TOKENS_LIST,
    ORGANIZATION_INTEGRATION_TOKEN_CREATE,
    ORGANIZATION_INTEGRATION_TOKEN_UPDATE,
    ORGANIZATION_INTEGRATION_TOKEN_DELETE,
} from 'appRedux/actions/organizationIntegrationTokens';
import {OrganizationIntegrationTokenTypes} from 'appRedux/actions/organizationIntegrationTokens/types';

import {
    ORGANIZATION_ADMIN_LIST,
    ORGANIZATION_ADMIN_CREATE,
    ORGANIZATION_ADMIN_INVITE,
    ORGANIZATION_ADMIN_UPDATE,
    ORGANIZATION_ADMIN_PASSWORD_RESET,
    ORGANIZATION_ADMIN_UNLOCK,
    ORGANIZATION_ADMIN_DELETE,
    ORGANIZATION_ADMIN_2FA_DEACTIVATE,
    ORGANIZATION_AGENT_REASSIGN,
    ORGANIZATION_ADMIN_UPDATE_ROLES,
    ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION,
} from 'appRedux/actions/organizationAdmin';
import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';

import {
    GET_ORGANIZATION_LANGUAGES_LIST,
    ORGANIZATION_LANGUAGE_ADD,
    ORGANIZATION_LANGUAGE_ACTIVATE,
    ORGANIZATION_LANGUAGE_REMOVE,
    ORGANIZATION_TRANSLATIONS_LIST,
    ORGANIZATION_TRANSLATIONS_UPDATE,
} from 'appRedux/actions/organizationLanguage';
import {OrganizationLanguageTypes, OrganizationTranslationTypes} from 'appRedux/actions/organizationLanguage/types';

import {
    FormItemBasic,
    FormForRequesterItemBasic,
    FormTotalInformationTypes,
    FormPageTypes,
    FormHasLanguageTypes,
    FormLanguageTypes,
    FormFieldFilterType,
    FormFieldFilterResponseType,
    FormTagType,
    FormSnippetType,
    FormTranslationItemTypes,
    FormAllowListTypes,
    FormHasAdditionalTypes,
    CurrentFormWorkflowStatusesTypes,
    FormFaqItemTypes,
    FormVersionCardType,
} from 'appRedux/actions/forms/types';
import {
    FORMS_LIST,
    FORM_CHANGE_STATUS,
    FORM_DELETE,
    FORMS_COPY,
    FORM_CREATE,
    FORM_UPDATE,
    FORM_PAUSE_ON,
    FORM_PAUSE_OFF,
    FORM_PAGE_UP,
    FORM_PAGE_DOWN,
    FORM_MULTIPLE_STATUS_CHANGING,
    FORM_INFORMATION,
    FORM_BY_CASE_INFORMATION,
    FORM_VERSION_STATUSES,
    FORM_VERSION_STRUCTURE,
    CREATE_FORM_VERSION,
    UPDATE_FORM_VERSION,
    DEACTIVATE_FORM_VERSION,
    DELETE_FORM_VERSION,
    FORM_LANGUAGE_ADD,
    FORM_LANGUAGE_STATUS,
    FORM_TRANSLATIONS,
    FORM_MAIN_TRANSLATION_UPDATE,
    FORM_TRANSLATION_UPDATE,
    FORM_TRANSLATION_EXPORT,
    FORM_TRANSLATION_IMPORT,
    FORM_LANGUAGE_DELETE,
    FORM_PAGE_ADD,
    FORM_PAGE_UPDATE,
    FORM_PAGE_DELETE,
    FORM_PAGE_COPY,
    FORM_SECTION_ADD,
    FORM_SECTION_UPDATE,
    FORM_SECTION_DELETE,
    FORM_SECTION_COPY,
    FORM_SECTION_MOVE,
    FORM_SECTION_UP,
    FORM_SECTION_DOWN,
    FORM_SECTION_RECALCULATE_PRIORITIES,
    FORM_FIELD_ADD,
    FORM_FIELD_UPDATE,
    FORM_FIELD_GDPR_SETTINGS_UPDATE,
    FORM_FIELD_DELETE,
    FORM_FIELD_COPY,
    FORM_FIELD_MOVE,
    FORM_FIELD_UP,
    FORM_FIELD_DOWN,
    FORM_POPUP_ADD,
    FORM_POPUP_UPDATE,
    FORM_POPUP_DELETE,
    FORM_POPUP_COPY,
    FORM_POPUP_MOVE,
    FORM_POPUP_UP,
    FORM_POPUP_DOWN,
    FORM_FIELD_POPUP_ADD,
    FORM_UPLOAD_LOGO,
    FORM_LOGO_GET,
    FORM_LOGO_DELETE,
    FORM_FILTERS_GET,
    FORM_FILTERS_CLEAR,
    FORM_TAG_LIST,
    FORM_TAG_CREATE,
    FORM_TAG_UPDATE,
    FORM_TAG_DELETE,
    FORM_ORGANIZATION_RELATE,
    GET_FORM_SNIPPET,
    GET_FORM_SNIPPET_CODE,
    GET_FORM_SNIPPET_LOGO,
    UPDATE_FORM_SNIPPET,
    UPDATE_FORM_WITH_LOGO_SNIPPET,
    DELETE_FORM_SNIPPET_LOGO,
    FORM_FIELD_CREATE_OPTION,
    FORM_FIELD_UPDATE_OPTION,
    FORM_FIELD_DELETE_OPTION,
    FORM_FIELD_IMPORT_OPTIONS,
    FORM_ALLOW_LIST_CREATE,
    FORM_ALLOW_LIST_DELETE,
    FORM_HAS_ADDITIONAL_CREATE,
    FORM_HAS_ADDITIONAL_DELETE,
    FORM_HAS_ADDITIONAL_APPEND,
    FORM_WORKFLOW_GET_STATUSES,
    FORM_WORKFLOW_UPDATE,
    FORM_FIELD_ADD_RELATED_SECTION,
    FORM_FIELD_REMOVE_RELATED_SECTION,
    FORMS_LIST_FOR_REQUESTER,
    FORM_FAQ_LIST,
    FORM_FAQ_CREATE,
    FORM_FAQ_UPDATE,
    FORM_FAQ_DELETE,
    FORM_FAQ_PRIORITY,
    FORM_VERSION_CARD_CREATE,
    FORM_VERSION_CARD_DELETE,
    FORM_VERSION_CARD_FIELD_CREATE,
    FORM_VERSION_CARD_FIELD_UPDATE,
    FORM_VERSION_CARD_FIELD_DELETE,
    FORM_VERSION_CARD_GET,
    FORM_VERSION_CARDS_GET_ALL,
    FORM_VERSION_CARD_UPDATE,
} from 'appRedux/actions/forms';
import {
    GET_ROLES_LIST,
    GET_ROLES_LIST_OTHER_ORGANIZATION,
    CREATE_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    TOGGLE_ROLE_STATUS,
    GET_ROLE_INFORMATION,
} from 'appRedux/actions/roles';
import {RoleTypes} from 'appRedux/actions/roles/types';
import {RULE_CREATE, GET_RULES_LIST} from 'appRedux/actions/rules';
import {RuleTypes} from 'appRedux/actions/rules/types';
import {FormVersionTypes, FormWorkflowStatusTypes} from 'appRedux/actions/forms/types';
import {BoardAlphabetResponseType} from 'appRedux/actions/admin/types';
import {INVITES_GET_LIST, INVITE_CREATE, INVITE_DELETE, INVITE_SEND} from 'appRedux/actions/invites';
import {InviteTypes} from 'appRedux/actions/invites/types';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';

import {getMergedItems} from 'pages/agent/listView/helper';

import {getMergedCases} from 'helpers/itemsHelper';

export const initialState: AdminState = {
    isLoading: false,
    isLoadingCasesList: false,
    agentTemplate: null,
    savedFilters: [],
    organizationList: [],
    organizationPrivacyPolicies: [],
    organizationQuotas: [],
    organizationAdminList: [],
    agentList: [],
    agentCasesActivitiesList: [],
    invites: [],
    formsList: [],
    formsListForRequester: [],
    requesterCasesNumber: 0,
    requesterCasesList: [],
    requesterCasesColumns: {},
    requesterCasesSwimlanes: {},
    requesterCasesAlphabetList: {},
    pinnedRequesterCasesNumber: 0,
    pinnedRequesterCasesList: [],
    casesWaitingApproveList: [],
    requestersList: {
        count: undefined,
        data: [],
    },
    formInfo: {
        languages: [],
        pages: [],
        versions: [],
        translations: [],
        tags: [],
        additionalForms: [],
        statuses: [],
        emails: [],
        submitMessage: null,
        snippet: null,
        card: null,
    },
    formFieldFilters: {
        formId: null,
        filters: [],
        tags: [],
        translations: [],
    },
    formWorkflowStatuses: [],
    formVersionStatuses: [],
    formFaq: [],
    languageList: [],
    roles: [],
    rolesOtherOrganization: [],
    currentRole: undefined,
    rules: [],
    organizationFormFilters: {
        filters: [],
        tags: [],
        translations: [],
    },
    organizationTemplates: [],
    organizationTranslations: [],
    organizationLanguages: [],
    organizationIntegrationTokens: [],
    organizationPdfTemplates: [],
    organizationCards: [],
    error: undefined,
};

export interface AdminState {
    isLoading: boolean;
    isLoadingCasesList: boolean;
    agentTemplate: AgentTemplateType | null;
    savedFilters: AgentSavedFilterTypes[];
    organizationList: OrganizationItemBasic[];
    organizationPrivacyPolicies: OrganizationPrivacyPolicyItemTypes[];
    organizationQuotas: OrganizationQuotasItemBasic[];
    organizationAdminList: OrganizationAdminListTypes[];
    agentList: AgentItemBasic[];
    agentCasesActivitiesList: RequesterCaseActivitiesTypes[];
    invites: InviteTypes[];
    formsList: FormItemBasic[];
    formsListForRequester: FormForRequesterItemBasic[];
    requesterCasesNumber: number;
    requesterCasesList: RequesterCaseResponseTypes[];
    requesterCasesColumns: BoardResponseType;
    requesterCasesSwimlanes: SwimlaneBoardResponseType;
    requesterCasesAlphabetList: BoardAlphabetResponseType;
    pinnedRequesterCasesNumber: number;
    pinnedRequesterCasesList: RequesterCaseResponseTypes[];
    casesWaitingApproveList: RequesterCaseResponseTypes[];
    requestersList: RequesterResponseTypes;
    formInfo: {
        id?: number;
        defaultLanguage?: number;
        workflow?: number;
        languages: FormHasLanguageTypes[];
        pages: FormPageTypes[];
        versions: FormVersionTypes[];
        translations: FormTranslationItemTypes[];
        tags: FormTagType[];
        additionalForms: FormHasAdditionalTypes[];
        emails: FormAllowListTypes[];
        statuses: FormWorkflowStatusTypes[];
        submitMessage: string | null;
        snippet: FormSnippetType | null;
        card: FormVersionCardType | null;
    };
    formFieldFilters: {
        formId: number | null;
        filters: FormFieldFilterType[];
        tags: FormTagType[];
        translations: FormTranslationItemTypes[];
    };
    formFaq: FormFaqItemTypes[];
    formWorkflowStatuses: CurrentFormWorkflowStatusesTypes[];
    formVersionStatuses: FormWorkflowStatusTypes[];
    roles: RoleTypes[];
    rolesOtherOrganization: RoleTypes[];
    currentRole: RoleTypes | undefined;
    rules: RuleTypes[];
    languageList: FormLanguageTypes[];
    organizationFormFilters: {
        filters: FormFieldFilterType[];
        tags: FormTagType[];
        translations: FormTranslationItemTypes[];
    };
    organizationTemplates: OrganizationTemplateItemTypes[];
    organizationLanguages: OrganizationLanguageTypes[];
    organizationTranslations: OrganizationTranslationTypes[];
    organizationIntegrationTokens: OrganizationIntegrationTokenTypes[];
    organizationPdfTemplates: OrganizationPdfTemplatesTypes[];
    organizationCards: FormVersionCardType[];
    error: IErrors | undefined;
}

const admin = (
    state = initialState,
    action: IActionType<
        | OrganizationItemBasic[]
        | OrganizationQuotasItemBasic[]
        | OrganizationAdminListTypes[]
        | OrganizationPrivacyPolicyItemTypes[]
        | OrganizationIntegrationTokenTypes[]
        | AgentTemplateType
        | AgentItemBasic[]
        | FormItemBasic[]
        | FormForRequesterItemBasic[]
        | FormVersionTypes[]
        | FormTranslationItemTypes[]
        | FormTotalInformationTypes
        | FormFieldFilterResponseType
        | FormPageTypes[]
        | FormHasLanguageTypes[]
        | FormFaqItemTypes[]
        | RequesterCaseResponseTypes[]
        | RequesterItemBasic[]
        | FormTagType[]
        | FormHasAdditionalTypes[]
        | RoleTypes[]
        | RoleTypes
        | RuleTypes[]
        | AgentSavedFilterTypes[]
        | InviteTypes[]
        | RequesterCaseActivitiesTypes[]
        | FormWorkflowStatusTypes[]
        | FormAllowListTypes[]
        | OrganizationTemplateItemTypes[]
        | OrganizationLanguageTypes[]
        | OrganizationTranslationTypes[]
        | CurrentFormWorkflowStatusesTypes[]
        | OrganizationPdfTemplatesTypes[]
        | BoardResponseType
        | SwimlaneBoardResponseType
        | BoardAlphabetResponseType
        | BoardItemsResponseType
        | FormSnippetType
        | ListItemsRequestTypesResponseTypes
        | ListUpdatedItemsRequestTypesResponseTypes
        | FormVersionCardType
        | FormVersionCardType[]
        | IErrors
    >,
): AdminState => {
    switch (action.type) {
        case INVITE_SEND.REQUEST:
        case INVITE_CREATE.REQUEST:
        case INVITE_DELETE.REQUEST:
        case INVITES_GET_LIST.REQUEST:
        case DELETE_ORGANIZATION.REQUEST:
        case CHANGE_ORGANIZATION_STATUS.REQUEST:
        case CREATE_ORGANIZATION.REQUEST:
        case AGENT_TEMPLATE_GET.REQUEST:
        case AGENT_TEMPLATE_UPDATE.REQUEST:
        case AGENT_FILTERS_LIST.REQUEST:
        case AGENT_FILTER_TAGS_LIST.REQUEST:
        case AGENT_FILTERS_LIST_CREATE.REQUEST:
        case AGENT_FILTERS_LIST_UPDATE.REQUEST:
        case AGENT_FILTERS_LIST_DELETE.REQUEST:
        case ORGANIZATION_LIST.REQUEST:
        case GET_ORGANIZATIONS_STATISTIC.REQUEST:
        case SEND_ORGANIZATIONS_STATISTIC.REQUEST:
        case UPDATE_ORGANIZATION.REQUEST:
        case ORGANIZATION_CHANGE_DOMAIN.REQUEST:
        case ORGANIZATION_MULTIPLE_STATUS_CHANGING.REQUEST:
        case ORGANIZATION_ADMIN_LIST.REQUEST:
        case ORGANIZATION_ADMIN_CREATE.REQUEST:
        case ORGANIZATION_ADMIN_INVITE.REQUEST:
        case ORGANIZATION_ADMIN_UPDATE.REQUEST:
        case ORGANIZATION_ADMIN_PASSWORD_RESET.REQUEST:
        case ORGANIZATION_ADMIN_UNLOCK.REQUEST:
        case ORGANIZATION_ADMIN_DELETE.REQUEST:
        case ORGANIZATION_ADMIN_2FA_DEACTIVATE.REQUEST:
        case ORGANIZATION_ADMIN_UPDATE_ROLES.REQUEST:
        case ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.REQUEST:
        case ORGANIZATION_AGENT_REASSIGN.REQUEST:
        case TOGGLE_TWO_FACTOR_ORGANIZATION.REQUEST:
        case ORGANIZATION_QUOTAS_LIST.REQUEST:
        case CREATE_ORGANIZATION_QUOTA.REQUEST:
        case UPDATE_ORGANIZATION_QUOTA.REQUEST:
        case DELETE_ORGANIZATION_QUOTA.REQUEST:
        case TOGGLE_ORGANIZATION_QUOTA_HARD.REQUEST:
        case ORGANIZATION_UPLOAD_LOGO.REQUEST:
        case ORGANIZATION_UPLOAD_FAVICON.REQUEST:
        case ORGANIZATION_LOGO_DELETE.REQUEST:
        case ORGANIZATION_FAVICON_DELETE.REQUEST:
        case ORGANIZATION_LOGO_GET.REQUEST:
        case CREATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST:
        case UPDATE_ORGANIZATION_SSO_CREDENTIALS.REQUEST:
        case UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.REQUEST:
        case ORGANIZATION_TEMPLATES_LIST.REQUEST:
        case ORGANIZATION_TEMPLATE_CREATE.REQUEST:
        case ORGANIZATION_TEMPLATE_UPDATE.REQUEST:
        case GET_ORGANIZATION_LANGUAGES_LIST.REQUEST:
        case ORGANIZATION_LANGUAGE_ADD.REQUEST:
        case ORGANIZATION_LANGUAGE_ACTIVATE.REQUEST:
        case ORGANIZATION_LANGUAGE_REMOVE.REQUEST:
        case ORGANIZATION_TRANSLATIONS_LIST.REQUEST:
        case ORGANIZATION_TRANSLATIONS_UPDATE.REQUEST:
        case ORGANIZATION_PRIVACY_POLICY_LIST.REQUEST:
        case CREATE_ORGANIZATION_PRIVACY_POLICY.REQUEST:
        case UPDATE_ORGANIZATION_PRIVACY_POLICY.REQUEST:
        case TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.REQUEST:
        case CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.REQUEST:
        case ORGANIZATION_INTEGRATION_TOKENS_LIST.REQUEST:
        case ORGANIZATION_INTEGRATION_TOKEN_CREATE.REQUEST:
        case ORGANIZATION_INTEGRATION_TOKEN_UPDATE.REQUEST:
        case ORGANIZATION_INTEGRATION_TOKEN_DELETE.REQUEST:
        case AGENT_LIST.REQUEST:
        case CASES_ACTIVITIES_LIST.REQUEST:
        case FORMS_LIST.REQUEST:
        case FORMS_LIST_FOR_REQUESTER.REQUEST:
        case FORM_CHANGE_STATUS.REQUEST:
        case FORM_DELETE.REQUEST:
        case FORMS_COPY.REQUEST:
        case FORM_CREATE.REQUEST:
        case FORM_UPDATE.REQUEST:
        case FORM_PAUSE_ON.REQUEST:
        case FORM_PAUSE_OFF.REQUEST:
        case FORM_PAGE_UP.REQUEST:
        case FORM_PAGE_DOWN.REQUEST:
        case FORM_MULTIPLE_STATUS_CHANGING.REQUEST:
        case FORM_INFORMATION.REQUEST:
        case FORM_BY_CASE_INFORMATION.REQUEST:
        case FORM_VERSION_STATUSES.REQUEST:
        case FORM_VERSION_STRUCTURE.REQUEST:
        case CREATE_FORM_VERSION.REQUEST:
        case UPDATE_FORM_VERSION.REQUEST:
        case DEACTIVATE_FORM_VERSION.REQUEST:
        case DELETE_FORM_VERSION.REQUEST:
        case FORM_VERSION_CARD_CREATE.REQUEST:
        case FORM_VERSION_CARD_UPDATE.REQUEST:
        case FORM_VERSION_CARD_DELETE.REQUEST:
        case FORM_VERSION_CARD_GET.REQUEST:
        case FORM_VERSION_CARDS_GET_ALL.REQUEST:
        case FORM_VERSION_CARD_FIELD_CREATE.REQUEST:
        case FORM_VERSION_CARD_FIELD_UPDATE.REQUEST:
        case FORM_VERSION_CARD_FIELD_DELETE.REQUEST:
        case FORM_FILTERS_GET.REQUEST:
        case FORM_LANGUAGE_ADD.REQUEST:
        case FORM_LANGUAGE_STATUS.REQUEST:
        case FORM_LANGUAGE_DELETE.REQUEST:
        case FORM_TRANSLATIONS.REQUEST:
        case FORM_MAIN_TRANSLATION_UPDATE.REQUEST:
        case FORM_TRANSLATION_UPDATE.REQUEST:
        case FORM_TRANSLATION_EXPORT.REQUEST:
        case FORM_TRANSLATION_IMPORT.REQUEST:
        case FORM_PAGE_ADD.REQUEST:
        case FORM_PAGE_UPDATE.REQUEST:
        case FORM_PAGE_DELETE.REQUEST:
        case FORM_PAGE_COPY.REQUEST:
        case FORM_SECTION_ADD.REQUEST:
        case FORM_SECTION_UPDATE.REQUEST:
        case FORM_SECTION_DELETE.REQUEST:
        case FORM_SECTION_COPY.REQUEST:
        case FORM_SECTION_MOVE.REQUEST:
        case FORM_SECTION_UP.REQUEST:
        case FORM_SECTION_DOWN.REQUEST:
        case FORM_SECTION_RECALCULATE_PRIORITIES.REQUEST:
        case FORM_FIELD_ADD.REQUEST:
        case FORM_FIELD_UPDATE.REQUEST:
        case FORM_FIELD_GDPR_SETTINGS_UPDATE.REQUEST:
        case FORM_FIELD_DELETE.REQUEST:
        case FORM_FIELD_COPY.REQUEST:
        case FORM_FIELD_MOVE.REQUEST:
        case FORM_FIELD_UP.REQUEST:
        case FORM_FIELD_DOWN.REQUEST:
        case FORM_FIELD_ADD_RELATED_SECTION.REQUEST:
        case FORM_FIELD_REMOVE_RELATED_SECTION.REQUEST:
        case FORM_POPUP_ADD.REQUEST:
        case FORM_POPUP_UPDATE.REQUEST:
        case FORM_POPUP_DELETE.REQUEST:
        case FORM_POPUP_COPY.REQUEST:
        case FORM_POPUP_MOVE.REQUEST:
        case FORM_POPUP_UP.REQUEST:
        case FORM_POPUP_DOWN.REQUEST:
        case FORM_FIELD_POPUP_ADD.REQUEST:
        case FORM_UPLOAD_LOGO.REQUEST:
        case FORM_LOGO_GET.REQUEST:
        case FORM_LOGO_DELETE.REQUEST:
        case FORM_TAG_LIST.REQUEST:
        case FORM_TAG_CREATE.REQUEST:
        case FORM_TAG_UPDATE.REQUEST:
        case FORM_TAG_DELETE.REQUEST:
        case FORM_HAS_ADDITIONAL_CREATE.REQUEST:
        case FORM_HAS_ADDITIONAL_DELETE.REQUEST:
        case FORM_HAS_ADDITIONAL_APPEND.REQUEST:
        case FORM_ALLOW_LIST_CREATE.REQUEST:
        case FORM_ALLOW_LIST_DELETE.REQUEST:
        case FORM_FAQ_LIST.REQUEST:
        case FORM_FAQ_CREATE.REQUEST:
        case FORM_FAQ_UPDATE.REQUEST:
        case FORM_FAQ_DELETE.REQUEST:
        case FORM_FAQ_PRIORITY.REQUEST:
        case GET_FORM_SNIPPET.REQUEST:
        case GET_FORM_SNIPPET_CODE.REQUEST:
        case GET_FORM_SNIPPET_LOGO.REQUEST:
        case DELETE_FORM_SNIPPET_LOGO.REQUEST:
        case UPDATE_FORM_SNIPPET.REQUEST:
        case UPDATE_FORM_WITH_LOGO_SNIPPET.REQUEST:
        case FORM_ORGANIZATION_RELATE.REQUEST:
        case GET_RULES_LIST.REQUEST:
        case RULE_CREATE.REQUEST:
        case GET_ROLES_LIST.REQUEST:
        case GET_ROLES_LIST_OTHER_ORGANIZATION.REQUEST:
        case GET_ROLE_INFORMATION.REQUEST:
        case CREATE_ROLE.REQUEST:
        case UPDATE_ROLE.REQUEST:
        case DELETE_ROLE.REQUEST:
        case TOGGLE_ROLE_STATUS.REQUEST:
        case LANGUAGE_LIST.REQUEST:
        case TOGGLE_LANGUAGE.REQUEST:
        case FORM_WORKFLOW_GET_STATUSES.REQUEST:
        case FORM_WORKFLOW_UPDATE.REQUEST:
        case FORM_FIELD_CREATE_OPTION.REQUEST:
        case FORM_FIELD_UPDATE_OPTION.REQUEST:
        case FORM_FIELD_DELETE_OPTION.REQUEST:
        case FORM_FIELD_IMPORT_OPTIONS.REQUEST:
        case ORGANIZATION_PDF_TEMPLATES_LIST.REQUEST:
        case CREATE_ORGANIZATION_PDF_TEMPLATE.REQUEST:
        case UPDATE_ORGANIZATION_PDF_TEMPLATE.REQUEST:
        case DELETE_ORGANIZATION_PDF_TEMPLATE.REQUEST:
        case UPLOAD_ORGANIZATION_PDF_ATTACHMENT.REQUEST:
        case DELETE_ORGANIZATION_PDF_ATTACHMENT.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case GET_PINNED_CASES.REQUEST:
        case GET_WAITING_APPROVE_CASES.REQUEST:
        case REQUEST_CASE_EXPORT.REQUEST:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.REQUEST:
        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.REQUEST:
        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.REQUEST:
        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.REQUEST:
        case REQUEST_CASE_EXPORT_MULTIPLE.REQUEST:
        case REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_COLUMN:
        case REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD:
        case REQUESTER_CASES_LIST.REQUEST_SWIMLANE_BOARD:
        case REQUESTER_CASES_LIST.REQUEST_LOAD_SWIMLANE:
        case REQUESTER_CASES_LIST.REQUEST_CUSTOM_BOARD_OVERVIEW:
        case REQUESTER_CASES_LIST.REQUEST_BOARD_COLUMN:
        case REQUEST_CASE_DRAG_AND_DROP.REQUEST:
        case REQUEST_CASE_CREATE_STACK.REQUEST:
        case REQUEST_CASE_DELETE_STACK.REQUEST: {
            return {
                ...state,
                isLoadingCasesList: true,
            };
        }

        case AGENT_FILTERS_LIST.SUCCESS:
        case AGENT_FILTERS_LIST_CREATE.SUCCESS:
        case AGENT_FILTERS_LIST_UPDATE.SUCCESS:
        case AGENT_FILTERS_LIST_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                savedFilters: action.payload as AgentSavedFilterTypes[],
                error: undefined,
            };
        }

        case AGENT_FILTER_TAGS_LIST.SUCCESS: {
            return {
                ...state,
                organizationFormFilters: action.payload as FormFieldFilterResponseType,
                isLoading: false,
                error: undefined,
            };
        }

        case ORGANIZATION_UPLOAD_LOGO.SUCCESS:
        case ORGANIZATION_UPLOAD_FAVICON.SUCCESS:
        case ORGANIZATION_LOGO_DELETE.SUCCESS:
        case ORGANIZATION_FAVICON_DELETE.SUCCESS:
        case CHANGE_ORGANIZATION_STATUS.SUCCESS:
        case ORGANIZATION_MULTIPLE_STATUS_CHANGING.SUCCESS:
        case DELETE_ORGANIZATION.SUCCESS:
        case CREATE_ORGANIZATION.SUCCESS:
        case UPDATE_ORGANIZATION.SUCCESS:
        case TOGGLE_TWO_FACTOR_ORGANIZATION.SUCCESS:
        case ORGANIZATION_CHANGE_DOMAIN.SUCCESS:
        case ORGANIZATION_LIST.SUCCESS:
        case CREATE_ORGANIZATION_SSO_CREDENTIALS.SUCCESS:
        case UPDATE_ORGANIZATION_SSO_CREDENTIALS.SUCCESS:
        case UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationList: action.payload as OrganizationItemBasic[],
                error: undefined,
            };
        }

        case ORGANIZATION_ADMIN_LIST.SUCCESS:
        case ORGANIZATION_ADMIN_CREATE.SUCCESS:
        case ORGANIZATION_ADMIN_INVITE.SUCCESS:
        case ORGANIZATION_ADMIN_UPDATE.SUCCESS:
        case ORGANIZATION_ADMIN_PASSWORD_RESET.SUCCESS:
        case ORGANIZATION_ADMIN_UNLOCK.SUCCESS:
        case ORGANIZATION_ADMIN_DELETE.SUCCESS:
        case ORGANIZATION_ADMIN_2FA_DEACTIVATE.SUCCESS:
        case ORGANIZATION_ADMIN_UPDATE_ROLES.SUCCESS:
        case ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.SUCCESS:
        case ORGANIZATION_AGENT_REASSIGN.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationAdminList: action.payload as OrganizationAdminListTypes[],
                error: undefined,
            };
        }

        case ORGANIZATION_PRIVACY_POLICY_LIST.SUCCESS:
        case CREATE_ORGANIZATION_PRIVACY_POLICY.SUCCESS:
        case UPDATE_ORGANIZATION_PRIVACY_POLICY.SUCCESS:
        case TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.SUCCESS:
        case CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationPrivacyPolicies: action.payload as OrganizationPrivacyPolicyItemTypes[],
                error: undefined,
            };
        }

        case ORGANIZATION_QUOTAS_LIST.SUCCESS:
        case CREATE_ORGANIZATION_QUOTA.SUCCESS:
        case UPDATE_ORGANIZATION_QUOTA.SUCCESS:
        case DELETE_ORGANIZATION_QUOTA.SUCCESS:
        case TOGGLE_ORGANIZATION_QUOTA_HARD.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationQuotas: action.payload as OrganizationQuotasItemBasic[],
                error: undefined,
            };
        }

        case AGENT_TEMPLATE_GET.SUCCESS:
        case AGENT_TEMPLATE_UPDATE.SUCCESS:
            return {
                ...state,
                isLoading: false,
                agentTemplate: action.payload as AgentTemplateType,
                error: undefined,
            };

        case AGENT_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agentList: action.payload as AgentItemBasic[],
                error: undefined,
            };
        }

        case FORMS_LIST.SUCCESS:
        case FORM_MULTIPLE_STATUS_CHANGING.SUCCESS:
        case FORM_CHANGE_STATUS.SUCCESS:
        case FORMS_COPY.SUCCESS:
        case FORM_CREATE.SUCCESS:
        case FORM_UPDATE.SUCCESS:
        case FORM_PAUSE_ON.SUCCESS:
        case FORM_PAUSE_OFF.SUCCESS:
        case FORM_DELETE.SUCCESS:
        case FORM_LOGO_DELETE.SUCCESS:
        case FORM_ORGANIZATION_RELATE.SUCCESS:
        case FORM_UPLOAD_LOGO.SUCCESS:
        case FORM_WORKFLOW_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formsList: action.payload as FormItemBasic[],
                error: undefined,
            };
        }

        case FORMS_LIST_FOR_REQUESTER.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formsListForRequester: action.payload as FormForRequesterItemBasic[],
                error: undefined,
            };
        }

        case FORM_FILTERS_GET.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formFieldFilters: action.payload as FormFieldFilterResponseType,
                error: undefined,
            };
        }

        case FORM_FILTERS_CLEAR.REQUEST: {
            return {
                ...state,
                isLoading: false,
                formFieldFilters: {
                    formId: null,
                    filters: [],
                    tags: [],
                    translations: [],
                },
                error: undefined,
            };
        }

        case ORGANIZATION_LOGO_GET.SUCCESS:
        case GET_FORM_SNIPPET_LOGO.SUCCESS:
        case FORM_LOGO_GET.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case FORM_BY_CASE_INFORMATION.SUCCESS:
        case FORM_INFORMATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    ...(action.payload as FormTotalInformationTypes),
                },
                error: undefined,
            };
        }

        case FORM_LANGUAGE_ADD.SUCCESS:
        case FORM_LANGUAGE_DELETE.SUCCESS:
        case FORM_LANGUAGE_STATUS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    languages: action.payload as FormHasLanguageTypes[],
                },
                error: undefined,
            };
        }

        case FORM_TAG_LIST.SUCCESS:
        case FORM_TAG_CREATE.SUCCESS:
        case FORM_TAG_UPDATE.SUCCESS:
        case FORM_TAG_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    tags: action.payload as FormTagType[],
                },
                error: undefined,
            };
        }

        case FORM_HAS_ADDITIONAL_CREATE.SUCCESS:
        case FORM_HAS_ADDITIONAL_APPEND.SUCCESS:
        case FORM_HAS_ADDITIONAL_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    additionalForms: action.payload as FormHasAdditionalTypes[],
                },
                error: undefined,
            };
        }

        case FORM_ALLOW_LIST_CREATE.SUCCESS:
        case FORM_ALLOW_LIST_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    emails: action.payload as FormAllowListTypes[],
                },
                error: undefined,
            };
        }

        case GET_FORM_SNIPPET.SUCCESS:
        case UPDATE_FORM_SNIPPET.SUCCESS:
        case DELETE_FORM_SNIPPET_LOGO.SUCCESS:
        case UPDATE_FORM_WITH_LOGO_SNIPPET.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    snippet: action.payload as FormSnippetType | null,
                },
                error: undefined,
            };
        }

        case FORM_VERSION_CARD_CREATE.SUCCESS:
        case FORM_VERSION_CARD_UPDATE.SUCCESS:
        case FORM_VERSION_CARD_DELETE.SUCCESS:
        case FORM_VERSION_CARD_GET.SUCCESS:
        case FORM_VERSION_CARD_FIELD_CREATE.SUCCESS:
        case FORM_VERSION_CARD_FIELD_UPDATE.SUCCESS:
        case FORM_VERSION_CARD_FIELD_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    card: (action.payload as FormVersionCardType | undefined) || null,
                },
                error: undefined,
            };
        }

        case FORM_VERSION_CARDS_GET_ALL.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationCards: (action.payload as FormVersionCardType[]) || [],
                error: undefined,
            };
        }

        case FORM_MAIN_TRANSLATION_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    languages: get(action, ['payload', 'languages'], []),
                    translations: get(action, ['payload', 'translations'], []),
                },
                error: undefined,
            };
        }

        case FORM_TRANSLATION_UPDATE.SUCCESS:
        case FORM_TRANSLATIONS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    translations: action.payload as FormTranslationItemTypes[],
                },
                error: undefined,
            };
        }

        case CREATE_FORM_VERSION.SUCCESS:
        case UPDATE_FORM_VERSION.SUCCESS:
        case DEACTIVATE_FORM_VERSION.SUCCESS:
        case DELETE_FORM_VERSION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    versions: action.payload as FormVersionTypes[],
                },
                error: undefined,
            };
        }

        case FORM_VERSION_STATUSES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formVersionStatuses: action.payload as FormWorkflowStatusTypes[],
                error: undefined,
            };
        }

        case FORM_VERSION_STRUCTURE.SUCCESS:
        case FORM_PAGE_ADD.SUCCESS:
        case FORM_PAGE_UPDATE.SUCCESS:
        case FORM_PAGE_COPY.SUCCESS:
        case FORM_PAGE_DELETE.SUCCESS:
        case FORM_PAGE_UP.SUCCESS:
        case FORM_PAGE_DOWN.SUCCESS:
        case FORM_SECTION_ADD.SUCCESS:
        case FORM_SECTION_UPDATE.SUCCESS:
        case FORM_SECTION_DELETE.SUCCESS:
        case FORM_SECTION_COPY.SUCCESS:
        case FORM_SECTION_MOVE.SUCCESS:
        case FORM_SECTION_UP.SUCCESS:
        case FORM_SECTION_DOWN.SUCCESS:
        case FORM_SECTION_RECALCULATE_PRIORITIES.SUCCESS:
        case FORM_FIELD_ADD.SUCCESS:
        case FORM_FIELD_UPDATE.SUCCESS:
        case FORM_FIELD_GDPR_SETTINGS_UPDATE.SUCCESS:
        case FORM_FIELD_DELETE.SUCCESS:
        case FORM_FIELD_MOVE.SUCCESS:
        case FORM_FIELD_UP.SUCCESS:
        case FORM_FIELD_DOWN.SUCCESS:
        case FORM_FIELD_ADD_RELATED_SECTION.SUCCESS:
        case FORM_FIELD_REMOVE_RELATED_SECTION.SUCCESS:
        case FORM_FIELD_COPY.SUCCESS:
        case FORM_POPUP_ADD.SUCCESS:
        case FORM_POPUP_UPDATE.SUCCESS:
        case FORM_POPUP_DELETE.SUCCESS:
        case FORM_POPUP_COPY.SUCCESS:
        case FORM_POPUP_MOVE.SUCCESS:
        case FORM_POPUP_UP.SUCCESS:
        case FORM_POPUP_DOWN.SUCCESS:
        case FORM_FIELD_POPUP_ADD.SUCCESS:
        case FORM_FIELD_CREATE_OPTION.SUCCESS:
        case FORM_FIELD_UPDATE_OPTION.SUCCESS:
        case FORM_FIELD_DELETE_OPTION.SUCCESS:
        case FORM_FIELD_IMPORT_OPTIONS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formInfo: {
                    ...state.formInfo,
                    pages: action.payload as FormPageTypes[],
                },
                error: undefined,
            };
        }

        case FORM_FAQ_LIST.SUCCESS:
        case FORM_FAQ_CREATE.SUCCESS:
        case FORM_FAQ_UPDATE.SUCCESS:
        case FORM_FAQ_DELETE.SUCCESS:
        case FORM_FAQ_PRIORITY.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formFaq: action.payload as FormFaqItemTypes[],
                error: undefined,
            };
        }

        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS:
        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS:
        case REQUEST_CASE_CREATE_STACK.SUCCESS:
        case REQUEST_CASE_DELETE_STACK.SUCCESS:
        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS: {
            const payload = action.payload as ListItemsRequestTypesResponseTypes;
            const itemsNumber = payload.count;
            const items = payload.results;
            if (Number(itemsNumber) > 0 && items.length === 0) {
                return {
                    ...state,
                    isLoadingCasesList: false,
                };
            }

            const previousCount = state.requesterCasesNumber ? state.requesterCasesNumber : 0;
            const itemsNumberReduced = Number(itemsNumber) < previousCount;
            const currentItems = state.requesterCasesList ? state.requesterCasesList : [];

            return {
                ...state,
                isLoadingCasesList: false,
                requesterCasesNumber: Number(itemsNumber),
                requesterCasesList: items.length > 0 ? getMergedCases(currentItems, items, itemsNumberReduced) : [],
                requesterCasesColumns: (action.payload as BoardResponseType) || {},
            };
        }

        case REQUEST_CASE_DRAG_AND_DROP.SUCCESS:
        case REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD:
        case REQUEST_CASE_CREATE_STACK.SUCCESS_CUSTOM_BOARD:
        case REQUEST_CASE_DELETE_STACK.SUCCESS_CUSTOM_BOARD: {
            const itemsNumber: number = Object.values(action.payload as BoardResponseType).reduce((acc, item) => {
                if (item.count) acc += item.count;
                return acc;
            }, 0);
            const items: RequesterCaseResponseTypes[] = Object.values(action.payload).reduce((acc, item) => {
                item.results && acc.push(...item.results);
                return acc;
            }, []);

            return {
                ...state,
                isLoadingCasesList: false,
                requesterCasesNumber: Number(itemsNumber),
                requesterCasesList: items || [],
                requesterCasesColumns: (action.payload as BoardResponseType) || {},
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_SWIMLANE_BOARD:
        case REQUEST_CASE_CREATE_STACK.SUCCESS_SWIMLANE_BOARD:
        case REQUEST_CASE_DELETE_STACK.SUCCESS_SWIMLANE_BOARD: {
            const itemsNumber: number = Object.values(action.payload as SwimlaneBoardResponseType).reduce(
                (acc, item) => {
                    if (item?.swimlaneInfo?.qty) acc += item.swimlaneInfo.qty;
                    return acc;
                },
                0,
            );
            const items: RequesterCaseResponseTypes[] = Object.values(
                action.payload as SwimlaneBoardResponseType,
            ).reduce((acc: RequesterCaseResponseTypes[], item) => {
                if (item.cases) {
                    Object.values(item.cases).forEach(item => {
                        item.results && acc.push(...item.results);
                    });
                }
                return acc;
            }, []);

            return {
                ...state,
                isLoadingCasesList: false,
                requesterCasesNumber: Number(itemsNumber),
                requesterCasesList: items || [],
                requesterCasesSwimlanes: (action.payload as SwimlaneBoardResponseType) || {},
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_LOAD_SWIMLANE: {
            const requesterCasesSwimlanes = {
                ...state.requesterCasesSwimlanes,
                ...(action.payload as SwimlaneBoardResponseType),
            };

            const itemsNumber: number = Object.values(requesterCasesSwimlanes).reduce((acc, item) => {
                if (item?.swimlaneInfo?.qty) acc += item.swimlaneInfo.qty;
                return acc;
            }, 0);
            const items: RequesterCaseResponseTypes[] = Object.values(requesterCasesSwimlanes).reduce(
                (acc: RequesterCaseResponseTypes[], item) => {
                    if (item.cases) {
                        Object.values(item.cases).forEach(item => {
                            item.results && acc.push(...item.results);
                        });
                    }
                    return acc;
                },
                [],
            );

            return {
                ...state,
                isLoadingCasesList: false,
                requesterCasesNumber: Number(itemsNumber),
                requesterCasesList: items || [],
                requesterCasesSwimlanes: requesterCasesSwimlanes,
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_ALPHABET: {
            const payload = Object.values(action.payload as BoardAlphabetResponseType);
            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: get(payload, [0, 'count'], 0),
                requesterCasesAlphabetList: (action.payload as BoardAlphabetResponseType) || {},
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_OVERVIEW: {
            const payload = action.payload as BoardItemsResponseType;
            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: payload.count,
                requesterCasesList: payload.results,
            };
        }

        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_ALPHABET_LIST:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_ALPHABET_LIST:
        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_ALPHABET_LIST:
        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_ALPHABET_LIST: {
            const newPayload = Object.values(action.payload as BoardAlphabetResponseType);
            const keys = Object.keys(action.payload as BoardAlphabetResponseType);
            const boardUuid = keys.length > 0 ? keys[0] : null;
            const newItems = get(newPayload, [0, 'results'], {});
            if (boardUuid) {
                return {
                    ...state,
                    isLoading: false,
                    requesterCasesNumber: get(newPayload, [0, 'count'], 0),
                    requesterCasesAlphabetList: {
                        [boardUuid]: {
                            count: get(newPayload, [0, 'count'], 0),
                            results: newItems,
                        },
                    },
                };
            }
            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: get(newPayload, [0, 'count'], 0),
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_ALPHABET_UPDATE: {
            const currentPayload = Object.values(state.requesterCasesAlphabetList as BoardAlphabetResponseType);
            const newPayload = Object.values(action.payload as BoardAlphabetResponseType);
            const keys = Object.keys(action.payload as BoardAlphabetResponseType);
            const boardUuid = keys.length > 0 ? keys[0] : null;
            const currentItems = get(currentPayload, [0, 'results'], {});
            const newItems = get(newPayload, [0, 'results'], {});
            const mergedItems = getMergedItems(currentItems, newItems);
            if (boardUuid) {
                return {
                    ...state,
                    isLoading: false,
                    requesterCasesNumber: get(newPayload, [0, 'count'], 0),
                    requesterCasesAlphabetList: {
                        [boardUuid]: {
                            count: get(newPayload, [0, 'count'], 0),
                            results: mergedItems,
                        },
                    },
                };
            }
            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: get(newPayload, [0, 'count'], 0),
            };
        }

        case REQUESTER_CASES_LIST.SUCCESS_CUSTOM_BOARD_COLUMN:
        case REQUESTER_CASES_LIST.SUCCESS_BOARD_COLUMN: {
            const payload = action.payload as ListItemsRequestTypesResponseTypes;
            const itemsNumber = payload.count;
            const items = payload.results;

            const status = get(action.payload, 'status', '');
            const statusString = String(status);

            const requesterCasesColumns = status
                ? {
                      ...state.requesterCasesColumns,
                      [statusString]: {
                          ...state.requesterCasesColumns[statusString],
                          results: [...state.requesterCasesColumns[statusString].results, ...items],
                      },
                  }
                : state.requesterCasesColumns;

            return {
                ...state,
                isLoadingCasesList: false,
                requesterCasesNumber: Number(itemsNumber),
                requesterCasesList: [...state.requesterCasesList, ...items],
                requesterCasesColumns: requesterCasesColumns,
            };
        }

        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_BOARD_VIEW: {
            const payload = action.payload as ListUpdatedItemsRequestTypesResponseTypes;
            const ids = payload.ids ? payload.ids : [];
            const updatedCases = payload.updatedCases ? payload.updatedCases : {};
            const filteredColumns: BoardResponseType = {};

            for (const status in state.requesterCasesColumns) {
                const filteredResults = state.requesterCasesColumns[status].results.filter(
                    item => !ids.includes(Number(item.caseId)),
                );

                filteredColumns[status] = {
                    ...state.requesterCasesColumns[status],
                    results: [...updatedCases[status].results, ...filteredResults],
                };
            }

            const items: RequesterCaseResponseTypes[] = Object.values(filteredColumns).reduce(
                (acc: RequesterCaseResponseTypes[], item) => {
                    item.results && acc.push(...item.results);
                    return acc;
                },
                [],
            );

            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: state.requesterCasesNumber,
                requesterCasesList: items || [],
                requesterCasesColumns: filteredColumns,
            };
        }

        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_BOARD_VIEW:
        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_BOARD_VIEW:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_BOARD_VIEW: {
            const payload = action.payload as ListUpdatedItemsRequestTypesResponseTypes;
            const ids = payload.ids ? payload.ids : [];
            const updatedCases = payload.updatedCases ? payload.updatedCases : {};
            const filteredColumns: BoardResponseType = {};

            for (const status in state.requesterCasesColumns) {
                const updatedCasesStatusResults = [...updatedCases[status].results];
                const items = state.requesterCasesColumns[status].results.map(item => {
                    if (!ids.includes(Number(item.caseId))) return item;
                    return updatedCasesStatusResults.find(
                        (updItem: RequesterCaseResponseTypes) => updItem.caseId == item.caseId,
                    );
                });

                const addedItems = updatedCasesStatusResults.filter(
                    updItem => !items.find(item => item?.caseId === updItem.caseId),
                );

                const results = [
                    ...addedItems,
                    ...(items.filter(item => item !== undefined) as RequesterCaseResponseTypes[]),
                ];

                filteredColumns[status] = {
                    ...state.requesterCasesColumns[status],
                    results,
                };
            }

            const items: RequesterCaseResponseTypes[] = Object.values(filteredColumns).reduce(
                (acc: RequesterCaseResponseTypes[], item) => {
                    item.results && acc.push(...item.results);
                    return acc;
                },
                [],
            );

            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: state.requesterCasesNumber,
                requesterCasesList: items || [],
                requesterCasesColumns: filteredColumns,
            };
        }

        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.SUCCESS_PINNED_LIST:
        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.SUCCESS_PINNED_LIST:
        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.SUCCESS_PINNED_LIST:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.SUCCESS_PINNED_LIST:
        case GET_PINNED_CASES.SUCCESS: {
            const payload = action.payload as ListItemsRequestTypesResponseTypes;
            const itemsNumber = payload.count;
            const items = payload.results;
            return {
                ...state,
                isLoading: false,
                pinnedRequesterCasesNumber: Number(itemsNumber),
                pinnedRequesterCasesList: items,
            };
        }

        case GET_WAITING_APPROVE_CASES.SUCCESS: {
            const payload = action.payload as ListItemsRequestTypesResponseTypes;
            const items = payload.results;
            return {
                ...state,
                isLoadingCasesList: false,
                casesWaitingApproveList: items,
            };
        }

        case REQUESTER_CASES_LIST.REQUEST_EMPTY: {
            return {
                ...state,
                isLoading: false,
                requesterCasesNumber: 0,
                requesterCasesList: [],
            };
        }

        case LANGUAGE_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                languageList: action.payload as any,
                error: undefined,
            };
        }

        case GET_ORGANIZATIONS_STATISTIC.SUCCESS:
        case SEND_ORGANIZATIONS_STATISTIC.SUCCESS:
        case GET_FORM_SNIPPET_CODE.SUCCESS:
        case FORM_TRANSLATION_IMPORT.SUCCESS:
        case FORM_TRANSLATION_EXPORT.SUCCESS:
        case REQUEST_CASE_EXPORT.SUCCESS:
        case REQUEST_CASE_EXPORT_MULTIPLE.SUCCESS:
        case TOGGLE_LANGUAGE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case GET_ROLES_LIST.SUCCESS:
        case DELETE_ROLE.SUCCESS:
        case TOGGLE_ROLE_STATUS.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                roles: action.payload as RoleTypes[],
            };
        }

        case GET_ROLES_LIST_OTHER_ORGANIZATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rolesOtherOrganization: action.payload as RoleTypes[],
            };
        }

        case CREATE_ROLE.SUCCESS:
        case UPDATE_ROLE.SUCCESS:
        case GET_ROLE_INFORMATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentRole: action.payload as RoleTypes,
            };
        }

        case INVITE_SEND.SUCCESS:
        case INVITE_DELETE.SUCCESS:
        case INVITE_CREATE.SUCCESS:
        case INVITES_GET_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                invites: action.payload as InviteTypes[],
            };
        }

        case GET_RULES_LIST.SUCCESS:
        case RULE_CREATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rules: action.payload as RuleTypes[],
            };
        }

        case CASES_ACTIVITIES_LIST.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agentCasesActivitiesList: action.payload as RequesterCaseActivitiesTypes[],
            };
        }

        case ORGANIZATION_TEMPLATES_LIST.SUCCESS:
        case ORGANIZATION_TEMPLATE_CREATE.SUCCESS:
        case ORGANIZATION_TEMPLATE_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationTemplates: action.payload as OrganizationTemplateItemTypes[],
            };
        }

        case FORM_WORKFLOW_GET_STATUSES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formWorkflowStatuses: action.payload as CurrentFormWorkflowStatusesTypes[],
            };
        }

        case GET_ORGANIZATION_LANGUAGES_LIST.SUCCESS:
        case ORGANIZATION_LANGUAGE_ADD.SUCCESS:
        case ORGANIZATION_LANGUAGE_ACTIVATE.SUCCESS:
        case ORGANIZATION_LANGUAGE_REMOVE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationLanguages: action.payload as OrganizationLanguageTypes[],
            };
        }

        case ORGANIZATION_TRANSLATIONS_LIST.SUCCESS:
        case ORGANIZATION_TRANSLATIONS_UPDATE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationTranslations: action.payload as OrganizationTranslationTypes[],
            };
        }

        case ORGANIZATION_INTEGRATION_TOKENS_LIST.SUCCESS:
        case ORGANIZATION_INTEGRATION_TOKEN_CREATE.SUCCESS:
        case ORGANIZATION_INTEGRATION_TOKEN_UPDATE.SUCCESS:
        case ORGANIZATION_INTEGRATION_TOKEN_DELETE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationIntegrationTokens: action.payload as OrganizationIntegrationTokenTypes[],
            };
        }

        case ORGANIZATION_PDF_TEMPLATES_LIST.SUCCESS:
        case CREATE_ORGANIZATION_PDF_TEMPLATE.SUCCESS:
        case UPDATE_ORGANIZATION_PDF_TEMPLATE.SUCCESS:
        case DELETE_ORGANIZATION_PDF_TEMPLATE.SUCCESS:
        case UPLOAD_ORGANIZATION_PDF_ATTACHMENT.SUCCESS:
        case DELETE_ORGANIZATION_PDF_ATTACHMENT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizationPdfTemplates: action.payload as OrganizationPdfTemplatesTypes[],
            };
        }

        case INVITE_SEND.ERROR:
        case INVITE_DELETE.ERROR:
        case INVITE_CREATE.ERROR:
        case INVITES_GET_LIST.ERROR:
        case DELETE_ORGANIZATION.ERROR:
        case CREATE_ORGANIZATION.ERROR:
        case UPDATE_ORGANIZATION.ERROR:
        case TOGGLE_TWO_FACTOR_ORGANIZATION.ERROR:
        case ORGANIZATION_LIST.ERROR:
        case GET_ORGANIZATIONS_STATISTIC.ERROR:
        case SEND_ORGANIZATIONS_STATISTIC.ERROR:
        case CHANGE_ORGANIZATION_STATUS.ERROR:
        case ORGANIZATION_MULTIPLE_STATUS_CHANGING.ERROR:
        case ORGANIZATION_CHANGE_DOMAIN.ERROR:
        case ORGANIZATION_ADMIN_LIST.ERROR:
        case ORGANIZATION_ADMIN_CREATE.ERROR:
        case ORGANIZATION_ADMIN_INVITE.ERROR:
        case ORGANIZATION_ADMIN_UPDATE.ERROR:
        case ORGANIZATION_ADMIN_PASSWORD_RESET.ERROR:
        case ORGANIZATION_ADMIN_UNLOCK.ERROR:
        case ORGANIZATION_ADMIN_DELETE.ERROR:
        case ORGANIZATION_ADMIN_2FA_DEACTIVATE.ERROR:
        case ORGANIZATION_ADMIN_UPDATE_ROLES.ERROR:
        case ORGANIZATION_ADMIN_RELATE_OTHER_ORGANIZATION.ERROR:
        case ORGANIZATION_AGENT_REASSIGN.ERROR:
        case ORGANIZATION_UPLOAD_LOGO.ERROR:
        case ORGANIZATION_UPLOAD_FAVICON.ERROR:
        case ORGANIZATION_LOGO_DELETE.ERROR:
        case ORGANIZATION_FAVICON_DELETE.ERROR:
        case ORGANIZATION_LOGO_GET.ERROR:
        case ORGANIZATION_QUOTAS_LIST.ERROR:
        case CREATE_ORGANIZATION_QUOTA.ERROR:
        case UPDATE_ORGANIZATION_QUOTA.ERROR:
        case DELETE_ORGANIZATION_QUOTA.ERROR:
        case TOGGLE_ORGANIZATION_QUOTA_HARD.ERROR:
        case CREATE_ORGANIZATION_SSO_CREDENTIALS.ERROR:
        case UPDATE_ORGANIZATION_SSO_CREDENTIALS.ERROR:
        case UPDATE_ORGANIZATION_SSO_CLIENT_SECRET_CREDENTIALS.ERROR:
        case ORGANIZATION_TEMPLATES_LIST.ERROR:
        case ORGANIZATION_TEMPLATE_CREATE.ERROR:
        case ORGANIZATION_TEMPLATE_UPDATE.ERROR:
        case GET_ORGANIZATION_LANGUAGES_LIST.ERROR:
        case ORGANIZATION_LANGUAGE_ADD.ERROR:
        case ORGANIZATION_LANGUAGE_ACTIVATE.ERROR:
        case ORGANIZATION_LANGUAGE_REMOVE.ERROR:
        case ORGANIZATION_TRANSLATIONS_LIST.ERROR:
        case ORGANIZATION_TRANSLATIONS_UPDATE.ERROR:
        case ORGANIZATION_PRIVACY_POLICY_LIST.ERROR:
        case CREATE_ORGANIZATION_PRIVACY_POLICY.ERROR:
        case UPDATE_ORGANIZATION_PRIVACY_POLICY.ERROR:
        case TOGGLE_ORGANIZATION_PRIVACY_POLICY_DEFAULT.ERROR:
        case CREATE_ORGANIZATION_PRIVACY_POLICY_VERSION.ERROR:
        case ORGANIZATION_INTEGRATION_TOKENS_LIST.ERROR:
        case ORGANIZATION_INTEGRATION_TOKEN_CREATE.ERROR:
        case ORGANIZATION_INTEGRATION_TOKEN_UPDATE.ERROR:
        case ORGANIZATION_INTEGRATION_TOKEN_DELETE.ERROR:
        case AGENT_LIST.ERROR:
        case CASES_ACTIVITIES_LIST.ERROR:
        case AGENT_TEMPLATE_GET.ERROR:
        case AGENT_TEMPLATE_UPDATE.ERROR:
        case AGENT_FILTERS_LIST.ERROR:
        case AGENT_FILTER_TAGS_LIST.ERROR:
        case AGENT_FILTERS_LIST_CREATE.ERROR:
        case AGENT_FILTERS_LIST_UPDATE.ERROR:
        case AGENT_FILTERS_LIST_DELETE.ERROR:
        case FORMS_LIST.ERROR:
        case FORMS_LIST_FOR_REQUESTER.ERROR:
        case FORM_CHANGE_STATUS.ERROR:
        case FORM_DELETE.ERROR:
        case FORM_FILTERS_GET.ERROR:
        case FORMS_COPY.ERROR:
        case FORM_CREATE.ERROR:
        case FORM_UPDATE.ERROR:
        case FORM_PAUSE_ON.ERROR:
        case FORM_PAUSE_OFF.ERROR:
        case FORM_PAGE_UP.ERROR:
        case FORM_PAGE_DOWN.ERROR:
        case FORM_MULTIPLE_STATUS_CHANGING.ERROR:
        case FORM_INFORMATION.ERROR:
        case FORM_BY_CASE_INFORMATION.ERROR:
        case FORM_VERSION_STATUSES.ERROR:
        case FORM_VERSION_STRUCTURE.ERROR:
        case FORM_VERSION_CARD_CREATE.ERROR:
        case FORM_VERSION_CARD_UPDATE.ERROR:
        case FORM_VERSION_CARD_DELETE.ERROR:
        case FORM_VERSION_CARD_GET.ERROR:
        case FORM_VERSION_CARDS_GET_ALL.ERROR:
        case FORM_VERSION_CARD_FIELD_CREATE.ERROR:
        case FORM_VERSION_CARD_FIELD_UPDATE.ERROR:
        case FORM_VERSION_CARD_FIELD_DELETE.ERROR:
        case CREATE_FORM_VERSION.ERROR:
        case UPDATE_FORM_VERSION.ERROR:
        case DEACTIVATE_FORM_VERSION.ERROR:
        case DELETE_FORM_VERSION.ERROR:
        case FORM_LANGUAGE_ADD.ERROR:
        case FORM_LANGUAGE_STATUS.ERROR:
        case FORM_LANGUAGE_DELETE.ERROR:
        case FORM_TRANSLATIONS.ERROR:
        case FORM_MAIN_TRANSLATION_UPDATE.ERROR:
        case FORM_TRANSLATION_UPDATE.ERROR:
        case FORM_TRANSLATION_EXPORT.ERROR:
        case FORM_TRANSLATION_IMPORT.ERROR:
        case FORM_PAGE_ADD.ERROR:
        case FORM_PAGE_UPDATE.ERROR:
        case FORM_PAGE_DELETE.ERROR:
        case FORM_PAGE_COPY.ERROR:
        case FORM_SECTION_ADD.ERROR:
        case FORM_SECTION_UPDATE.ERROR:
        case FORM_SECTION_DELETE.ERROR:
        case FORM_SECTION_COPY.ERROR:
        case FORM_SECTION_MOVE.ERROR:
        case FORM_SECTION_UP.ERROR:
        case FORM_SECTION_DOWN.ERROR:
        case FORM_SECTION_RECALCULATE_PRIORITIES.ERROR:
        case FORM_FIELD_ADD.ERROR:
        case FORM_FIELD_UPDATE.ERROR:
        case FORM_FIELD_GDPR_SETTINGS_UPDATE.ERROR:
        case FORM_FIELD_DELETE.ERROR:
        case FORM_FIELD_COPY.ERROR:
        case FORM_FIELD_MOVE.ERROR:
        case FORM_FIELD_UP.ERROR:
        case FORM_FIELD_DOWN.ERROR:
        case FORM_FIELD_ADD_RELATED_SECTION.ERROR:
        case FORM_FIELD_REMOVE_RELATED_SECTION.ERROR:
        case FORM_POPUP_ADD.ERROR:
        case FORM_POPUP_UPDATE.ERROR:
        case FORM_POPUP_DELETE.ERROR:
        case FORM_POPUP_COPY.ERROR:
        case FORM_POPUP_MOVE.ERROR:
        case FORM_POPUP_UP.ERROR:
        case FORM_POPUP_DOWN.ERROR:
        case FORM_FIELD_POPUP_ADD.ERROR:
        case FORM_UPLOAD_LOGO.ERROR:
        case FORM_LOGO_GET.ERROR:
        case FORM_LOGO_DELETE.ERROR:
        case FORM_TAG_CREATE.ERROR:
        case FORM_TAG_LIST.ERROR:
        case FORM_TAG_UPDATE.ERROR:
        case FORM_TAG_DELETE.ERROR:
        case FORM_HAS_ADDITIONAL_CREATE.ERROR:
        case FORM_HAS_ADDITIONAL_DELETE.ERROR:
        case FORM_HAS_ADDITIONAL_APPEND.ERROR:
        case FORM_ORGANIZATION_RELATE.ERROR:
        case FORM_ALLOW_LIST_CREATE.ERROR:
        case FORM_ALLOW_LIST_DELETE.ERROR:
        case GET_FORM_SNIPPET.ERROR:
        case GET_FORM_SNIPPET_CODE.ERROR:
        case GET_FORM_SNIPPET_LOGO.ERROR:
        case DELETE_FORM_SNIPPET_LOGO.ERROR:
        case UPDATE_FORM_SNIPPET.ERROR:
        case UPDATE_FORM_WITH_LOGO_SNIPPET.ERROR:
        case FORM_FAQ_LIST.ERROR:
        case FORM_FAQ_CREATE.ERROR:
        case FORM_FAQ_UPDATE.ERROR:
        case FORM_FAQ_DELETE.ERROR:
        case FORM_FAQ_PRIORITY.ERROR:
        case GET_RULES_LIST.ERROR:
        case RULE_CREATE.ERROR:
        case GET_ROLES_LIST.ERROR:
        case GET_ROLES_LIST_OTHER_ORGANIZATION.ERROR:
        case GET_ROLE_INFORMATION.ERROR:
        case CREATE_ROLE.ERROR:
        case UPDATE_ROLE.ERROR:
        case DELETE_ROLE.ERROR:
        case TOGGLE_ROLE_STATUS.ERROR:
        case LANGUAGE_LIST.ERROR:
        case TOGGLE_LANGUAGE.ERROR:
        case FORM_WORKFLOW_GET_STATUSES.ERROR:
        case FORM_WORKFLOW_UPDATE.ERROR:
        case FORM_FIELD_CREATE_OPTION.ERROR:
        case FORM_FIELD_UPDATE_OPTION.ERROR:
        case FORM_FIELD_DELETE_OPTION.ERROR:
        case FORM_FIELD_IMPORT_OPTIONS.ERROR:
        case ORGANIZATION_PDF_TEMPLATES_LIST.ERROR:
        case CREATE_ORGANIZATION_PDF_TEMPLATE.ERROR:
        case UPDATE_ORGANIZATION_PDF_TEMPLATE.ERROR:
        case DELETE_ORGANIZATION_PDF_TEMPLATE.ERROR:
        case UPLOAD_ORGANIZATION_PDF_ATTACHMENT.ERROR:
        case DELETE_ORGANIZATION_PDF_ATTACHMENT.ERROR: {
            return {
                ...state,
                isLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        case GET_PINNED_CASES.ERROR:
        case GET_WAITING_APPROVE_CASES.ERROR:
        case REQUEST_CASE_ASSIGN_AGENT_MULTIPLE.ERROR:
        case REQUEST_CASE_CHANGE_STATUS_MULTIPLE.ERROR:
        case REQUEST_CASE_TOGGLE_PINNING_MULTIPLE.ERROR:
        case REQUEST_CASE_EXPORT_MULTIPLE.ERROR:
        case REQUEST_CASE_EXPORT.ERROR:
        case REQUEST_CASE_CHANGE_TAG_MULTIPLE.ERROR:
        case REQUEST_CASE_CREATE_STACK.ERROR:
        case REQUEST_CASE_DELETE_STACK.ERROR:
        case REQUEST_CASE_DRAG_AND_DROP.ERROR:
        case REQUESTER_CASES_LIST.ERROR: {
            return {
                ...state,
                isLoadingCasesList: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default admin;
