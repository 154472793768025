import {TFunction} from 'i18next';
import get from 'lodash/get';

import {
    RequesterCaseTypes,
    RequesterCaseItemType,
    RequesterCaseAttachmentsForArchiveTypes,
} from 'appRedux/actions/requestCase/types';
import {
    ClientTranslationTypes,
    ClientTranslationUpdatedType,
    ClientTranslationUpdatedItemType,
} from 'appRedux/actions/clientTranslation/types';
import {AgentsWithCaseAccessTypes} from 'appRedux/actions/crypto/types';
import {FormFieldTypes, FormPopupTypes} from 'appRedux/actions/forms/types';
import {ChatContactTypes} from 'appRedux/actions/requestChat/types';

import GoogleSvgIcon from 'assets/icons/buttons/GoogleSvgIcon';
import TranslateSvgIcon from 'assets/icons/buttons/TranslateSvgIcon';
import TimeSvgIcon from 'assets/icons/buttons/TimeSvgIcon';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';

import {routes} from 'config/index';

export const getNotReadMessagesNumber = (contacts: ChatContactTypes[], caseId: number): number | null => {
    const contact = contacts && contacts.find(item => item.caseId === caseId);
    return contact ? contact.notReadMessages : null;
};

export const FILE_CASE = 'FILE_CASE';
export const FILE_CHAT = 'FILE_CHAT';

export interface CommonFileType {
    uuid: string;
    index: number;
    type: string;
    title: string;
    extension: string;
    encryptPrefix?: string | null;
    pageId?: number;
    formId?: number;
    fieldId?: number;
    fieldSectionId?: number;
    formFieldLabel?: string;
    isImage: boolean;
    thumbLink?: string;
    fileLink?: string;
    thumb?: string;
    originalName?: string;
}

export const getCaseTitle = (t: TFunction, currentCase: RequesterCaseTypes) => {
    const {userName, userEmail} = currentCase;
    return userName
        ? `${userName} ${t('orguser.requesterCase.case')}`
        : `${userEmail} ${t('orguser.requesterCase.case')}`;
};

export const getAgentFromList = (agentList: AgentsWithCaseAccessTypes[], agentEmail: string) => {
    const agent = agentList.find(item => {
        return item.email === agentEmail;
    });
    if (agent) {
        return agent.id;
    }
    return undefined;
};

export const getCompletedItemsNumber = (
    fields: FormFieldTypes[],
    popups: FormPopupTypes[],
    content: RequesterCaseItemType,
): number => {
    let counter = 0;
    for (let i = 0, n = fields.length; i < n; i++) {
        const id = fields[i].id;
        if (`field-${id}` in content) {
            counter++;
        }
    }
    for (let i = 0, n = popups.length; i < n; i++) {
        const id = popups[i].id;
        if (`popup-${id}` in content) {
            counter++;
        }
    }
    return counter;
};

export const getBackLink = (uuid: string, isAgent: boolean): string => {
    const path = isAgent ? routes.AGENT : routes.ADMIN;
    return `${path}/${routes.BOARD}/${uuid}/cases`;
};

export const getInitialClientTranslations = (
    sectionId: number,
    translations: ClientTranslationTypes[],
): ClientTranslationUpdatedType => {
    const translationsArray: ClientTranslationUpdatedItemType[] = [];
    translations.forEach((item: ClientTranslationTypes) => {
        const {fieldId, initialText, popupItem, translation} = item;
        if (sectionId === item.sectionId) {
            translationsArray.push({
                field: fieldId,
                popupItem: popupItem ? popupItem : undefined,
                initialText,
                translation,
            });
        }
    });
    return {
        section: sectionId,
        translations: translationsArray,
    };
};

export const getCurrentTranslation = (
    translations: ClientTranslationTypes[],
    sectionId: number,
    fieldId: number,
    popupItem?: number | null,
): ClientTranslationTypes | undefined => {
    if (popupItem) {
        return translations.find(
            (item: ClientTranslationTypes) =>
                item.sectionId === sectionId &&
                item.fieldId === fieldId &&
                item.popupId &&
                item.popupItem === popupItem,
        );
    }
    return translations.find(
        (item: ClientTranslationTypes) => item.sectionId === sectionId && item.fieldId === fieldId,
    );
};

export const getInitialTranslatedValue = (
    field: number,
    clientTranslations?: ClientTranslationUpdatedType,
    popupItem?: number | null,
): string => {
    const translations = get(clientTranslations, 'translations', []);
    const item = translations.find(
        (translation: ClientTranslationUpdatedItemType) =>
            translation.field === field && translation.popupItem === popupItem,
    );
    return item ? item.translation : '';
};

export const getTranslationIcon = (currentTranslation?: ClientTranslationTypes) => {
    if (currentTranslation && currentTranslation.isOutdated) {
        return TimeSvgIcon;
    }
    if (currentTranslation && currentTranslation.isGoogleTranslation) {
        return GoogleSvgIcon;
    }
    return TranslateSvgIcon;
};

export interface SelectedAttachmentType {
    uuid: string;
    fileSource: string;
    fileName: string;
}

export const getFilesForDownload = async (
    caseAttachments: RequesterCaseAttachmentsForArchiveTypes[],
    unwrappedCaseKey: CryptoKey | null,
): Promise<RequesterCaseAttachmentsForArchiveTypes[]> => {
    const results: RequesterCaseAttachmentsForArchiveTypes[] = [];
    for (let i = 0, n = caseAttachments.length; i < n; i++) {
        const item = caseAttachments[i];
        if (item.type === FILE_CHAT) {
            results.push({
                type: item.type,
                uuid: item.uuid,
                src: item.src,
                fileName:
                    item.encryptPrefix && unwrappedCaseKey
                        ? await decryptStringWithKey(item.fileName, unwrappedCaseKey)
                        : item.fileName,
                encryptPrefix: item.encryptPrefix,
            });
        } else {
            results.push(item);
        }
    }
    return results;
};

export const getUserNameLabel = (t: TFunction, caseLabel: string, isAnonymousAccount: boolean) => {
    if (isAnonymousAccount) {
        return `${caseLabel} (${t('orguser.requesterCase.anonymousAccount')})`;
    }
    return caseLabel;
};
