import * as yup from 'yup';

import {decimalValidation} from 'helpers/validation';

export const validation = (t: (trans: string) => string, availableAmount: number, isMoneyType: boolean) =>
    yup.object({
        amount: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required('messages.validation.isRequired')
            .test('decimal', t('messages.validation.decimalFormatRequired'), value =>
                decimalValidation(String(value), isMoneyType),
            )
            .min(0.01, t('messages.validation.minimalAmountValue').replace('%NUMBER%', String(0.01)))
            .max(
                availableAmount,
                t('messages.validation.maximalAmountValue').replace('%NUMBER%', String(availableAmount)),
            ),
    });

export const validationForClientMode = (t: (trans: string) => string, availableAmount: number, isMoneyType: boolean) =>
    yup.object({
        amountUser: yup
            .number()
            .typeError(t('messages.validation.isNumberRequired'))
            .required('messages.validation.isRequired')
            .test('decimal', t('messages.validation.decimalFormatRequired'), value =>
                decimalValidation(String(value), isMoneyType),
            )
            .min(0.01, t('messages.validation.minimalAmountValue').replace('%NUMBER%', String(0.01)))
            .max(
                availableAmount,
                t('messages.validation.maximalAmountValue').replace('%NUMBER%', String(availableAmount)),
            ),
    });
