import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Text} from '@react-pdf/renderer';

import {FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';
import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {
    CONTENT_FIELD,
    CONTENT_POPUP,
    FORM_FIELD_IMAGE_CHECK,
    FORM_FIELD_IMAGE_RADIO,
    FORM_FIELD_SIGNATURE,
    FORM_FIELD_UPLOADER,
    getSectionContent,
    SectionContentType,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import FieldPdfContent from 'pages/agent/pdfExport/pdfPartials/FieldPdfContent';
import PopupPdfContent from 'pages/agent/pdfExport/pdfPartials/PopupPdfContent';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import FileFieldPdfContent from 'pages/agent/pdfExport/pdfPartials/FileFieldPdfContent';
import ImgSelectFieldPdfContent from 'pages/agent/pdfExport/pdfPartials/ImgSelectFieldPdfContent';
import CommentPdfRow from 'pages/agent/pdfExport/pdfPartials/CommentPdfRow';
import SignatureFieldPdfContent from 'pages/agent/pdfExport/pdfPartials/SignatureFieldPdfContent';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getSectionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface SectionPdfContentType {
    pageId: number;
    section: FormSectionTypes;
    result: RequesterCaseItemType | null;
    formId?: string;
    printComments?: boolean;
}

const SectionPdfContent: FC<SectionPdfContentType> = ({pageId, section, result, formId, printComments}) => {
    const [t] = useTranslation();

    const {translations, currentCase} = useContext(PdfDocumentContext);

    const {title, id, fields, popups} = section;
    const {comments} = currentCase;
    const sectionContent: SectionContentType[] = getSectionContent(fields, popups);

    const keyword = getSectionKeyword(Number(formId), id, 'title');

    return (
        <>
            <View style={pdfStyles.sectionHeader}>
                <Text>{getFormTranslatedLabel(translations, keyword, title)}</Text>
            </View>
            {sectionContent.map((item: SectionContentType, index: number) => {
                if (item.type === CONTENT_FIELD) {
                    if (fields[item.index]?.type === FORM_FIELD_UPLOADER) {
                        return (
                            <FileFieldPdfContent
                                key={`field-${index}`}
                                formId={Number(formId)}
                                field={fields[item.index]}
                            />
                        );
                    } else if ([FORM_FIELD_IMAGE_CHECK, FORM_FIELD_IMAGE_RADIO].includes(fields[item.index]?.type)) {
                        return (
                            <ImgSelectFieldPdfContent
                                key={`field-${index}`}
                                formId={Number(formId)}
                                pageId={pageId}
                                sectionId={id}
                                field={fields[item.index]}
                                result={result}
                            />
                        );
                    } else if (fields[item.index]?.type === FORM_FIELD_SIGNATURE) {
                        return (
                            <SignatureFieldPdfContent
                                key={`field-${index}`}
                                formId={Number(formId)}
                                pageId={pageId}
                                sectionId={id}
                                field={fields[item.index]}
                                result={result}
                            />
                        );
                    } else {
                        return (
                            <FieldPdfContent
                                key={`field-${index}`}
                                formId={Number(formId)}
                                pageId={pageId}
                                sectionId={id}
                                field={fields[item.index]}
                                result={result}
                            />
                        );
                    }
                }

                if (item.type === CONTENT_POPUP) {
                    return (
                        <PopupPdfContent
                            key={`popup-${index}`}
                            formId={Number(formId)}
                            pageId={pageId}
                            sectionId={id}
                            popup={popups[item.index]}
                        />
                    );
                }

                return null;
            })}
            {printComments && (
                <>
                    <View style={pdfStyles.divider}></View>
                    <View style={pdfStyles.commentsTitle}>
                        <Text>{t('orguser.requesterCase.comments.comments')}:</Text>
                    </View>
                    <View style={pdfStyles.sectionComments}>
                        {comments
                            .filter(comment => comment.section === section.id)
                            .map((comment: RequesterCaseCommentsTypes, index: number) => {
                                return <CommentPdfRow item={comment} key={`comment-${comment.section}-${index}`} />;
                            })}
                    </View>
                </>
            )}
        </>
    );
};

export default SectionPdfContent;
