import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {CryptoContext} from 'contexts/crypto/context';
import {CaseKeyContext} from 'contexts/caseKey/context';

import EmptyArea from 'components/EmptyArea';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import PagePrintContent from 'pages/agent/print/partials/PagePrintContent';
import {getUserNameLabel} from 'pages/agent/requesterPage/partials/helper';
import {getDecodedResults} from 'pages/client/form/helper';

interface InfoForPrintType {
    pages: FormPageTypes[];
}

const InfoForPrint: FC<InfoForPrintType> = ({pages}) => {
    const [t] = useTranslation();

    const {keys} = useContext(CryptoContext);
    const {encodedCaseKey} = useContext(CaseKeyContext);

    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [clientInformation, setClientInformation] = useState({});

    const toggleExpandAll = () => {
        setExpandAll(previous => !previous);
    };

    const {
        requestCase: {
            currentCase: {result, isEncryptInfo, caseLabel, isAnonymousAccount},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const printPageContent = () => {
        window.print();
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isEncryptInfo) {
                if (result && keys && encodedCaseKey) {
                    const currentResults = await getDecodedResults(result, encodedCaseKey, keys);
                    setClientInformation({
                        enc: isEncryptInfo,
                        ...currentResults,
                    });
                }
            } else {
                setClientInformation(result);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [result, encodedCaseKey, isEncryptInfo]);

    return (
        <Box sx={{mb: 4}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography align="left" mb={2} sx={{fontSize: 32, fontWeight: 300}}>
                    {getUserNameLabel(t, caseLabel, isAnonymousAccount)}
                </Typography>
                <EmptyArea />
                <AgentSaveButton isSubmit={false} title={t('common.buttons.print')} onClick={printPageContent} />
                <AgentSaveButton isSubmit={false} title={t('common.buttons.expandAll')} onClick={toggleExpandAll} />
            </Box>
            <Box>
                {pages.map((page: FormPageTypes, index: number) => {
                    return (
                        <PagePrintContent
                            key={`page-${index}`}
                            pageNumber={index + 1}
                            page={page}
                            expandAll={expandAll}
                            result={clientInformation}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default InfoForPrint;
