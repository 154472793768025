import React, {FC} from 'react';

import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {RESOURCE_FIELD_TYPE_SLOT_TICKET, RESOURCE_FIELD_TYPE_BUDGET} from 'components/Forms/ResourceField/helper';

import ResourceFieldBudget from 'pages/client/form/fieldTypes/resourceFields/ResourceFieldBudget';
import ResourceFieldSlot from 'pages/client/form/fieldTypes/resourceFields/ResourceFieldSlot';

interface FormResourceFieldInformationType {
    formId: number;
    sectionId: number;
    field: FormFieldTypes;
}

const FormResourceFieldInformation: FC<FormResourceFieldInformationType> = ({formId, sectionId, field}) => {
    const {resourceFieldType} = field;
    switch (resourceFieldType) {
        case RESOURCE_FIELD_TYPE_SLOT_TICKET:
            return <ResourceFieldSlot formId={formId} sectionId={sectionId} field={field} />;
        case RESOURCE_FIELD_TYPE_BUDGET:
            return <ResourceFieldBudget formId={formId} field={field} />;
        default:
            return null;
    }
};

export default FormResourceFieldInformation;
