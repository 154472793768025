import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import CreateRequestTranslationForm from 'components/Forms/CreateRequestTranslationForm/CreateRequestTranslationForm';

interface RequestTranslationMenuItemType {
    sectionId: number;
    handleClose: () => void;
}

const RequestTranslationMenuItem: FC<RequestTranslationMenuItemType> = ({sectionId, handleClose}) => {
    const [t] = useTranslation();
    const {caseId} = useParams();

    const [openModal, setOpenModal] = useState<boolean>(false);

    return (
        <>
            <MenuItem onClick={() => setOpenModal(true)}>
                <Typography>{t('orguser.requesterCase.translation.requestTranslation')}</Typography>
            </MenuItem>
            <CreateRequestTranslationForm
                openModal={openModal}
                closeModal={() => {
                    setOpenModal(false);
                    handleClose();
                }}
                caseId={Number(caseId)}
                sectionId={sectionId}
            />
        </>
    );
};

export default RequestTranslationMenuItem;
