import React, {FC, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {RequesterCaseBudgetFormTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldBudgetInformationType} from 'appRedux/actions/forms/types';

import {RouteContext} from 'contexts/route/context';

import {validationForClientMode, validation} from 'components/Forms/RequesterCaseBudgetForm/validation';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import {RESOURCE_FIELD_BUDGET_TYPE_MONEY} from 'components/Forms/ResourceField/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface RequesterCaseBudgetFormType {
    resourceFieldBudgetInformation: ResourceFieldBudgetInformationType;
    initialValues: RequesterCaseBudgetFormTypes;
    onSubmitClicked: (values: RequesterCaseBudgetFormTypes) => void;
    isDisabled?: boolean;
    isCreate?: boolean;
}

const RequesterCaseBudgetForm: FC<RequesterCaseBudgetFormType> = ({
    resourceFieldBudgetInformation,
    initialValues,
    onSubmitClicked,
    isDisabled,
    isCreate,
}) => {
    const [t] = useTranslation();

    const {type, amount} = resourceFieldBudgetInformation;

    const {isClientMode} = useContext(RouteContext);

    const fieldName = isClientMode ? 'amountUser' : 'amount';
    const isMoneyType = type === RESOURCE_FIELD_BUDGET_TYPE_MONEY;
    const amountForValidation = isMoneyType ? 0.01 * amount : amount;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() =>
                isClientMode
                    ? validationForClientMode(t, amountForValidation, isMoneyType)
                    : validation(t, amountForValidation, isMoneyType)
            }
            onSubmit={values => {
                const fieldValue = Number(values[fieldName]);
                onSubmitClicked({
                    ...values,
                    [fieldName]: isMoneyType ? 100 * fieldValue : fieldValue,
                });
            }}
        >
            {(formik: FormikProps<RequesterCaseBudgetFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name={fieldName}
                                label={t('requester.casePage.budgetAmount')}
                                type={isMoneyType ? 'text' : 'number'}
                                component={FormikTextInput}
                                onBlur={formik.submitForm}
                                disabled={isClientMode ? isDisabled : false}
                            />
                        </Box>
                        {!isCreate && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RequesterCaseBudgetForm;
