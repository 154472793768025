import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {GET_WORKFLOWS_LIST} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';
import {WorkflowItemTypes} from 'appRedux/actions/workflow/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import WorkflowTile from 'pages/admin/workflows/WorkflowTile';

import {getLanguageById} from 'helpers/translationsHelper';

interface OrganizationWorkflowsType {
    organizationId: number;
    currentOrganizationLanguage?: LanguageItemTypes;
}

const OrganizationWorkflows: FC<OrganizationWorkflowsType> = ({organizationId, currentOrganizationLanguage}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isOrganizationEditEnabled} = useContext(PermissionContext);

    const {
        admin: {languageList},
        profile: {profile},
        workflow: {organizationWorkflows},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getWorkflowsList = useCallback(
        data => dispatch({type: GET_WORKFLOWS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getWorkflowsList({
            organizationId,
            showAlert,
        });
    }, []);

    if (!isOrganizationEditEnabled || !profile) {
        return null;
    }

    const currentUserLanguage = getLanguageById(languageList, profile.languageId).name;

    return (
        <Box>
            {organizationWorkflows.length > 0 ? (
                organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                    return (
                        <WorkflowTile
                            key={`workflow-${item.uuid}-${index}`}
                            item={item}
                            currentUserLanguage={currentUserLanguage}
                            currentOrganizationLanguage={currentOrganizationLanguage}
                        />
                    );
                })
            ) : (
                <ClientSectionWrapper>
                    <Typography>{t('orguser.workflows.parameters.noneWorkflow')}</Typography>
                </ClientSectionWrapper>
            )}
        </Box>
    );
};

export default OrganizationWorkflows;
