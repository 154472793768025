import React, {FC, useCallback} from 'react';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FORM_FILTERS_GET} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import FilterChip from 'components/AgentScreenComponents/_topBar/FilterChip';
import {FilterOptionType} from 'components/AdminScreenComponents/types';
import {getSelectedOptionInformation} from 'components/AgentScreenComponents/_caseBlock/helper';

import {FilterObjectType} from 'pages/agent/savedFilters/helper';
import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';
import {getFormVersionsList} from 'pages/agent/tableView/helper';

import {getOptionKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {
    DEFAULT_NONE,
    PARAMETER_AGENT,
    PARAMETER_FORM,
    PARAMETER_TAG,
    PARAMETER_OPTION,
    PARAMETER_LAST_CREATED,
    PARAMETER_LAST_UPDATED,
} from 'config/index';
import {TAG_COLOR_6} from 'config/theme';

interface SelectedFiltersType {
    isEditClicked: boolean;
    updatedFilter: FilterObjectType;
    updateFilterStructure: () => void;
}

const SelectedFilters: FC<SelectedFiltersType> = ({isEditClicked, updatedFilter, updateFilterStructure}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        admin: {
            agentList,
            formsList,
            organizationFormFilters: {filters, tags, translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getFormFieldFilters = useCallback(
        data => dispatch({type: FORM_FILTERS_GET.REQUEST, payload: data}),
        [dispatch],
    );

    const lastCreatedFromUrl = get(updatedFilter, PARAMETER_LAST_CREATED, null);
    const lastUpdatedFromUrl = get(updatedFilter, PARAMETER_LAST_UPDATED, null);

    const currentAgentsArray = get(updatedFilter, PARAMETER_AGENT, []);
    const currentFormsArray = get(updatedFilter, PARAMETER_FORM, []);
    const currentTagsArray = get(updatedFilter, PARAMETER_TAG, []);
    const currentOptionsArray = get(updatedFilter, PARAMETER_OPTION, []);

    const formVersions: FilterOptionType[] = formsList ? getFormVersionsList(formsList) : [];

    const onAgentDeleteClick = (agentId: string) => {
        const agents = get(updatedFilter, PARAMETER_AGENT, []);
        updatedFilter[PARAMETER_AGENT] = Array.isArray(agents)
            ? agents.filter(item => String(item) !== String(agentId))
            : [];
        updateFilterStructure();
    };

    const onFormDeleteClick = (formVersionId: string) => {
        const formVersions = get(updatedFilter, PARAMETER_FORM, []);
        updatedFilter[PARAMETER_FORM] = Array.isArray(formVersions)
            ? formVersions.filter(item => String(item) !== String(formVersionId))
            : [];

        if (updatedFilter[PARAMETER_FORM].length === 1) {
            const selectedFormId = updatedFilter[PARAMETER_FORM][0];
            getFormFieldFilters({
                id: selectedFormId,
            });
        } else {
            delete updatedFilter[PARAMETER_TAG];
        }

        updateFilterStructure();
    };

    const onTagDeleteClick = (tagId: string) => {
        const tags = get(updatedFilter, PARAMETER_TAG, []);
        updatedFilter[PARAMETER_TAG] = Array.isArray(tags) ? tags.filter(item => String(item) !== String(tagId)) : [];
        updateFilterStructure();
    };

    const onOptionDeleteClick = (optionId: string) => {
        const options = get(updatedFilter, PARAMETER_OPTION, []);
        updatedFilter[PARAMETER_OPTION] = Array.isArray(options)
            ? options.filter(item => String(item) !== String(optionId))
            : [];
        updateFilterStructure();
    };

    const onDeleteClick = (parameter: string) => {
        delete updatedFilter[parameter];
        if (parameter === PARAMETER_FORM) {
            delete updatedFilter[PARAMETER_TAG];
            Object.entries(updatedFilter).map((item: any) => {
                const field = item[0];
                if (field && field.includes('field')) {
                    delete updatedFilter[field];
                }
            });
        }
        updateFilterStructure();
    };

    return (
        <Box sx={{mt: 2, mb: 3}}>
            {Array.isArray(currentAgentsArray) &&
                currentAgentsArray.map(item => {
                    const agent = agentList
                        ? agentList.find(agentListItem => Number(agentListItem.id) === Number(item))
                        : null;
                    if (!agent) {
                        return null;
                    }
                    const {id, firstName, lastName, email} = agent;
                    return (
                        <FilterChip
                            key={`agent-${id}`}
                            title={firstName && lastName ? `${firstName} ${lastName}` : email}
                            onDeleteClick={isEditClicked ? () => onAgentDeleteClick(String(agent.id)) : undefined}
                            backgroundColor={'#9B7B6D'}
                            color={getTagColor('#9B7B6D')}
                        />
                    );
                })}
            {String(currentAgentsArray).includes(DEFAULT_NONE) && (
                <FilterChip
                    key={`agent-${DEFAULT_NONE}`}
                    title={t('orguser.requesterCase.notAssigned')}
                    onDeleteClick={() => onAgentDeleteClick(DEFAULT_NONE)}
                    backgroundColor={'#9B7B6D'}
                    color={getTagColor('#9B7B6D')}
                />
            )}
            {Array.isArray(currentFormsArray) &&
                currentFormsArray.map(item => {
                    const formVersion = formVersions
                        ? formVersions.find(formVersionItem => Number(formVersionItem.name) === Number(item))
                        : null;
                    if (!formVersion) {
                        return null;
                    }
                    const {label, name} = formVersion;
                    return (
                        <FilterChip
                            key={`form-${name}`}
                            title={label}
                            onDeleteClick={isEditClicked ? () => onFormDeleteClick(String(name)) : undefined}
                            backgroundColor={'#205072'}
                            color={getTagColor('#205072')}
                        />
                    );
                })}
            {currentFormsArray &&
                currentFormsArray.length === 1 &&
                Object.entries(updatedFilter).map((item: any) => {
                    const field = item[0];
                    if (field && field.includes('field')) {
                        const fieldId = field.replace('field', '');
                        const currentField = filters.find(filterItem => Number(filterItem.id) === Number(fieldId));
                        if (currentField) {
                            return (
                                <FilterChip
                                    key={`filter-${currentField.id}`}
                                    title={item[1]}
                                    onDeleteClick={
                                        isEditClicked ? () => onDeleteClick(`field${currentField.id}`) : undefined
                                    }
                                />
                            );
                        }
                    }
                    return null;
                })}
            {Array.isArray(currentTagsArray) &&
                currentTagsArray.map(item => {
                    const tag = tags.find(tagItem => Number(tagItem.id) === Number(item));
                    if (!tag) {
                        return null;
                    }
                    return (
                        <FilterChip
                            key={`tag-${tag.id}`}
                            title={tag.title}
                            backgroundColor={tag.color}
                            color={getTagColor(tag.color)}
                            onDeleteClick={isEditClicked ? () => onTagDeleteClick(String(tag.id)) : undefined}
                        />
                    );
                })}
            {Array.isArray(currentOptionsArray) &&
                currentOptionsArray.map(item => {
                    const selectedOption = getSelectedOptionInformation(filters, Number(item));
                    if (!selectedOption) {
                        return null;
                    }
                    const keyword = getOptionKeyword(selectedOption.formId, selectedOption.id, 'title');
                    return (
                        <FilterChip
                            key={`option-${selectedOption.id}`}
                            title={getFormTranslatedLabel(translations, keyword, selectedOption.title)}
                            backgroundColor={TAG_COLOR_6}
                            color={getTagColor(TAG_COLOR_6)}
                            onDeleteClick={
                                isEditClicked ? () => onOptionDeleteClick(String(selectedOption.id)) : undefined
                            }
                        />
                    );
                })}
            {lastCreatedFromUrl && (
                <FilterChip
                    title={`${t('orguser.savedFilters.created')} ${t(
                        `orguser.requesterCase.topbar.${String(lastCreatedFromUrl)}`,
                    )}`}
                    onDeleteClick={isEditClicked ? () => onDeleteClick(PARAMETER_LAST_CREATED) : undefined}
                />
            )}
            {lastUpdatedFromUrl && (
                <FilterChip
                    title={`${t('orguser.savedFilters.updated')} ${t(
                        `orguser.requesterCase.topbar.${String(lastUpdatedFromUrl)}`,
                    )}`}
                    onDeleteClick={isEditClicked ? () => onDeleteClick(PARAMETER_LAST_UPDATED) : undefined}
                />
            )}
        </Box>
    );
};

export default SelectedFilters;
