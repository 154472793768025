import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {SelectChangeEvent} from '@mui/material/Select';
import Alert from '@mui/material/Alert';

import {WorkflowStatusItemTypes, UpdateWorkflowStatusPermissionsRequestTypes} from 'appRedux/actions/workflow/types';
import {UPDATE_WORKFLOW_STATUS_PERMISSIONS} from 'appRedux/actions/workflow';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {OPTIONS_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {getTranslatedWorkflowStatus} from 'pages/admin/workflows/helper';
import {getTagColor} from 'pages/admin/updateForm/partials/FormTags/helper';

import {theme} from 'config/theme';
import {STATUS_OPENING, STATUS_PREPARED} from 'config/index';

interface UpdateWorkflowStatusPermissionFormType {
    item: WorkflowStatusItemTypes;
    currentLanguage: string;
    closeModal: () => void;
}

const UpdateWorkflowStatusPermissionForm: FC<UpdateWorkflowStatusPermissionFormType> = ({
    item,
    currentLanguage,
    closeModal,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {
        workflow: {workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {permittedIds, uuid, statusType} = item;

    const [permittedStatuses, setPermittedStatuses] = useState<string[]>(
        permittedIds ? permittedIds.split(OPTIONS_SEPARATOR) : [],
    );
    const [, setIsUpdated] = useState<boolean>(false);

    const {showAlert} = useContext(AlertContext);

    const updateWorkflowStatusPermissions = useCallback(
        data => dispatch({type: UPDATE_WORKFLOW_STATUS_PERMISSIONS.REQUEST, payload: data}),
        [dispatch],
    );

    const onStatusChecked = (event: SelectChangeEvent) => {
        const statusId = event.target.value;
        const isChecked = permittedStatuses.some(status => Number(status) === Number(statusId));
        if (isChecked) {
            for (let i = 0, n = permittedStatuses.length; i < n; i++) {
                const status = permittedStatuses[i];
                if (Number(status) === Number(statusId)) {
                    permittedStatuses.splice(i, 1);
                    setPermittedStatuses(permittedStatuses);
                    setIsUpdated(previous => !previous);
                    break;
                }
            }
        } else {
            setPermittedStatuses([...permittedStatuses, String(statusId)]);
        }
    };

    const onSubmitClicked = (values: UpdateWorkflowStatusPermissionsRequestTypes) => {
        updateWorkflowStatusPermissions({
            ...values,
            showAlert,
            callback: closeModal,
        });
    };

    const currentStatus =
        workflowStatusesList && workflowStatusesList.find(statusItem => Number(statusItem.id) === Number(item.id));

    const commonLabelStyles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200,
        fontWeight: 600,
        lineHeight: '22px',
    };
    return (
        <Formik
            enableReinitialize
            initialValues={{
                uuid,
                permittedIds: permittedStatuses.join(OPTIONS_SEPARATOR),
            }}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<UpdateWorkflowStatusPermissionsRequestTypes>) => {
                return (
                    <Form>
                        {permittedStatuses.length === 0 && (
                            <Alert severity={'error'} sx={{mb: 1}}>
                                <Typography>{t('orguser.workflows.updateStatusPermissionsExplanation')}</Typography>
                            </Alert>
                        )}
                        {!!currentStatus && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 40,
                                    borderRadius: 20,
                                    mw: 'auto',
                                    my: 1,
                                    px: 2,
                                    py: 1,
                                    width: 200,
                                    justifyContent: 'center',
                                    backgroundColor: currentStatus.color,
                                }}
                            >
                                <Typography sx={{...commonLabelStyles, color: getTagColor(currentStatus.color)}}>
                                    {getTranslatedWorkflowStatus(currentStatus, currentLanguage)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{pt: 1, pb: 1}}>
                            <Typography sx={{fontWeight: 700}}>{`${t(
                                'orguser.workflows.selectStatuses',
                            )}:`}</Typography>
                            <FormGroup>
                                {workflowStatusesList &&
                                    workflowStatusesList.map((statusItem: WorkflowStatusItemTypes) => {
                                        console.log(statusItem);
                                        if (
                                            Number(statusItem.id) !== Number(item.id) &&
                                            statusItem.statusType !== STATUS_OPENING
                                        ) {
                                            return (
                                                <FormControlLabel
                                                    key={`status-checkbox-${statusItem.uuid}`}
                                                    control={
                                                        <Checkbox
                                                            name={'roleIds'}
                                                            value={statusItem.id}
                                                            checked={permittedStatuses.includes(String(statusItem.id))}
                                                            onChange={onStatusChecked}
                                                            disabled={
                                                                statusType === STATUS_OPENING ||
                                                                (statusType === STATUS_PREPARED &&
                                                                    statusItem.statusType === STATUS_OPENING)
                                                            }
                                                            sx={{
                                                                '& svg': {color: theme.palette.info.main},
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                height: 40,
                                                                borderRadius: 20,
                                                                my: 1,
                                                                px: 2,
                                                                py: 1,
                                                                minWidth: 180,
                                                                justifyContent: 'center',
                                                                backgroundColor: statusItem.color,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    ...commonLabelStyles,
                                                                    color: getTagColor(statusItem.color),
                                                                }}
                                                            >
                                                                {getTranslatedWorkflowStatus(
                                                                    statusItem,
                                                                    currentLanguage,
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                            </FormGroup>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit isDisabled={statusType === STATUS_OPENING} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UpdateWorkflowStatusPermissionForm;
